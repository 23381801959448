import { useState, useEffect, useRef } from "react";

const useLazyLoad = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSourceLoaded(src);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentImgRef = imgRef.current; // Copy imgRef.current to a variable

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, [src]);

  return [sourceLoaded, imgRef];
};

export default useLazyLoad;
