import React, { useState, useEffect, useContext } from "react";
import { useCart } from "../context/CartContext"; // Corrected import path
import styles from "../style/Checkout.module.css"; // Import CSS Module
import { useTranslation } from "react-i18next"; // i18n support
import { ReactComponent as BagCheckoutIcon } from "../icons/bag_checkout.svg"; // Assuming the file is available in icons
import Collapsible from "./Collapsible"; // Import Collapsible component
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getNames } from "country-list"; // Import country-list package
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore"; // Firestore imports
import { db } from "../firebase-config"; // Firebase config import
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import Overlay from "./Overlay"; // Import the Overlay component
import { ReactComponent as BackArrowIcon } from "../icons/back.svg"; // Ensure this SVG exists
import { CurrencyContext } from "../context/CurrencyContext"; // Adjust the path if necessary

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const { t } = useTranslation();
  const { cart, clearCart } = useCart();
  const [formData, setFormData] = useState({
    country: "",
    firstName: "",
    lastName: "",
    address: "",
    apartment: "",
    postalCode: "",
    city: "",
    phone: "+33",
  });
  const [isSuccessOverlayVisible, setIsSuccessOverlayVisible] = useState(false);
  const [clientCode, setClientCode] = useState("");
  const [productSummary, setProductSummary] = useState([]);
  const [isPhoneDisabled, setIsPhoneDisabled] = useState(false);
  const { currency, exchangeRates } = useContext(CurrencyContext); // Access CurrencyContext

  const navigate = useNavigate(); // React Router navigation

  const subtotal = cart
    .reduce((total, item) => total + parseFloat(item.price), 0)
    .toFixed(2);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    const searchParams = new URLSearchParams(window.location.search);
    const sessionId = searchParams.get("session_id");

    // Check if returning from a Stripe checkout session
    if (sessionId) {
      // Retrieve stored data from localStorage
      const storedFormData = localStorage.getItem("formData");
      const storedCart = localStorage.getItem("cart");

      if (storedFormData && storedCart) {
        const parsedFormData = JSON.parse(storedFormData);
        const parsedCart = JSON.parse(storedCart);

        // Automatically save the order to the database
        handleSaveOrder(parsedFormData, parsedCart);

        // Clear localStorage after saving
        localStorage.removeItem("formData");
        localStorage.removeItem("cart");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [navigate]);

  const formatDateHere = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString; // Return the original string if invalid date
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    console.log("Cart items on Checkout page load:", cart);
  }, []);

  useEffect(() => {
    console.log("Cart items updated:", cart);
  }, [cart]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleBrandClick = () => {
    window.location.href = "/"; // Perform a full page reload to the home page
  };

  const validateForm = () => {
    // Check if phone number is valid (non-empty and starting with a valid country code, you can add more regex-based validations as needed)
    const isPhoneValid = formData.phone && formData.phone.length >= 10;

    // Check if country is selected
    const isCountrySelected = formData.country.trim() !== "";

    // Check if first and last names are at least 2 characters
    const isFirstNameValid = formData.firstName.trim().length >= 2;
    const isLastNameValid = formData.lastName.trim().length >= 2;

    // Check if address is at least 10 characters
    const isAddressValid = formData.address.trim().length >= 10;

    // Check if postal code has at least 4 numbers
    const isPostalCodeValid = /^[0-9]{4,}$/.test(formData.postalCode.trim());

    // Check if city has at least 3 characters
    const isCityValid = formData.city.trim().length >= 3;

    // Combine all validations
    const isFormValid =
      isPhoneValid &&
      isCountrySelected &&
      isFirstNameValid &&
      isLastNameValid &&
      isAddressValid &&
      isPostalCodeValid &&
      isCityValid;

    // Show an alert if the form is invalid
    if (!isFormValid) {
      alert("Please fill in all fields correctly before proceeding.");
    }

    return isFormValid;
  };

  useEffect(() => {
    // Find the first cart item that has a valid clientCode and customerPhone
    const existingCartItem = cart.find(
      (item) => item.clientCode && item.customerPhone
    );

    if (existingCartItem) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        phone: existingCartItem.customerPhone,
      }));
      setIsPhoneDisabled(true); // Disable the phone input
    } else {
      setIsPhoneDisabled(false); // Enable the phone input for new clients
    }
  }, [cart]);

  const generateClientCode = async (phoneNumber) => {
    let phoneWithoutCountryCode = phoneNumber;

    // Step 1: Remove '+' or '00' and country code digits at the start
    if (phoneNumber.startsWith("+") || phoneNumber.startsWith("00")) {
      phoneWithoutCountryCode = phoneNumber.replace(/^(\+|00)?\d{1,3}/, "");
    } else if (phoneNumber.startsWith("33")) {
      // Specifically handle phone numbers starting with '33' (France)
      phoneWithoutCountryCode = phoneNumber.replace(/^33/, "");
    }

    // **Step 2: REMOVE THIS STEP**
    // // Step 2: Remove the first digit after the country code
    // if (phoneWithoutCountryCode.length > 0) {
    //   phoneWithoutCountryCode = phoneWithoutCountryCode.slice(1);
    // }

    // Step 2 (New): Directly prepend '0' without removing any digits
    phoneWithoutCountryCode = "0" + phoneWithoutCountryCode;

    // Step 3: Remove any non-digit characters (optional, as it's likely already digits)
    phoneWithoutCountryCode = phoneWithoutCountryCode.replace(/\D/g, "");

    // Step 4: Extract the first 6 digits
    let clientCode = phoneWithoutCountryCode.slice(0, 6);

    // Step 5: If less than 6 digits, pad with zeros at the end
    clientCode = clientCode.padEnd(6, "0");

    // Step 6: Convert to number for incrementing
    let clientCodeNumber = parseInt(clientCode, 10);

    // Initialize Firestore collection reference
    const usersCollectionRef = collection(db, "users");
    let isUnique = false;

    while (!isUnique) {
      // Query the database to check if the clientCode exists
      const q = query(
        usersCollectionRef,
        where("clientCode", "==", clientCodeNumber.toString().padStart(6, "0"))
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // The code is unique
        isUnique = true;
      } else {
        // Increment the code by 1 and check again
        clientCodeNumber += 1;

        // Ensure clientCodeNumber remains 6 digits by rolling over if necessary
        if (clientCodeNumber > 999999) {
          clientCodeNumber = 0; // Reset to 000000 if it exceeds 999999
        }
      }
    }

    // Return the client code as a string, padded with zeros if necessary
    return clientCodeNumber.toString().padStart(6, "0");
  };

  const fetchAndIncrementOrderID = async () => {
    const counterRef = doc(db, "counters", "orderCounter");
    try {
      const counterDoc = await getDoc(counterRef);
      if (counterDoc.exists()) {
        const currentOrderCount = parseInt(
          counterDoc.data().currentOrderCount,
          10
        );
        const newOrderID = String(currentOrderCount + 1).padStart(4, "0");
        await updateDoc(counterRef, { currentOrderCount: newOrderID });
        return newOrderID;
      } else {
        await setDoc(counterRef, { currentOrderCount: "0001" });
        return "0001";
      }
    } catch (error) {
      console.error("Error fetching or updating order count:", error);
      return "0001"; // Fallback order ID
    }
  };

  const handleSaveOrder = async (formData, cart) => {
    try {
      // Check if any cart item has a clientCode and customerPhone (existing client)
      const existingCartItem = cart.find(
        (item) => item.clientCode && item.customerPhone
      );

      let clientCode;

      if (existingCartItem) {
        // **Existing Client Flow**
        clientCode = existingCartItem.clientCode;
        // No need to generate a new clientCode or add a new user document
        console.log(
          "Existing client detected. Using existing clientCode:",
          clientCode
        );
      } else {
        // **New Client Flow**
        clientCode = await generateClientCode(formData.phone.trim());

        const userData = {
          clientCode,
          customerName: `${formData.firstName.trim()} ${formData.lastName.trim()}`,
          customerAddress: `${formData.address.trim()}, ${formData.apartment.trim()}`,
          customerPhone: formData.phone.trim(),
          orderDate: formatDate(new Date()),
          creationTime: new Date().toISOString(), // Add this line to record the creation time
        };

        // Add new user document to Firestore
        const usersCollectionRef = collection(db, "users");
        await addDoc(usersCollectionRef, userData);

        console.log("New client added with clientCode:", clientCode);
      }

      // Fetch and increment the order ID
      const orderID = await fetchAndIncrementOrderID();

      // Prepare order data
      const orderData = {
        orderID, // Include the order ID
        clientCode, // Use existing or newly generated clientCode
        customerName: formData.firstName.trim() || "",
        customerLastName: formData.lastName.trim() || "",
        customerAddress: formData.address.trim() || "",
        customerAddressPlus: formData.apartment.trim() || "",
        customerAddressPostalCode: formData.postalCode.trim() || "",
        customerAddressCity: formData.city.trim() || "",
        customerPhone: formData.phone.trim() || "",
        products: cart.map((item) => ({
          productName: item.name || "Unknown Product",
          price: parseFloat(item.price) || 0,
          color: item.color || "N/A",
          productId: item.selectedProductId || "N/A",
          sizes:
            item.sizes && Object.keys(item.sizes).length > 0
              ? item.sizes
              : null, // Set to null for existing clients
          customizations:
            item.customizations && Object.keys(item.customizations).length > 0
              ? item.customizations
              : "None", // Set to "None" if empty
          selectedClient: item.selectedClient || "unknown",
          testOutfitDate:
            item.testOutfitDate && item.selectedClient === "new"
              ? item.testOutfitDate // ISO string
              : null,
          deliveryDate: item.deliveryDate || null, // Store as ISO string
        })),
        orderDate: formatDate(new Date()),
      };

      // Save order data to Firestore
      const ordersCollectionRef = collection(db, "Orders");
      await addDoc(ordersCollectionRef, orderData);

      // Update product summary for the overlay
      setProductSummary(orderData.products);

      // Show success overlay
      setIsSuccessOverlayVisible(true);
    } catch (error) {
      console.error("Error placing order:", error.message);
      alert(`Something went wrong: ${error.message}`);
    }
  };

  const handleBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/"); // Fallback to home if no history
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const maskPhoneNumber = (phone) => {
    // Split the phone into characters
    const chars = phone.split("");
    let digitCount = 0;

    // First, count the total number of digits
    for (let c of chars) {
      if (/\d/.test(c)) {
        digitCount += 1;
      }
    }

    let masked = "";
    let digitsSeen = 0;

    for (let c of chars) {
      if (/\d/.test(c)) {
        digitsSeen += 1;
        // Mask all digits except the last two
        if (digitCount - digitsSeen >= 5) {
          masked += "*";
        } else {
          masked += c;
        }
      } else {
        // Preserve non-digit characters
        masked += c;
      }
    }

    return masked;
  };

  const handlePayNow = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      // Store formData and cart in localStorage before redirecting to Stripe
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("cart", JSON.stringify(cart));

      const validCart = cart.map((product, i) => ({
        name: product.name || `Test Product ${i + 1}`,
        price: product.price || 10.0,
        image: product.image || "https://via.placeholder.com/150",
        quantity: product.quantity || 1,
      }));

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ products: validCart }),
        }
      );

      const session = await response.json();

      if (session.error) {
        throw new Error(session.error);
      }

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error("Stripe redirect error:", error);
        alert(error.message);
      }
    } catch (error) {
      console.error("Error during payment:", error);
      alert(`Payment failed: ${error.message}`);
    }
  };

  // Helper function to format price based on selected currency
  const formatPrice = (priceInGBP) => {
    let convertedPrice = priceInGBP;
    let symbol = "£"; // Default symbol

    switch (currency) {
      case "EUR":
        convertedPrice = priceInGBP * (exchangeRates["EUR"] || 1);
        symbol = "€";
        break;
      case "USD":
        convertedPrice = priceInGBP * (exchangeRates["USD"] || 1);
        symbol = "$";
        break;
      case "GBP":
      default:
        convertedPrice = priceInGBP;
        symbol = "£";
        break;
    }

    return `${symbol}${convertedPrice.toFixed(2)}`;
  };

  const orderSummary = (
    <div className={styles.cartSummary}>
      <BagCheckoutIcon className={styles.bagIcon} />
      <h2>{t("Your Cart")}</h2>
      {cart.length === 0 ? (
        <p>{t("Your cart is empty")}</p>
      ) : (
        <ul className={styles.cartList}>
          {cart.map((item, index) => (
            <li key={index} className={styles.cartItem}>
              <div className={styles.cartItemInfo}>
                <img
                  src={item.image}
                  alt={item.name}
                  className={styles.cartImg}
                />
                <div className={styles.cartItemDetails}>
                  <p>{item.name}</p>
                  {item.isCustomized && (
                    <p className={styles.customisationStatus}>
                      {t("Customised")}
                    </p>
                  )}
                  {item.selectedClient === "new" &&
                    item.sizes &&
                    typeof item.sizes === "object" && (
                      <p>
                        {t("Sizes")}: {Object.values(item.sizes).join(" / ")}
                      </p>
                    )}
                </div>
              </div>
              <div className={styles.cartItemPrice}>
                <p>{formatPrice(item.price)}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className={styles.cartSubtotal}>
        <p>{t("Subtotal")}:</p>
        <p>{formatPrice(parseFloat(subtotal) || 0)}</p>
      </div>
    </div>
  );

  const prioritizedCountries = [
    "France",
    "Belgium",
    "United Kingdom",
    "Guinea Conakry",
    "Portugal",
    "Germany",
    "Italy",
    "Switzerland",
    "Nigeria",
    "United States of America",
    "UAE",
  ];
  const allCountries = getNames();
  const otherCountries = allCountries.filter(
    (country) => !prioritizedCountries.includes(country)
  );

  return (
    <div className={styles.checkoutWrapper}>
      <header className={styles.checkoutHeader}>
        <button
          onClick={handleBackClick}
          className={styles.backButton}
          aria-label="Go Back"
        >
          <BackArrowIcon className={styles.backIcon} />
        </button>
        <div className={styles.brandText} onClick={handleBrandClick}>
          MCKNIGHT
        </div>
      </header>
      <div className={styles.checkoutContainer}>
        {isMobile && (
          <div className={styles.collapsibleMobile}>
            <Collapsible title={t("Show Order Summary")}>
              {orderSummary}
            </Collapsible>
          </div>
        )}

        <div className={styles.checkoutLeft}>
          <div className={styles.checkoutSection}>
            <h2>{t("Contact")}</h2>

            <div className={styles.checkoutFormRow}>
              {isPhoneDisabled ? (
                <input
                  type="text"
                  value={maskPhoneNumber(formData.phone)}
                  readOnly
                  className={styles.checkoutInput}
                  disabled={isPhoneDisabled} // Disable input if existing client
                />
              ) : (
                <PhoneInput
                  country={"fr"}
                  value={formData.phone}
                  onChange={(phone) => setFormData({ ...formData, phone })}
                  inputStyle={{ width: "100%" }} // Optional: for styling consistency
                  disabled={isPhoneDisabled} // Disable input if existing client
                  placeholder="Enter phone number"
                  enableSearch={true} // Optional: enhances user experience
                  aria-label="Enter phone number"
                />
              )}
            </div>
          </div>

          <div className={styles.checkoutSection}>
            <h2>{t("Delivery")}</h2>
            <div className={styles.checkoutFormRow}>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
                className={styles.checkoutSelect}
              >
                <option value="">{t("Country")}</option>
                {prioritizedCountries.map((country) => (
                  <option key={country} value={country}>
                    {t(country)}
                  </option>
                ))}
                <option disabled>──────────</option>
                {otherCountries.map((country) => (
                  <option key={country} value={country}>
                    {t(country)}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.checkoutFormRow}>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder={t("First name")}
                className={styles.checkoutInput}
                required
              />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder={t("Last name")}
                className={styles.checkoutInput}
                required
              />
            </div>
            <div className={styles.checkoutFormRow}>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder={t("Address")}
                className={styles.checkoutInput}
                required
              />
            </div>
            <div className={styles.checkoutFormRow}>
              <input
                type="text"
                name="apartment"
                value={formData.apartment}
                onChange={handleInputChange}
                placeholder={t("Apartment, suite, etc. (optional)")}
                className={styles.checkoutInput}
              />
            </div>
            <div className={styles.checkoutFormRow}>
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
                placeholder={t("Postal code")}
                className={styles.checkoutInput}
                required
              />
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder={t("City")}
                className={styles.checkoutInput}
                required
              />
            </div>
          </div>

          {/* Payment Information Box */}
          <h2>{t("Payment")}</h2>
          <p className={styles.paymentBoxContent}>
            {t("All transactions are secure and encrypted.")}
          </p>
          <div className={styles.paymentBox}>
            <label className={styles.paymentOption}>
              <input
                type="radio"
                name="paymentMethod"
                value="Credit/Debit Card"
                checked
                disabled
                className={styles.paymentRadio}
              />
              <span className={styles.paymentLabel}>
                {t("Credit/Debit Card")}
              </span>
            </label>
            <p className={styles.paymentBoxContent}>
              {t(
                "After clicking “Pay Now”, you will be redirected to Credit/Debit Card to complete your purchase securely."
              )}
            </p>
          </div>

          {/* Pay Now Button */}
          <button
            className={styles.payNowButton}
            onClick={handlePayNow}
            disabled={cart.length === 0} // Disable if cart is empty
          >
            {t("PAY NOW")}
          </button>
        </div>

        {/* Order Summary Section - Desktop */}
        {!isMobile && (
          <div className={styles.checkoutRight}>{orderSummary}</div>
        )}

        <Overlay
          message="Order placed successfully!"
          isVisible={isSuccessOverlayVisible}
          onClose={() => {
            setIsSuccessOverlayVisible(false);
            clearCart(); // **Clear the cart here**

            navigate("/products", { replace: true }); // Redirect after closing the overlay and clear navigation history
          }}
          productSummary={productSummary} // Pass the product summary to the overlay
        />
      </div>
    </div>
  );
};

export default Checkout;
