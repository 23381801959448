// Products.js

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Dropdown2 from "./Dropdown2";
import "../style/Products.css";
import { useTranslation } from "react-i18next";
import { db } from "../firebase-config";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { CurrencyContext } from "../context/CurrencyContext"; // Import CurrencyContext

const Products = () => {
  const { t, i18n } = useTranslation();
  const { currency, exchangeRates } = useContext(CurrencyContext); // Access CurrencyContext

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [productsToShowCount, setProductsToShowCount] = useState(48);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const q = query(
          collection(db, "categories"),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const fetchedCategories = querySnapshot.docs.map((doc) => doc.id);
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch products and collect colors
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);

        const fetchedProducts = new Map(); // Map to avoid duplicate product names
        const colorMap = new Map();

        // Iterate over categories fetched from the 'categories' collection
        for (const category of categories) {
          const itemsCollection = collection(db, "products", category, "items");
          const q = query(itemsCollection, orderBy("createdAt", "desc"));
          const productsSnapshot = await getDocs(q);

          productsSnapshot.forEach((doc) => {
            const productData = doc.data();
            const createdAt = productData.createdAt
              ? productData.createdAt.toDate()
              : new Date(0);

            // Skip products with visibility "No"
            if (productData.categories[0].visibility === "No") {
              return;
            }

            // Prepare a key to uniquely identify the product
            const productKey =
              productData.uuid || productData.productId || doc.id;

            // If a product with the same key already exists, merge categories
            if (fetchedProducts.has(productKey)) {
              const existingProduct = fetchedProducts.get(productKey);
              existingProduct.categories.push({
                category: category,
                visibility: productData.categories[0].visibility,
              });
            } else {
              fetchedProducts.set(productKey, {
                id: doc.id,
                uuid: productData.uuid || null,
                name: productData.name || "Unknown Product",
                price: `£${productData.price || "0"}`,
                displayImages: productData.displayImages || [],
                imageUrl: productData.displayImages?.[0] || "",
                colors: productData.colors || [],
                categories: [
                  {
                    category: category,
                    visibility: productData.categories[0].visibility || "All",
                  },
                ],
                selectedProductId: productData.productId || "",
                createdAt,
                newIn: productData.newIn || "",
              });
            }

            // Collect only the first color of each product
            if (productData.colors && productData.colors.length > 0) {
              const firstColor = productData.colors[0];
              if (firstColor.name) {
                if (!colorMap.has(firstColor.name)) {
                  colorMap.set(firstColor.name, {
                    name: firstColor.name,
                    frenchName: firstColor.frenchName || firstColor.name,
                  });
                }
              }
            }
          });
        }

        // Convert Map to array and sort by createdAt descending
        const productsArray = Array.from(fetchedProducts.values()).sort(
          (a, b) => b.createdAt - a.createdAt
        );

        setProducts(productsArray);
        setFilteredProducts(productsArray);
        setDisplayedProducts(productsArray.slice(0, productsToShowCount)); // Initialize displayed products
        setColors(Array.from(colorMap.values())); // Store color objects
        setAvailableColors(["Select All", ...Array.from(colorMap.keys())]); // Store color names (English)
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to load products. Please try again later.");
        setIsLoading(false);
      }
    };

    if (categories.length > 0) {
      fetchProducts();
    }
  }, [categories]);

  // Apply filters
  useEffect(() => {
    const applyFilters = () => {
      let filtered = products;

      // Apply visibility logic
      filtered = filtered.filter((product) => {
        const productVisibilities = product.categories.map(
          (cat) => cat.visibility
        );

        // If the product has visibility "No" in all categories, exclude it
        if (productVisibilities.every((vis) => vis === "No")) {
          return false;
        }

        // Handle visibility "All"
        if (productVisibilities.includes("All")) {
          return true;
        }

        // Handle visibility "Limited"
        if (productVisibilities.includes("Limited")) {
          // If no categories are selected, exclude products with "Limited" visibility
          if (
            selectedCategories.length === 0 ||
            selectedCategories.includes("Select All")
          ) {
            return false;
          }

          // Check if the product's categories intersect with selectedCategories
          const productCategories = product.categories
            .filter((cat) => cat.visibility === "Limited")
            .map((cat) => cat.category);

          const isInSelectedCategory = selectedCategories.some((selectedCat) =>
            productCategories.includes(selectedCat)
          );

          return isInSelectedCategory;
        }

        return false;
      });

      // Apply category filter
      if (
        selectedCategories.length > 0 &&
        !selectedCategories.includes("Select All")
      ) {
        filtered = filtered.filter((product) => {
          const productCategories = product.categories.map(
            (cat) => cat.category
          );
          return selectedCategories.some((selectedCat) =>
            productCategories.includes(selectedCat)
          );
        });
      }

      // Apply color filter based on internal values
      if (selectedColors.length > 0 && !selectedColors.includes("select_all")) {
        filtered = filtered.filter((product) => {
          const firstColorName = product.colors[0]?.name;
          return selectedColors.includes(firstColorName);
        });
      }

      setFilteredProducts(filtered);
      setDisplayedProducts(filtered.slice(0, productsToShowCount)); // Update displayed products
    };

    applyFilters();
  }, [selectedCategories, selectedColors, products, productsToShowCount]);

  // Update displayed products when productsToShowCount changes
  useEffect(() => {
    setDisplayedProducts(filteredProducts.slice(0, productsToShowCount));
  }, [filteredProducts, productsToShowCount]);

  // Reset selectedColors if they are no longer available
  useEffect(() => {
    if (selectedColors.length > 0 && !selectedColors.includes("select_all")) {
      const validSelectedColors = selectedColors.filter((color) =>
        availableColors.includes(color)
      );
      if (validSelectedColors.length !== selectedColors.length) {
        setSelectedColors(validSelectedColors);
      }
    }
  }, [availableColors, selectedColors]);

  const handleProductClick = (product) => {
    window.scrollTo(0, 0);
    const categoryName = product.categories[0].category;

    // Construct the URL with query parameters
    const url = `/full-product?category=${encodeURIComponent(
      categoryName
    )}&productId=${encodeURIComponent(product.id)}`;

    // Navigate to the FullProduct.js page with the constructed URL
    window.location.href = url;
  };

  // Prepare category options with "Select All" as the first item
  const categoryOptions = ["Select All", ...categories].map((cat) => t(cat));

  // Prepare color options as objects with value and label
  const colorOptions = availableColors.map((colorName) => {
    if (colorName === "Select All") {
      return { value: "select_all", label: t("Select All") };
    } else {
      const colorObj = colors.find((c) => c.name === colorName);
      return {
        value: colorObj.name, // Original name for filtering
        label:
          i18n.language === "fr" && colorObj.frenchName
            ? colorObj.frenchName
            : colorObj.name, // Translated name for display
      };
    }
  });

  // Prevent scrolling when overlay is visible without affecting scroll position across pages
  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };

    if (isOverlayVisible) {
      document.addEventListener("touchmove", preventScroll, { passive: false });
      document.addEventListener("wheel", preventScroll, { passive: false });
    } else {
      document.removeEventListener("touchmove", preventScroll);
      document.removeEventListener("wheel", preventScroll);
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      document.removeEventListener("touchmove", preventScroll);
      document.removeEventListener("wheel", preventScroll);
    };
  }, [isOverlayVisible]);

  const handleLoadMore = () => {
    setProductsToShowCount((prevCount) => prevCount + 48);
  };

  // Helper function to format price based on selected currency
  const formatPrice = (priceInGBP) => {
    let convertedPrice = priceInGBP;
    let symbol = "£"; // Default symbol

    switch (currency) {
      case "EUR":
        convertedPrice = priceInGBP * (exchangeRates["EUR"] || 1);
        symbol = "€";
        break;
      case "USD":
        convertedPrice = priceInGBP * (exchangeRates["USD"] || 1);
        symbol = "$";
        break;
      case "GBP":
      default:
        convertedPrice = priceInGBP;
        symbol = "£";
        break;
    }

    return `${symbol}${convertedPrice.toFixed(2)}`;
  };

  return (
    <div>
      {isOverlayVisible && (
        <div
          className="invisible-overlay"
          onClick={() => setOverlayVisible(false)}
        ></div>
      )}
      <div className="dropdown-container">
        <Dropdown2
          label={t("Color")}
          options={colorOptions} // Array of { value, label }
          selectedOptions={selectedColors} // Array of original color names
          setSelectedOptions={setSelectedColors}
          setOverlayVisible={setOverlayVisible}
        />
        <Dropdown2
          label={t("Categories")}
          options={categoryOptions} // Array of strings (labels)
          selectedOptions={selectedCategories}
          setSelectedOptions={setSelectedCategories}
          setOverlayVisible={setOverlayVisible}
        />
      </div>

      {/* Optional: Display error message if any */}
      {error && <div className="error-message">{error}</div>}

      {/* Display custom loading spinner if data is still loading */}
      {isLoading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="products-grid-unique">
            {displayedProducts.map((product, index) => (
              <div
                key={product.id}
                className="product-wrapper"
                onClick={() => handleProductClick(product)}
              >
                <div className="product-container-unique">
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className="product-image-unique"
                    loading="lazy"
                  />
                </div>
                <div className="product-details-unique">
                  <div className="new-in-unique">
                    {product.newIn ? t("Full Set") : <span>&nbsp;</span>}
                  </div>
                  <div className="item-name-unique">{product.name}</div>
                  <div className="price-unique">
                    {formatPrice(
                      parseFloat(product.price.replace(/[^0-9.-]+/g, "")) || 0
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Load More Section */}
          {displayedProducts.length < filteredProducts.length && (
            <div className="load-more-container">
              <span onClick={handleLoadMore} className="load-more-text">
                {t("Load More")} | {displayedProducts.length} {t("of")}{" "}
                {filteredProducts.length}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Products;
