// dropdown2.js

import React, { useState, useRef, useEffect, useCallback } from "react";
import { ReactComponent as DropDownIcon } from "../icons/dropdown.svg";

const Dropdown = ({ label, options, selectedOptions, setSelectedOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  // Function to handle option clicks
  const handleOptionClick = (optionValue) => {
    if (optionValue === "select_all" || optionValue === "clear") {
      if (selectedOptions.length === getOptionValues().length) {
        setSelectedOptions([]);
      } else {
        const allValues = getOptionValues();
        setSelectedOptions(allValues);
      }
    } else {
      const newSelectedOptions = selectedOptions.includes(optionValue)
        ? selectedOptions.filter((value) => value !== optionValue)
        : [...selectedOptions, optionValue];
      setSelectedOptions(newSelectedOptions);
    }
    // Close the dropdown after selecting an option
    setIsOpen(false);
  };

  // Helper function to extract internal values from options
  const getOptionValues = () => {
    return options.map((option) =>
      typeof option === "object" ? option.value : option
    );
  };

  // Close dropdown when clicking outside
  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  // Function to chunk options for layout purposes
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  // Determine if options are objects or strings
  const isObjectOption = typeof options[0] === "object" && options[0] !== null;

  // Create chunks excluding the first option ("Select All")
  const optionChunks = chunkArray(options.slice(1), 5); // Exclude "Select All"

  return (
    <div className={`dropdown ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        {label} <DropDownIcon className="dropdown-icon" />
      </button>
      {isOpen && (
        <>
          <div
            className="dropdown-overlay"
            onClick={() => setIsOpen(false)}
          ></div>
          <div className="dropdown-menu2">
            <div
              className="dropdown-item dropdown-select-all"
              onClick={() =>
                handleOptionClick(
                  selectedOptions.length === getOptionValues().length
                    ? "clear"
                    : "select_all"
                )
              }
            >
              {selectedOptions.length === getOptionValues().length
                ? "Clear"
                : "Select All"}
            </div>
            {optionChunks.map((chunk, chunkIndex) => (
              <div key={chunkIndex} className="dropdown-row">
                {chunk.map((option) => {
                  const value = isObjectOption ? option.value : option;
                  const label = isObjectOption ? option.label : option;

                  return (
                    <div
                      key={value}
                      className="dropdown-item"
                      onClick={() => handleOptionClick(value)}
                    >
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes(value)}
                        readOnly
                      />
                      {label}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
