// specificproduct.js

import React, { useState, useEffect, useContext } from "react";
import Dropdown2 from "./Dropdown2";
import "../style/Products.css";
import { useTranslation } from "react-i18next";
import { db } from "../firebase-config";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { CurrencyContext } from "../context/CurrencyContext";

const SpecificProduct = () => {
  const { t, i18n } = useTranslation();
  const { category } = useParams();
  const navigate = useNavigate();
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { currency, exchangeRates } = useContext(CurrencyContext); // Access CurrencyContext
  const [error, setError] = useState(null); // Define error state

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const q = query(
          collection(db, "categories"),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const fetchedCategories = querySnapshot.docs.map((doc) => doc.id);
        setCategories(fetchedCategories);
      } catch (err) {
        // Rename parameter to avoid shadowing
        console.error("Error fetching categories:", err);
        setError("Failed to fetch categories. Please try again later.");
      }
    };

    fetchCategories();
  }, []);

  // Map URL path to category name and set initial selected categories
  useEffect(() => {
    if (category && categories.length > 0) {
      // Create a mapping of category name to its URL path
      const categoryMap = categories.map((catName) => ({
        name: catName,
        path: catName.toLowerCase().replace(/\s+/g, "-"),
      }));

      // Find the category that matches the URL path
      const matchedCategory = categoryMap.find((cat) => cat.path === category);

      if (matchedCategory) {
        setSelectedCategories([matchedCategory.name]);
      } else {
        console.warn(`No matching category found for path: ${category}`);
        setSelectedCategories([]);
      }
    }
  }, [category, categories]);

  // Fetch all colors from all categories
  useEffect(() => {
    const fetchAllColors = async () => {
      try {
        const colorMap = new Map();

        for (const category of categories) {
          const itemsCollection = collection(db, "products", category, "items");
          const itemsSnapshot = await getDocs(itemsCollection);

          itemsSnapshot.docs.forEach((itemDoc) => {
            const itemData = itemDoc.data();

            // Collect only the first color of each product
            if (itemData.colors && itemData.colors.length > 0) {
              const firstColor = itemData.colors[0];
              if (firstColor.name) {
                if (!colorMap.has(firstColor.name)) {
                  colorMap.set(firstColor.name, {
                    name: firstColor.name,
                    frenchName: firstColor.frenchName || firstColor.name,
                  });
                }
              }
            }
          });
        }

        setColors(Array.from(colorMap.values()));
        setAvailableColors(["Select All", ...Array.from(colorMap.keys())]);
      } catch (err) {
        // Rename parameter to avoid shadowing
        console.error("Error fetching colors:", err);
        setError("Failed to fetch colors. Please try again later.");
      }
    };

    if (categories.length > 0) {
      fetchAllColors();
    }
  }, [categories]);

  // Fetch products from selected categories or all categories
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);

        const fetchedProducts = new Map();

        // Determine which categories to fetch from
        const categoriesToFetch =
          selectedCategories.length === 0 ||
          selectedCategories.includes("Select All")
            ? categories
            : selectedCategories;

        for (const selectedCategory of categoriesToFetch) {
          const itemsCollection = collection(
            db,
            "products",
            selectedCategory,
            "items"
          );
          const q = query(itemsCollection, orderBy("createdAt", "desc"));
          const productsSnapshot = await getDocs(q);

          productsSnapshot.forEach((doc) => {
            const itemData = doc.data();
            const createdAt = itemData.createdAt
              ? itemData.createdAt.toDate()
              : new Date(0);

            // Get visibility for the selected category
            const categoryVisibilityObj = itemData.categories.find(
              (cat) => cat.category === selectedCategory
            );

            const visibility = categoryVisibilityObj
              ? categoryVisibilityObj.visibility
              : "All"; // Default to "All" if not specified

            // Skip products with visibility "No"
            if (visibility === "No") {
              return; // Skip this product
            }

            // Prepare a key to uniquely identify the product
            const productKey = itemData.uuid || itemData.productId || doc.id;

            // If a product with the same key already exists, merge categories
            if (fetchedProducts.has(productKey)) {
              const existingProduct = fetchedProducts.get(productKey);
              existingProduct.categories.push({
                category: selectedCategory,
                visibility: visibility,
              });
            } else {
              fetchedProducts.set(productKey, {
                id: doc.id,
                uuid: itemData.uuid || null,
                name: itemData.name || "Unknown Product",
                price: `£${itemData.price || "0"}`,
                displayImages: itemData.displayImages || [],
                imageUrl: itemData.displayImages?.[0] || "",
                colors: itemData.colors || [],
                categories: [
                  {
                    category: selectedCategory,
                    visibility: visibility,
                  },
                ],
                selectedProductId: itemData.productId || "",
                createdAt,
                newIn: itemData.newIn || "",
              });
            }
          });
        }

        // Convert Map to array and sort products
        const productsArray = Array.from(fetchedProducts.values()).sort(
          (a, b) => b.createdAt - a.createdAt
        );

        setProducts(productsArray);
        setFilteredProducts(productsArray);
        setIsLoading(false);
      } catch (err) {
        // Rename parameter to avoid shadowing
        console.error("Error fetching products:", err);
        setError("Failed to fetch products. Please try again later.");
        setIsLoading(false);
      }
    };

    if (categories.length > 0) {
      fetchProducts();
    }
  }, [selectedCategories, categories]);

  // Apply filters (do not update availableColors here)
  useEffect(() => {
    const applyFilters = () => {
      let filtered = products;

      // Apply visibility logic
      filtered = filtered.filter((product) => {
        const productVisibilities = product.categories.map(
          (cat) => cat.visibility
        );

        // If the product has visibility "No" in all categories, exclude it
        if (productVisibilities.every((vis) => vis === "No")) {
          return false;
        }

        // Handle visibility "All"
        if (productVisibilities.includes("All")) {
          return true;
        }

        // Handle visibility "Limited"
        if (productVisibilities.includes("Limited")) {
          // If no categories are selected, exclude products with "Limited" visibility
          if (
            selectedCategories.length === 0 ||
            selectedCategories.includes("Select All")
          ) {
            return false;
          }

          // Check if the product's categories intersect with selectedCategories
          const productCategories = product.categories
            .filter((cat) => cat.visibility === "Limited")
            .map((cat) => cat.category);

          const isInSelectedCategory = selectedCategories.some((selectedCat) =>
            productCategories.includes(selectedCat)
          );

          return isInSelectedCategory;
        }

        return false;
      });

      // Apply category filter
      if (
        selectedCategories.length > 0 &&
        !selectedCategories.includes("Select All")
      ) {
        filtered = filtered.filter((product) => {
          const productCategories = product.categories.map(
            (cat) => cat.category
          );
          return selectedCategories.some((selectedCat) =>
            productCategories.includes(selectedCat)
          );
        });
      }

      // Apply color filter based on internal values
      if (selectedColors.length > 0 && !selectedColors.includes("select_all")) {
        filtered = filtered.filter((product) => {
          const firstColorName = product.colors[0]?.name;
          return selectedColors.includes(firstColorName);
        });
      }

      setFilteredProducts(filtered);
    };

    applyFilters();
  }, [selectedCategories, selectedColors, products]);

  // Reset selectedColors if they are no longer available
  useEffect(() => {
    if (selectedColors.length > 0 && !selectedColors.includes("select_all")) {
      const validSelectedColors = selectedColors.filter((color) =>
        availableColors.includes(color)
      );
      if (validSelectedColors.length !== selectedColors.length) {
        setSelectedColors(validSelectedColors);
      }
    }
  }, [availableColors, selectedColors]);

  const handleProductClick = (product) => {
    window.scrollTo(0, 0);
    const categoryName = product.categories[0].category;

    // Construct the URL with query parameters
    const url = `/full-product?category=${encodeURIComponent(
      categoryName
    )}&productId=${encodeURIComponent(product.id)}`;

    // Navigate to the FullProduct.js page with the constructed URL
    window.location.href = url;
  };

  // Prepare category options with "Select All" as the first item
  const categoryOptions = ["Select All", ...categories].map((cat) => t(cat));

  // Prepare color options as objects with value and label
  const colorOptions = availableColors.map((colorName) => {
    if (colorName === "Select All") {
      return { value: "select_all", label: t("Select All") };
    } else {
      const colorObj = colors.find((c) => c.name === colorName);
      return {
        value: colorObj.name, // Original name for filtering
        label:
          i18n.language === "fr" && colorObj.frenchName
            ? colorObj.frenchName
            : colorObj.name, // Translated name for display
      };
    }
  });

  // Prevent scrolling when overlay is visible without affecting scroll position across pages
  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };

    if (isOverlayVisible) {
      document.addEventListener("touchmove", preventScroll, { passive: false });
      document.addEventListener("wheel", preventScroll, { passive: false });
    } else {
      document.removeEventListener("touchmove", preventScroll);
      document.removeEventListener("wheel", preventScroll);
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      document.removeEventListener("touchmove", preventScroll);
      document.removeEventListener("wheel", preventScroll);
    };
  }, [isOverlayVisible]);

  // Helper function to format price based on selected currency
  const formatPrice = (priceInGBP) => {
    let convertedPrice = priceInGBP;
    let symbol = "£"; // Default symbol

    switch (currency) {
      case "EUR":
        convertedPrice = priceInGBP * (exchangeRates["EUR"] || 1);
        symbol = "€";
        break;
      case "USD":
        convertedPrice = priceInGBP * (exchangeRates["USD"] || 1);
        symbol = "$";
        break;
      case "GBP":
      default:
        convertedPrice = priceInGBP;
        symbol = "£";
        break;
    }

    return `${symbol}${convertedPrice.toFixed(2)}`;
  };

  return (
    <div className="specific-product-container">
      {isOverlayVisible && (
        <div
          className="invisible-overlay"
          onClick={() => setOverlayVisible(false)}
        ></div>
      )}
      <div className="dropdown-container">
        <Dropdown2
          label={t("Color")}
          options={colorOptions} // Array of { value, label }
          selectedOptions={selectedColors} // Array of original color names
          setSelectedOptions={setSelectedColors}
          setOverlayVisible={setOverlayVisible}
        />
        <Dropdown2
          label={t("Categories")}
          options={categoryOptions} // Array of strings (labels)
          selectedOptions={selectedCategories}
          setSelectedOptions={setSelectedCategories}
          setOverlayVisible={setOverlayVisible}
        />
      </div>

      {/* Optional: Display error message if any */}
      {error && <div className="error-message">{error}</div>}

      {/* Display custom loading spinner if data is still loading */}
      {isLoading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="products-grid-unique">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="product-wrapper-unique"
              onClick={() => handleProductClick(product)}
            >
              <div className="product-container-unique">
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  className="product-image-unique"
                />
              </div>
              <div className="product-details-unique">
                <div className="new-in-unique">
                  {product.newIn ? t("Full Set") : <span>&nbsp;</span>}
                </div>
                <div className="item-name-unique">{product.name}</div>
                <div className="price-unique">
                  {formatPrice(
                    parseFloat(product.price.replace(/[^0-9.-]+/g, "")) || 0
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SpecificProduct;
