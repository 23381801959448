import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { uploadImageWithMetadata } from "../firebaseActions";
import "../style/Settings.css";

const Settings = () => {
  const today = new Date().toLocaleDateString();

  // State variables for FX settings
  const [euroToDollar, setEuroToDollar] = useState("");
  const [euroToPound, setEuroToPound] = useState("");
  const [isSavingFX, setIsSavingFX] = useState(false); // Loader for FX save
  const [isChangingPassword, setIsChangingPassword] = useState(false); // Loader for password change
  const [notification, setNotification] = useState("");

  // State variables for password management
  const [currentPassword, setCurrentPassword] = useState(""); // Current password input
  const [newPassword, setNewPassword] = useState(""); // New password input
  const [storedPassword, setStoredPassword] = useState(""); // Actual stored password in Firestore

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        // Fetch FX rates
        const fxDoc = await getDoc(doc(db, "FX", "rates"));
        if (fxDoc.exists()) {
          const data = fxDoc.data();
          setEuroToDollar(data.euroToDollar || "");
          setEuroToPound(data.euroToPound || "");
        }

        // Fetch current password from Firestore
        const passwordDoc = await getDoc(
          doc(db, "AdminSettings", "credentials")
        );
        if (passwordDoc.exists()) {
          setStoredPassword(passwordDoc.data().currentPassword); // Store the actual current password
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSettingsData();
  }, []);

  // Handle save button click for FX settings
  const handleSaveClick = async () => {
    setIsSavingFX(true);
    try {
      await setDoc(doc(db, "FX", "rates"), {
        euroToDollar,
        euroToPound,
      });

      setNotification("FX settings updated successfully!");
      setTimeout(() => setNotification(""), 3000); // Clear notification after 3 seconds
    } catch (error) {
      console.error("Error updating FX settings:", error);
      setNotification("Failed to update FX settings. Please try again.");
    } finally {
      setIsSavingFX(false); // Stop loading
    }
  };

  // Handle password change
  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword) {
      setNotification("Please fill in both fields.");
      return;
    }

    setIsChangingPassword(true); // Start loading
    try {
      // Fetch the stored password again to verify it
      const passwordDoc = await getDoc(doc(db, "AdminSettings", "credentials"));
      if (passwordDoc.exists()) {
        const storedPassword = passwordDoc.data().currentPassword;

        // Check if the current password entered matches the one stored in Firestore
        if (currentPassword === storedPassword) {
          // Update the password in Firestore
          await setDoc(doc(db, "AdminSettings", "credentials"), {
            currentPassword: newPassword,
            email: passwordDoc.data().email, // Preserve the email field
          });

          setNotification("Password updated successfully!");
        } else {
          setNotification("Current password is incorrect.");
        }
      } else {
        setNotification("Error: Unable to retrieve stored password.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setNotification("Failed to update password. Please try again.");
    } finally {
      setIsChangingPassword(false); // Stop loading
      setTimeout(() => setNotification(""), 3000); // Clear notification after 3 seconds
    }
  };

  // Handle file change for promotional pictures
  const handlePromoPictureClick = (type) => {
    document.getElementById(`fileInput-${type}`).click();
  };

  // Handle file change for promotional pictures with separate paths for mobile and desktop
  const handleFileChange = async (event, type) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      try {
        // Separate documents for mobile and desktop images
        const filePath =
          type === "Mobile"
            ? `dkp-promo-mobile/${selectedFile.name}`
            : `dkp-promo-desktop/${selectedFile.name}`;

        // Upload the image
        await uploadImageWithMetadata(selectedFile, filePath, {
          cacheControl: "public, max-age=31536000",
        });

        // Store the file name in separate documents for mobile and desktop
        const docPath = type === "Mobile" ? "mobile" : "desktop";

        // Store the latest file name and type in Firestore under separate documents
        await setDoc(doc(db, "PromoImages", docPath), {
          fileName: selectedFile.name,
          type: type, // Mobile or Desktop
        });

        alert(`Promotional picture (${type}) uploaded successfully!`);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert(
          `Failed to upload promotional picture (${type}). Please try again.`
        );
      }
    }
  };

  return (
    <div className="settings-container">
      <div className="header">
        <div className="date">{today}</div>
      </div>
      <div className="title-section">
        <div className="quick-settings-title">Quick Settings</div>
      </div>
      <div className="fx-password-container">
        <div className="fx-settings-section">
          <div className="fx-title">Currency Exchange (FX) Settings</div>
          <div className="fx-group">
            <label>Euro to Dollar:</label>
            <input
              type="number"
              value={euroToDollar}
              onChange={(e) => setEuroToDollar(e.target.value)}
              className="fx-input"
            />
          </div>
          <div className="fx-group">
            <label>Euro to Pound:</label>
            <input
              type="number"
              value={euroToPound}
              onChange={(e) => setEuroToPound(e.target.value)}
              className="fx-input"
            />
          </div>
          <button
            className="save-fx-button"
            onClick={handleSaveClick}
            disabled={isSavingFX}
          >
            {isSavingFX ? <span className="loader"></span> : "Save"}
          </button>
        </div>
        <div className="change-password-section">
          <div className="change-password-title">Change Password</div>
          <div className="change-password-form">
            <div className="form-group-unique-settings">
              <label>Enter current password:</label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="form-group-unique-settings">
              <label>New Password:</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <button
              className="change-password-button"
              onClick={handleChangePassword}
              disabled={isChangingPassword}
            >
              {isChangingPassword ? (
                <span className="loader"></span>
              ) : (
                "Change Password"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="promo-pictures-section">
        <div
          className="promo-picture"
          onClick={() => handlePromoPictureClick("Desktop")}
        >
          Change Promotional <br /> Picture (Desktop)
        </div>
        <input
          type="file"
          id="fileInput-Desktop"
          style={{ display: "none" }}
          onChange={(event) => handleFileChange(event, "Desktop")}
        />
        <div
          className="promo-picture"
          onClick={() => handlePromoPictureClick("Mobile")}
        >
          Change Promotional <br /> Picture (Mobile)
        </div>
        <input
          type="file"
          id="fileInput-Mobile"
          style={{ display: "none" }}
          onChange={(event) => handleFileChange(event, "Mobile")}
        />
      </div>
      {notification && (
        <div className={`notification ${notification ? "show" : ""}`}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default Settings;
