import React, { useState } from "react";
import "../style/Dashboard.css";
import downIcon from "../icons/down.svg"; // Import the down arrow icon

const Dashboard = () => {
  const today = new Date().toLocaleDateString();

  const [mostBoughtDropdownVisible, setMostBoughtDropdownVisible] =
    useState(false);
  const [mostBoughtPeriod, setMostBoughtPeriod] = useState("past month");

  const [leastBoughtDropdownVisible, setLeastBoughtDropdownVisible] =
    useState(false);
  const [leastBoughtPeriod, setLeastBoughtPeriod] = useState("past month");

  const toggleMostBoughtDropdown = () => {
    setMostBoughtDropdownVisible(!mostBoughtDropdownVisible);
  };

  const toggleLeastBoughtDropdown = () => {
    setLeastBoughtDropdownVisible(!leastBoughtDropdownVisible);
  };

  const handleMostBoughtOptionClick = (period) => {
    setMostBoughtPeriod(period);
    setMostBoughtDropdownVisible(false);
  };

  const handleLeastBoughtOptionClick = (period) => {
    setLeastBoughtPeriod(period);
    setLeastBoughtDropdownVisible(false);
  };

  return (
    <div className="dashboard-container">
      <div className="date">{today}</div>
      <div className="statistics">Statistics</div>
      <div className="stats-container">
        <div className="stats-box">
          <div className="stats-number">18</div>
          <div className="stats-label">Total Products</div>
        </div>
        <div className="stats-box">
          <div className="stats-number">-</div>
          <div className="stats-label">Total Categories</div>
        </div>
        <div className="stats-box">
          <div className="stats-number">15</div>
          <div className="stats-label">Users</div>
        </div>
      </div>
      <div className="additional-containers">
        <div className="container-left">
          <div className="container-header">
            <span>Most bought items</span>
            <div className="dropdown" onClick={toggleMostBoughtDropdown}>
              {mostBoughtPeriod}{" "}
              <img
                src={downIcon}
                alt="Dropdown icon"
                className="dropdown-icon"
              />
              {mostBoughtDropdownVisible && (
                <div className="dropdown-content">
                  <button
                    className="dropdown-item"
                    onClick={() => handleMostBoughtOptionClick("past month")}
                  >
                    past month
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => handleMostBoughtOptionClick("past week")}
                  >
                    past week
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="items-container">
            <div className="item-box"></div>
            <div className="item-box"></div>
            <div className="item-box"></div>
            <div className="item-box"></div>
          </div>
          <div className="item-numbers">
            <div className="item-number">10</div>
            <div className="item-number">7</div>
            <div className="item-number">5</div>
            <div className="item-number">3</div>
          </div>
        </div>
        <div className="container-right">
          <div className="container-header">
            <span>Least bought items</span>
            <div className="dropdown" onClick={toggleLeastBoughtDropdown}>
              {leastBoughtPeriod}{" "}
              <img
                src={downIcon}
                alt="Dropdown icon"
                className="dropdown-icon"
              />
              {leastBoughtDropdownVisible && (
                <div className="dropdown-content">
                  <button
                    className="dropdown-item"
                    onClick={() => handleLeastBoughtOptionClick("past month")}
                  >
                    past month
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => handleLeastBoughtOptionClick("past week")}
                  >
                    past week
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="items-container">
            <div className="item-box"></div>
            <div className="item-box"></div>
            <div className="item-box"></div>
            <div className="item-box"></div>
          </div>
          <div className="item-numbers">
            <div className="item-number">10</div>
            <div className="item-number">7</div>
            <div className="item-number">5</div>
            <div className="item-number">3</div>
          </div>
        </div>
      </div>
      <div className="new-dashboard-container">
        {" "}
        {/* Changed class name */}
        <div className="new-dashboard-container-header">
          {" "}
          {/* Changed class name */}
          <span>Top purchasing customers</span>
          <span className="refresh">Refresh</span>
        </div>
        <div className="customer-table-container">
          <div className="customer-table">
            <div className="customer-row customer-header">
              <div className="name">Name</div>
              <div className="order-count">Order count</div>
              <div className="paid">Paid (€)</div>
              <div className="email">Email</div>
            </div>
            <div className="customer-row">
              <div className="name">Sunny Michales</div>
              <div className="order-count">14</div>
              <div className="paid">2300.00</div>
              <div className="email">sunnymichales12@gmail.com</div>
            </div>
            <div className="customer-row">
              <div className="name">John Doe</div>
              <div className="order-count">10</div>
              <div className="paid">1500.00</div>
              <div className="email">johndoe@example.com</div>
            </div>
            <div className="customer-row">
              <div className="name">Jane Smith</div>
              <div className="order-count">8</div>
              <div className="paid">1200.00</div>
              <div className="email">janesmith@example.com</div>
            </div>
            <div className="customer-row">
              <div className="name">Alice Johnson</div>
              <div className="order-count">6</div>
              <div className="paid">900.00</div>
              <div className="email">alicejohnson@example.com</div>
            </div>
            <div className="customer-row">
              <div className="name">Bob Brown</div>
              <div className="order-count">4</div>
              <div className="paid">600.00</div>
              <div className="email">bobbrown@example.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
