// ViewOrders.js
import React, { useState, useEffect, useMemo } from "react";
import "../style/ViewOrders.css";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  runTransaction,
} from "firebase/firestore";

// Dropdown options for Final Delivery status
const deliveryStatuses = ["New", "Ongoing", "Sent out"];

// Sorting options
const sortOptions = [
  "Order Date", // Added "Order Date"
  "Order ID",
  "EDD Test outfit",
  "EDD Final outfit",
  "Status: New",
  "Status: Ongoing",
];

const ViewOrders = () => {
  const today = new Date().toLocaleDateString();
  const navigate = useNavigate();

  // State to manage the orders
  const [entries, setEntries] = useState([]);

  // State to manage the sorting option
  const [sortOption, setSortOption] = useState("Order Date"); // Set default to "Order Date"

  // Function to format date strings (DD/MM/YYYY)
  const formatDateHere = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString; // Return the original string if invalid date
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Fetch orders from Firestore
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersCollectionRef = collection(db, "Orders");
        const ordersSnapshot = await getDocs(ordersCollectionRef);
        const ordersData = ordersSnapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data();

          // Existing data mapping...
          const customerName = `${data.customerName || ""} ${
            data.customerLastName || ""
          }`.trim();
          const orderId = data.orderID || "";
          const orderDate = data.orderDate || "";
          const products = data.products || [];
          const productId = products
            .map((product) => product.productId || "")
            .filter((id) => id !== "")
            .join(", ");
          const productNames = products
            .map((product) => product.productName || "")
            .join(", ");
          const prices = products
            .map((product) => `€${product.price || 0}`)
            .join(", ");

          const firstProduct = products[0] || {};
          const testOutfitDetails =
            firstProduct.selectedClient === "new"
              ? Object.values(firstProduct.sizes || {}).join(", ")
              : "Already Client";
          const expectedDeliveryDateToClient = firstProduct.testOutfitDate
            ? formatDateHere(firstProduct.testOutfitDate)
            : "";
          const expectedFinalDeliveryDate = firstProduct.deliveryDate
            ? formatDateHere(firstProduct.deliveryDate)
            : "";

          // **Extract Customizations Per Product Including Color**
          const productCustomizations = products.map((product) => {
            let customizations = [];

            // Add existing customizations if any
            if (
              product.customizations &&
              typeof product.customizations === "object" &&
              !Array.isArray(product.customizations) &&
              Object.keys(product.customizations).length > 0
            ) {
              const formattedCustomizations = Object.entries(
                product.customizations
              )
                .map(([key, value]) => `${key}: ${value}`)
                .join(", ");
              customizations.push(formattedCustomizations);
            } else {
              customizations.push("None");
            }

            // Append Color if it's not "No Color Selected"
            if (
              product.color &&
              product.color.trim() !== "" &&
              product.color.trim().toLowerCase() !== "no color selected"
            ) {
              customizations.push(`Color: ${product.color}`);
            }

            const finalCustomizations = customizations.join(", ");

            return {
              productName: product.productName || "Unnamed Product",
              customizations: finalCustomizations,
            };
          });

          return {
            firestoreId: docSnapshot.id, // Firestore document ID for deletion
            orderId,
            orderDate,
            productName: productNames,
            price: prices,
            customerName,
            clientCode: data.clientCode || "", // **Updated field**
            testOutfitDetails,
            expectedDeliveryDateToClient,
            expectedFinalDeliveryDate,
            status: data.status || "New", // Fetch status from Firestore or default to "New"
            productId,
            productCustomizations, // Array of { productName, customizations }
          };
        });

        setEntries(ordersData);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  const updateOrderInDatabase = async (order, newStatus) => {
    const originalOrderRef = doc(db, "Orders", order.firestoreId);

    try {
      if (newStatus === "Sent out") {
        const sentOutOrderRef = doc(db, "SentOutOrders", order.orderId);

        await runTransaction(db, async (transaction) => {
          // Add the order to SentOutOrders with the new status
          transaction.set(sentOutOrderRef, {
            ...order,
            status: newStatus,
          });

          // Remove the order from Orders
          transaction.delete(originalOrderRef);
        });

        console.log(
          `Order ${order.orderId} has been marked as ${newStatus}, moved to SentOutOrders, and removed from Orders.`
        );

        // Remove the order from the local state
        setEntries((prevEntries) =>
          prevEntries.filter((entry) => entry.firestoreId !== order.firestoreId)
        );
      } else {
        // Update the status in the original Orders collection
        await setDoc(originalOrderRef, { status: newStatus }, { merge: true });

        console.log(`Order ${order.orderId} status updated to ${newStatus}.`);

        // Update the status in the local state
        setEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.firestoreId === order.firestoreId
              ? { ...entry, status: newStatus }
              : entry
          )
        );
      }
    } catch (error) {
      console.error("Error updating order status in Firestore:", error);
    }
  };

  const handleStatusChange = async (index, newStatus) => {
    const order = sortedEntries[index];

    await updateOrderInDatabase(order, newStatus);
  };

  // Handler for sorting entries
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const parseDate = (dateStr) => {
    if (!dateStr || dateStr.toLowerCase() === "none") return null;

    // Try to parse "DD/MM/YYYY" format
    const parts = dateStr.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts.map(Number);
      return new Date(year, month - 1, day); // Months are zero-indexed in JS Date
    } else {
      // Try to parse ISO date string
      const date = new Date(dateStr);
      if (!isNaN(date)) {
        return date;
      }
    }
    return null; // Unable to parse date
  };

  const sortedEntries = useMemo(() => {
    return [...entries].sort((a, b) => {
      if (sortOption === "Order Date") {
        const dateA = parseDate(a.orderDate);
        const dateB = parseDate(b.orderDate);
        if (dateA && dateB) {
          return dateB - dateA; // Descending order
        } else if (dateA && !dateB) {
          return -1; // a comes before b
        } else if (!dateA && dateB) {
          return 1; // b comes before a
        } else {
          return 0; // Both are invalid dates
        }
      } else if (sortOption === "Order ID") {
        return a.orderId.localeCompare(b.orderId);
      } else if (sortOption === "EDD Test outfit") {
        const dateA = parseDate(a.expectedDeliveryDateToClient);
        const dateB = parseDate(b.expectedDeliveryDateToClient);

        if (dateA && dateB) {
          return dateA - dateB; // Ascending order
        } else if (dateA && !dateB) {
          return -1; // a comes before b
        } else if (!dateA && dateB) {
          return 1; // b comes before a
        } else {
          return 0; // Both are "None"
        }
      } else if (sortOption === "EDD Final outfit") {
        const dateA = parseDate(a.expectedFinalDeliveryDate);
        const dateB = parseDate(b.expectedFinalDeliveryDate);

        if (dateA && dateB) {
          return dateA - dateB; // Ascending order
        } else if (dateA && !dateB) {
          return -1; // a comes before b
        } else if (!dateA && dateB) {
          return 1; // b comes before a
        } else {
          return 0; // Both are "None"
        }
      } else if (sortOption === "Status: New") {
        // Prioritize "New" orders
        if (a.status === "New" && b.status !== "New") return -1;
        if (a.status !== "New" && b.status === "New") return 1;
        return 0;
      } else if (sortOption === "Status: Ongoing") {
        // Prioritize "Ongoing" orders
        if (a.status === "Ongoing" && b.status !== "Ongoing") return -1;
        if (a.status !== "Ongoing" && b.status === "Ongoing") return 1;
        return 0;
      }
      return 0;
    });
  }, [entries, sortOption]);

  return (
    <div className="view-orders-container">
      <div className="header">
        <div className="date">{today}</div>
        <div className="search-container">
          <SearchIcon className="icon" />
          <input type="text" placeholder="Search" className="search-input" />
        </div>
      </div>
      <div className="title-section">
        <div className="view-orders-title">View Orders</div>
        <div className="right-section">
          <div
            className="finished-orders-text"
            onClick={() => navigate("/finished-orders")}
            style={{ cursor: "pointer", color: "#007bff" }}
          >
            Finished Orders
          </div>
          <div className="sort-container">
            <span>Sort by: </span>
            <select
              className="sort-dropdown"
              value={sortOption}
              onChange={handleSortChange}
            >
              {sortOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="orders-table">
        <div className="scrollable-content">
          <div className="orders-header">
            <div className="view-header-cell">
              Order ID
              <br />& Date
            </div>
            <div className="view-header-cell view-header-cell-wide">
              Product Info
            </div>
            <div className="view-header-cell view-header-cell-wide">
              Customer Info
            </div>
            <div className="view-header-cell">
              Test Outfit
              <br />
              Details
            </div>
            <div className="view-header-cell view-header-cell-wide">
              Expected delivery date
              <br />
              of test outfit to client
            </div>
            <div className="view-header-cell view-header-cell-wide">
              Expected delivery date
              <br />
              of final outfit
            </div>
            <div className="view-header-cell view-header-cell-wide">
              Customize outfits
            </div>
            <div className="view-header-cell view-header-cell-wide">Status</div>
          </div>
          {sortedEntries.map((entry, index) => (
            <div className="orders-row" key={entry.firestoreId}>
              <div className="order-cell order-cell-wrap">
                {entry.orderId}
                <br />
                <br />
                Date of Order: {entry.orderDate}
              </div>
              <div className="order-cell order-cell-wrap order-cell-wide">
                {entry.productName}
                <br />
                <br />
                Price: {entry.price}
                <br />
                <br />
                Product ID: {entry.productId}
              </div>
              <div className="order-cell order-cell-wrap order-cell-wide">
                {entry.customerName}
                <br />
                <br />
                CLIENT CODE: {entry.clientCode}
              </div>
              <div className="order-cell order-cell-wrap">
                {entry.testOutfitDetails}
              </div>
              <div className="order-cell order-cell-wrap order-cell-wide">
                {entry.expectedDeliveryDateToClient}
              </div>
              <div className="order-cell order-cell-wrap order-cell-wide">
                {entry.expectedFinalDeliveryDate}
              </div>
              <div className="order-cell order-cell-wrap order-cell-wide">
                {/* Display Customizations Per Product */}
                {entry.productCustomizations.map((item, idx) => (
                  <div key={idx} className="product-customization">
                    <strong>{item.productName}:</strong>{" "}
                    {item.customizations === "None"
                      ? "None"
                      : item.customizations}
                    <br />
                    <br />
                  </div>
                ))}
              </div>
              <div className="order-cell order-cell-wrap order-cell-wide">
                <select
                  className="delivery-status-dropdown"
                  value={entry.status}
                  onChange={(e) => handleStatusChange(index, e.target.value)}
                >
                  {deliveryStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewOrders;
