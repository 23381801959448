// src/components/CategoryManagement.js

import React, { useState, useEffect, useRef } from "react";
import "../style/CategoryManagement.css";
import { db } from "../firebase-config";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
  serverTimestamp, // Import serverTimestamp
} from "firebase/firestore";

const CategoryManagement = () => {
  const today = new Date().toLocaleDateString();
  const [isAdding, setIsAdding] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categories, setCategories] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), // Include all data fields (e.g., createdAt)
        }));
        setCategories(categoriesList);
      } catch (error) {
        console.error("Error fetching categories: ", error);
        setStatusMessage("Failed to fetch categories.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleAddCategoryClick = () => {
    setIsAdding(true);
  };

  const handleDoneClick = async () => {
    if (categoryName.trim()) {
      setIsLoading(true);
      setStatusMessage("");
      try {
        const categoryDocRef = doc(db, "categories", categoryName);
        await setDoc(categoryDocRef, {
          name: categoryName,
          createdAt: serverTimestamp(), // Add the createdAt timestamp
        });

        setCategories([
          ...categories,
          { id: categoryName, createdAt: new Date() },
        ]);
        setIsAdding(false);
        setCategoryName("");

        // Set the success message
        setStatusMessage("Category created successfully.");

        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Set a timeout to clear the message after 3 seconds
        timeoutRef.current = setTimeout(() => {
          setStatusMessage("");
        }, 3000);
      } catch (error) {
        console.error("Error adding category: ", error);
        setStatusMessage("Failed to create category. Please try again.");

        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Set a timeout to clear the error message after 3 seconds
        timeoutRef.current = setTimeout(() => {
          setStatusMessage("");
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    } else {
      setStatusMessage("Category name cannot be empty.");

      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a timeout to clear the error message after 3 seconds
      timeoutRef.current = setTimeout(() => {
        setStatusMessage("");
      }, 3000);
    }
  };

  const handleCancelClick = () => {
    setIsAdding(false);
    setCategoryName("");
    setStatusMessage("");
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      setIsLoading(true);
      try {
        await deleteDoc(doc(db, "categories", categoryId));
        setCategories(
          categories.filter((category) => category.id !== categoryId)
        );
        setStatusMessage("Category deleted successfully.");
      } catch (error) {
        console.error("Error deleting category: ", error);
        setStatusMessage("Failed to delete category. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleViewProducts = (categoryId) => {
    console.log("View products in category:", categoryId);
    // Implement your logic to view products
  };

  return (
    <div className="category-management-container">
      <div className="header">
        <div className="date">{today}</div>
      </div>
      <div className="title-section">
        <div className="category-management-title">Category Management</div>
        {!isAdding && (
          <div className="add-category-container">
            <div className="add-category-box" onClick={handleAddCategoryClick}>
              ADD CATEGORY
            </div>
          </div>
        )}
      </div>
      {isAdding && (
        <div className="add-category-form">
          <div className="add-category-title">Add Category</div>
          <div className="form-group-unique">
            <label>Name:</label>
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Enter category name"
            />
          </div>
          <div className="button-group">
            <button
              className="done-button"
              onClick={handleDoneClick}
              disabled={isLoading}
            >
              {isLoading ? "Creating..." : "Done"}
            </button>
            <button
              className="cancel-button"
              onClick={handleCancelClick}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
          {statusMessage && (
            <div className={`status-message ${isLoading ? "loading" : ""}`}>
              {statusMessage}
            </div>
          )}
        </div>
      )}
      <div className="categories-list">
        {isLoading ? (
          <div>Loading categories...</div>
        ) : (
          categories.map((category) => (
            <div key={category.id} className="category-box">
              <div className="category-header">
                <span className="category-name">{category.id}</span>
                {/* Optional: Display creation date */}
                {category.createdAt && (
                  <span className="category-date">
                    {category.createdAt.toDate
                      ? category.createdAt.toDate().toLocaleDateString()
                      : new Date().toLocaleDateString()}
                  </span>
                )}
              </div>
              <div className="category-actions">
                <button
                  className="action-button view-products-button"
                  onClick={() => handleViewProducts(category.id)}
                >
                  View Products
                </button>
                <button
                  className="action-button delete-category-button"
                  onClick={() => handleDeleteCategory(category.id)}
                >
                  Delete Category
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      {statusMessage && <div className="status-message">{statusMessage}</div>}
    </div>
  );
};

export default CategoryManagement;
