// src/components/Favorites.js

import React, { useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FavoritesContext } from "../context/FavoritesContext";
import { ReactComponent as CloseIcon } from "../icons/cancel.svg";
import { useTranslation } from "react-i18next";
import { CurrencyContext } from "../context/CurrencyContext";

const Favorites = ({ drawerOpen, setDrawerOpen }) => {
  const { t } = useTranslation();
  const { favorites, toggleFavorite } = useContext(FavoritesContext);
  const { currency, exchangeRates } = useContext(CurrencyContext);

  const navigate = useNavigate();
  const drawerRef = useRef(); // This will now reference the scrollable content container

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If click outside the drawer area, close it
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawerOpen(false);
      }
    };

    if (drawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [drawerOpen, setDrawerOpen]);

  // Reset scroll position when drawer opens
  useEffect(() => {
    if (drawerOpen && drawerRef.current) {
      // Scroll to top of the content container
      drawerRef.current.scrollTop = 0;
    }
  }, [drawerOpen]);

  const handleProductClick = (product) => {
    if (!product.category || !product.id) {
      console.error(
        "Missing category or productId for favorite item:",
        product
      );
      return;
    }
    navigate(
      `/full-product?category=${encodeURIComponent(
        product.category
      )}&productId=${encodeURIComponent(product.id)}`
    );
    setDrawerOpen(false);
  };

  const isMobile = window.innerWidth < 768;

  const calculateTotal = () => {
    return favorites.reduce((total, item) => {
      const itemPrice = parseFloat(item.price) || 0;
      return total + itemPrice;
    }, 0);
  };

  const formatPrice = (priceInGBP) => {
    let convertedPrice = priceInGBP;
    let symbol = "£";
    switch (currency) {
      case "EUR":
        convertedPrice = priceInGBP * (exchangeRates["EUR"] || 1);
        symbol = "€";
        break;
      case "USD":
        convertedPrice = priceInGBP * (exchangeRates["USD"] || 1);
        symbol = "$";
        break;
      case "GBP":
      default:
        convertedPrice = priceInGBP;
        symbol = "£";
        break;
    }
    return `${symbol}${convertedPrice.toFixed(2)}`;
  };

  const frequentlyBoughtItems = favorites
    .flatMap((item) => item.selectedForYou || [])
    .reduce((uniqueItems, currentItem) => {
      if (!uniqueItems.some((itm) => itm.name === currentItem.name)) {
        uniqueItems.push(currentItem);
      }
      return uniqueItems;
    }, []);

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: drawerOpen ? "rgba(0, 0, 0, 0.5)" : "transparent",
      transition: "background-color 0.3s ease",
      zIndex: 1000,
      display: drawerOpen ? "block" : "none",
    },
    drawer: {
      position: "fixed",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
      transform: drawerOpen ? "translateX(0)" : "translateX(100%)",
      transition: "transform 0.3s ease",
      zIndex: 1001,
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      overflow: "hidden",
    },
    drawerWide: {
      width: "450px",
    },
    drawerHeader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
      position: "relative",
      paddingTop: isMobile ? "20px" : "0px",
    },
    icon: {
      cursor: "pointer",
      width: isMobile ? "24px" : "16px",
      height: isMobile ? "24px" : "16px",
      position: "absolute",
      left: isMobile ? "5px" : "0px",
    },
    title: {
      fontFamily: "Georgia",
      color: "#707070",
      opacity: 0.9,
      fontSize: isMobile ? "20px" : "14px",
      textAlign: "center",
    },
    hr: {
      width: "100%",
      border: "none",
      borderTop: "2px solid rgba(0, 0, 0, 0.2)",
      margin: "10px 0",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Georgia",
      color: "#707070",
      fontSize: "16px",
      textAlign: "center",
      height: "calc(100% - 60px)",
      justifyContent: favorites.length === 0 ? "center" : "flex-start",
      alignItems: favorites.length === 0 ? "center" : "flex-start",
      paddingLeft: isMobile ? "10px" : "0px",
      paddingRight: isMobile ? "10px" : "0px",
      boxSizing: "border-box",
      overflowY: "auto", // This is the scrollable element
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      overflowX: "hidden",
    },
    favoriteItem: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "10px",
      textAlign: "left",
      width: "100%",
      cursor: "pointer",
    },
    favoriteItemImageContainer: {
      flex: "0 0 auto",
      marginRight: "10px",
    },
    favoriteItemImage: {
      width: "80px",
      height: "100px",
      objectFit: "cover",
    },
    favoriteItemDetailsContainer: {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    favoriteItemName: {
      fontFamily: "Georgia",
      fontSize: "14px",
      color: "#000",
      marginBottom: "5px",
    },
    favoriteItemPrice: {
      fontFamily: "Georgia",
      fontSize: "14px",
      color: "#000",
    },
    removeButtonContainer: {
      flex: "0 0 auto",
      textAlign: "right",
      alignSelf: "flex-start",
    },
    removeButton: {
      fontFamily: "Georgia",
      fontSize: "14px",
      color: "#000",
      textDecoration: "underline",
      cursor: "pointer",
    },
    orderButton: {
      marginTop: "20px",
      padding: "15px 20px",
      border: "none",
      backgroundColor: "#25D366", // WhatsApp green
      color: "#FFF",
      fontSize: "14px",
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
      boxSizing: "border-box",
    },
    totalContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingLeft: isMobile ? "10px" : "0px",
      paddingRight: isMobile ? "10px" : "0px",
      boxSizing: "border-box",
      fontFamily: "Georgia",
      fontSize: "16px",
      color: "#000",
      marginTop: "10px",
      marginBottom: "10px",
    },
    frequentlyBoughtContainer: {
      width: "100%",
      boxSizing: "border-box",
      marginTop: "20px",
      marginBottom: "20px",
    },
    frequentlyBoughtTitle: {
      fontFamily: "Georgia",
      fontSize: "14px",
      color: "#000",
      textAlign: "center",
      marginTop: "30px",
      marginBottom: "20px",
    },
    frequentlyBoughtGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "10px",
    },
    frequentlyBoughtItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      boxSizing: "border-box",
      cursor: "pointer",
    },
    frequentlyBoughtImage: {
      width: "100%",
      height: "170px",
      objectFit: "cover",
      marginBottom: "5px",
    },
    frequentlyBoughtName: {
      fontFamily: "Georgia",
      fontSize: "12px",
      color: "#000",
      textAlign: "left",
      marginBottom: "10px",
    },
    emptyCartText: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      padding: "20px",
    },
  };

  const globalStyles = `
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  if (typeof document !== "undefined") {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = globalStyles;
    document.head.appendChild(styleSheet);
  }

  const handleOrderOnWhatsAppClick = () => {
    const productLines = favorites
      .map((item, index) => `${index + 1}. Product ID: ${item.productId}`)
      .join("\n");

    const message = `Hi, I would like to order the following items:\n${productLines}`;
    const encodedMessage = encodeURIComponent(message);

    window.open(`https://wa.me/33620181435?text=${encodedMessage}`, "_blank");
  };

  return (
    <>
      {drawerOpen && (
        <div style={styles.overlay} onClick={() => setDrawerOpen(false)}></div>
      )}
      <div
        style={{
          ...styles.drawer,
          ...(window.innerWidth >= 768 ? styles.drawerWide : {}),
        }}
      >
        <div style={styles.drawerHeader}>
          <CloseIcon style={styles.icon} onClick={() => setDrawerOpen(false)} />
          <div style={styles.title}>{t("Favorites")}</div>
        </div>
        <div
          style={{
            paddingLeft: isMobile ? "10px" : "0px",
            paddingRight: isMobile ? "10px" : "0px",
          }}
        >
          <hr style={styles.hr} />
        </div>

        {/* Attach ref here to the content div */}
        <div style={styles.content} ref={drawerRef}>
          {favorites.length === 0 ? (
            <div style={styles.emptyCartText}>
              <div>{t("You have no favorite items.")}</div>
            </div>
          ) : (
            <>
              {favorites.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    style={styles.favoriteItem}
                    onClick={() => handleProductClick(item)}
                  >
                    <div style={styles.favoriteItemImageContainer}>
                      <img
                        src={item.image}
                        alt={item.name}
                        style={styles.favoriteItemImage}
                      />
                    </div>
                    <div style={styles.favoriteItemDetailsContainer}>
                      <div style={styles.favoriteItemName}>{item.name}</div>
                      <div style={styles.favoriteItemPrice}>
                        {formatPrice(item.price)}
                      </div>
                    </div>
                    <div style={styles.removeButtonContainer}>
                      <div
                        style={styles.removeButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(item);
                        }}
                      >
                        {t("Remove")}
                      </div>
                    </div>
                  </div>
                  <hr style={styles.hr} />
                </React.Fragment>
              ))}

              <div style={styles.totalContainer}>
                <div>{t("Total")}</div>
                <div>{formatPrice(calculateTotal())}</div>
              </div>

              <div
                style={styles.orderButton}
                onClick={handleOrderOnWhatsAppClick}
              >
                {t("Order on WhatsApp")}
              </div>

              <div style={styles.frequentlyBoughtContainer}>
                <div style={styles.frequentlyBoughtTitle}>
                  {t("Frequently bought together")}
                </div>
                {frequentlyBoughtItems.length === 0 ? (
                  <div>
                    {t("No items found for Frequently Bought Together.")}
                  </div>
                ) : (
                  <div style={styles.frequentlyBoughtGrid}>
                    {frequentlyBoughtItems.map((item, index) => (
                      <div
                        key={index}
                        style={styles.frequentlyBoughtItem}
                        onClick={() => handleProductClick(item)}
                      >
                        <img
                          src={item.image}
                          alt={item.name}
                          style={styles.frequentlyBoughtImage}
                        />
                        <div style={styles.frequentlyBoughtName}>
                          {item.name} - {formatPrice(item.price)}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Favorites;
