import React from "react";
import { ReactComponent as InstagramIcon } from "../icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "../icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../icons/twitter.svg";
import { ReactComponent as TikTokIcon } from "../icons/tiktok.svg";
import { useTranslation } from "react-i18next";

const SocialLinks = () => {
  const { t } = useTranslation();

  return (
    <div className="social-links-container">
      <hr className="styled-hr-home" />
      <div className="social-links">
        <InstagramIcon className="social-icon" />
        <FacebookIcon className="social-icon" />
        <TwitterIcon className="social-icon" />
        <TikTokIcon className="social-icon" />
      </div>
      <hr className="styled-hr-home-bottom" />
      <div className="footer-details">
        <p>{t("Online Bespoke Fitting")}</p>
        <p>{t("Home Delivery")}</p>
        <p>{t("Free Test Outfit")}</p>
        <p>{t("24/7 Customer Service")}</p>
      </div>
    </div>
  );
};

export default SocialLinks;
