import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";
import "../style/Item.css";
import Pagination from "./Pagination";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import clockIcon from "../icons/clock.svg";
import Collapsible from "./Collapsible";
import { ReactComponent as DarkStarIcon } from "../icons/dark_star.svg";
import { ReactComponent as BlueStarIcon } from "../icons/blue_star.svg";
import styles from "../style/Pagination.module.css";
// import { useCart } from "../context/CartContext";
import { useTranslation } from "react-i18next";
import { collection, getDocs, query, where } from "firebase/firestore";
import filledStar from "../icons/filledstar.svg";
import unfilledStar from "../icons/unfilledstar.svg";
import { CurrencyContext } from "../context/CurrencyContext"; // Import CurrencyContext
import { FavoritesContext } from "../context/FavoritesContext"; // Import FavoritesContext

const FullProduct = () => {
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const category = searchParams.get("category");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const { favorites, toggleFavorite } = useContext(FavoritesContext);

  const [isContainerFocused] = useState(false);
  const swipeTimeoutRef = useRef(null);
  const [isStarClicked, setIsStarClicked] = useState(false);
  const [clientDeliveryDate, setClientDeliveryDate] = useState("");
  const [selectedClient, setSelectedClient] = useState("new");
  const imageContainerRef = useRef(null);
  const [defaultColorName, setDefaultColorName] = useState(null);
  const [defaultCustomizations, setDefaultCustomizations] = useState({});
  const [customerName, setCustomerName] = useState("");
  const [isClientCodeLoading, setIsClientCodeLoading] = useState(false);
  const { currency, exchangeRates } = useContext(CurrencyContext); // Access CurrencyContext
  const productIdentifier = product ? product.productId || product.id : null;

  // FullProduct.js

  const [clientCode, setClientCode] = useState(() => {
    const savedCode = sessionStorage.getItem("clientCode");
    return savedCode ? JSON.parse(savedCode) : Array(6).fill("");
  });
  const inputRefs = useRef([]);
  const [sizeData, setSizeData] = useState({});
  // const { addToCart } = useCart();
  const [isLoaded, setIsLoaded] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedForYouProducts, setSelectedForYouProducts] = useState([]);
  const [filled, setFilled] = useState([]); // For star icons
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedCustomizations, setSelectedCustomizations] = useState({});
  const containerRefs = useRef([]);
  const [testOutfitDateObject, setTestOutfitDateObject] = useState(null);
  const [deliveryDateObject, setDeliveryDateObject] = useState(null);
  const [selectedColorPrice, setSelectedColorPrice] = useState(0);
  const [defaultColor, setDefaultColor] = useState(null);
  const [isClientCodeValid, setIsClientCodeValid] = useState(null); // **New State Variable**
  const [customProductId, setCustomProductId] = useState("");

  // Calculate total price using useMemo for optimization

  const {
    colors = [],
    sections = [],
    productionDays = 0,
    testOutfitDays = 0,
    displayTestOutfit = false, // Add this line
    availableMeasurements = {},
    designersNotes = "",
    remoteBespoke = "",
    deliveryOptions = "",
    familyName = "",
    categories = [],
  } = product || {};
  const totalPrice = useMemo(() => {
    // Base price of the product
    const basePrice = parseFloat(product?.price.replace(/[£,]/g, "")) || 0;

    // Additional price from selected color
    const colorPrice = parseFloat(selectedColorPrice) || 0;

    // Additional price from selected customizations (sections)
    const customizationPrice = Object.keys(selectedCustomizations).reduce(
      (acc, sectionName) => {
        const section = sections.find((sec) => sec.sectionName === sectionName);
        if (section) {
          const subsection = section.subsections.find(
            (sub) => sub.name === selectedCustomizations[sectionName]
          );
          if (subsection && subsection.price) {
            return acc + parseFloat(subsection.price);
          }
        }
        return acc;
      },
      0
    );

    return basePrice + colorPrice + customizationPrice;
  }, [product?.price, selectedColorPrice, selectedCustomizations, sections]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      if (productId && category) {
        try {
          setIsLoaded(false); // Reset loading state
          setProduct(null); // Clear previous product
          const productRef = doc(db, "products", category, "items", productId);
          const productDoc = await getDoc(productRef);
          if (productDoc.exists()) {
            const productData = productDoc.data();
            setProduct(productDoc.data());
            setCustomProductId(productData.productId || "");

            setIsLoaded(true);
          } else {
            console.error("Product not found!");
            setIsLoaded(true); // Even if not found, end loading state
          }
        } catch (error) {
          console.error("Error fetching product:", error);
          setIsLoaded(true); // End loading state on error
        }
      } else {
        console.error("Product ID or category is missing");
      }
    };

    fetchProduct();
  }, [productId, category]);

  // Extract product properties

  const calculateTestOutfitDay = (daysToAdd) => {
    let currentDate = new Date();
    let daysAdded = 0;

    while (daysAdded < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getUTCDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++;
      }
    }

    const options = { weekday: "long" };
    let dayString = currentDate.toLocaleDateString(i18n.language, options);

    // Capitalize the first letter
    dayString = dayString.charAt(0).toUpperCase() + dayString.slice(1);

    return dayString;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const RELATED_PRODUCTS_LIMIT = isMobile ? 2 : 3;

  const handleCustomizationChange = (sectionName, subsectionValue) => {
    setSelectedCustomizations((prevState) => ({
      ...prevState,
      [sectionName]: subsectionValue,
    }));
  };

  const calculateDeliveryDate = (daysToAdd) => {
    let currentDate = new Date();
    let daysAdded = 0;

    while (daysAdded < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getUTCDay(); // 0 = Sunday, 6 = Saturday
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++;
      }
    }
    // Return the Date object instead of a formatted string
    return currentDate;
  };

  const isProductCustomized = useMemo(() => {
    // Compare selected color with default color
    const colorCustomized = selectedColor !== defaultColorName;

    // Compare selected customizations with default customizations
    let customizationsCustomized = false;

    if (sections && sections.length > 0) {
      for (let sectionName in selectedCustomizations) {
        const selectedOption = selectedCustomizations[sectionName];
        const defaultOption = defaultCustomizations[sectionName];

        if (selectedOption !== defaultOption) {
          customizationsCustomized = true;
          break;
        }
      }
    }

    return colorCustomized || customizationsCustomized;
  }, [
    selectedColor,
    defaultColorName,
    selectedCustomizations,
    defaultCustomizations,
    sections,
  ]);

  useEffect(() => {
    if (colors && colors.length > 0) {
      const firstColor = colors[0];
      setDefaultColor(firstColor);
      setSelectedColor(firstColor.name);
      const parsedPrice = parseFloat(firstColor.price);
      setSelectedColorPrice(!isNaN(parsedPrice) ? parsedPrice : 0);

      // Store the default color name
      setDefaultColorName(firstColor.name);
    } else {
      setDefaultColorName(null);
    }
  }, [colors]);

  useEffect(() => {
    if (sections && sections.length > 0) {
      const initialCustomizations = {};
      sections.forEach((section) => {
        if (section.subsections && section.subsections.length > 0) {
          initialCustomizations[section.sectionName] =
            section.subsections[0].name;
        }
      });

      // Set defaultCustomizations unconditionally
      setDefaultCustomizations(initialCustomizations);

      // Only set selectedCustomizations if it's empty
      if (Object.keys(selectedCustomizations).length === 0) {
        setSelectedCustomizations(initialCustomizations);
      }
    } else {
      setDefaultCustomizations({});
    }
  }, [sections]);

  // Calculate test outfit day
  const testOutfitDay = displayTestOutfit
    ? calculateTestOutfitDay(testOutfitDays)
    : null;

  useEffect(() => {
    if (selectedClient === "new") {
      let totalDays;
      if (displayTestOutfit) {
        totalDays = testOutfitDays + productionDays;
      } else {
        totalDays = productionDays;
      }
      // console.log("Production Days:", productionDays);
      // console.log("Test Outfit Days:", testOutfitDays);
      // console.log("Total Days to Add for Delivery:", totalDays);

      setClientDeliveryDate(calculateDeliveryDate(totalDays));
    } else {
      setClientDeliveryDate(calculateDeliveryDate(productionDays));
    }
  }, [selectedClient, productionDays, testOutfitDays, displayTestOutfit]);

  const addOrdinalSuffix = (day) => {
    if (typeof day !== "number") {
      day = parseInt(day, 10);
      if (isNaN(day)) return day; // Return original value if not a number
    }

    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };
  const formatClientDeliveryDate = (date) => {
    if (!date) return "";

    // Ensure 'date' is a Date object
    if (!(date instanceof Date) || isNaN(date)) return "";

    const options = { day: "numeric", month: "long" };

    if (i18n.language === "en") {
      const day = date.getDate();
      const month = date.toLocaleString("en-GB", { month: "long" });
      const dayWithSuffix = addOrdinalSuffix(day);
      return `${dayWithSuffix} of ${month}`;
    } else {
      // For French and other locales
      let dateString = date.toLocaleDateString(i18n.language, options);
      // Capitalize the first letter
      dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
      return dateString;
    }
  };

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      if (product && familyName) {
        try {
          const categoriesSnapshot = await getDocs(collection(db, "products"));
          const fetchedProducts = [];
          const fetchedProductNames = new Set();

          // Get the current product's name
          const currentProductName = product.name || "Unknown Product";

          // Loop through each category
          for (const categoryDoc of categoriesSnapshot.docs) {
            const categoryName = categoryDoc.id;
            const itemsCollection = collection(
              db,
              "products",
              categoryName,
              "items"
            );
            const q = query(
              itemsCollection,
              where("familyName", "==", familyName)
            );
            const querySnapshot = await getDocs(q);

            for (const docSnap of querySnapshot.docs) {
              const data = docSnap.data();
              const fetchedProductName = data.name || "Unknown Product";

              // Exclude the current product and any products with the same name
              if (fetchedProductName === currentProductName) continue;

              // Skip if we've already added a product with the same name
              if (fetchedProductNames.has(fetchedProductName)) continue;

              // Add the product to the list, using doc.id as productId
              fetchedProducts.push({
                id: docSnap.id, // Firestore doc ID for navigation
                name: fetchedProductName,
                price: data.price || "0",
                image: data.displayImages?.[0] || "",
                newIn: data.newIn || false,
                productId: data.productId || null, // Store custom productId if available
                category: categoryName,
              });

              fetchedProductNames.add(fetchedProductName);

              // Limit to 3 products
              if (fetchedProducts.length === RELATED_PRODUCTS_LIMIT) break;
            }

            if (fetchedProducts.length === RELATED_PRODUCTS_LIMIT) break;
          }

          setRelatedProducts(fetchedProducts);
          setFilled(Array(fetchedProducts.length).fill(false)); // Initialize star icons
        } catch (error) {
          console.error("Error fetching related products:", error);
        }
      }
    };

    fetchRelatedProducts();
  }, [product, familyName, RELATED_PRODUCTS_LIMIT]);

  useEffect(() => {
    const fetchSelectedForYouProducts = async () => {
      if (product && product.categories.length > 0) {
        try {
          const fetchedProducts = [];
          const fetchedProductNames = new Set(); // To track unique product names

          // Extract category names from the product's categories array
          const categoryNames = product.categories.map((cat) => cat.category);

          // Extract the current product's name
          const currentProductName = product.name || "Unknown Product";

          for (const category of categoryNames) {
            const categoryRef = collection(db, "products", category, "items");
            const q = query(categoryRef);
            const querySnapshot = await getDocs(q);

            for (const docSnap of querySnapshot.docs) {
              const data = docSnap.data();
              const fetchedProductName = data.name || "Unknown Product";

              // Exclude the current product by name
              if (fetchedProductName === currentProductName) continue;

              // Skip if we've already added a product with the same name
              if (fetchedProductNames.has(fetchedProductName)) continue;

              fetchedProducts.push({
                id: docSnap.id,
                name: fetchedProductName,
                price: data.price || "0",
                image: data.displayImages?.[0] || "",
                productId: docSnap.id, // Use doc.id here
                category: category, // Include category
              });

              fetchedProductNames.add(fetchedProductName); // Mark the product name as added

              // Limit to 8 products
              if (fetchedProducts.length === 48) break;
            }

            // Break out of the outer loop if we've collected enough products
            if (fetchedProducts.length === 48) break;
          }

          setSelectedForYouProducts(fetchedProducts);
        } catch (error) {
          console.error("Error fetching selected products:", error);
        }
      } else {
      }
    };

    fetchSelectedForYouProducts();
  }, [product, productId]);

  const handleProductClick = (selectedProduct) => {
    window.scrollTo(0, 0); // Scroll to top
    window.location.href = `/full-product?category=${encodeURIComponent(
      selectedProduct.category
    )}&productId=${encodeURIComponent(selectedProduct.id)}`; // Use doc ID here
  };

  // Containers for images
  const containers = useMemo(() => {
    return product?.displayImages?.length
      ? product.displayImages
      : [product?.imageUrl];
  }, [product]);

  const formatText = (text) => {
    // Split by language indicators and newline characters
    const sections = text.split(/\n(?=EN|FR)/); // Split at "EN" or "FR" on a new line

    return sections.map((section, index) => {
      // Further split each section by lines
      const lines = section.split("\n").filter(Boolean); // Filter out empty lines
      return (
        <React.Fragment key={index}>
          {lines.map((line, lineIndex) => (
            <p key={lineIndex} style={{ margin: "0.5em 0" }}>
              {line}
            </p>
          ))}
          {index < sections.length - 1 && (
            <hr style={{ border: "none", height: "10px" }} />
          )}{" "}
          {/* Optional spacer */}
        </React.Fragment>
      );
    });
  };

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % containers.length);
  }, [containers.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + containers.length) % containers.length
    );
  }, [containers.length]);

  const handlePaginationClick = (index) => {
    setCurrentIndex(index);
    if (containerRefs.current[index]) {
      containerRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = useCallback(
    (e) => {
      if (isContainerFocused) {
        if (swipeTimeoutRef.current) {
          clearTimeout(swipeTimeoutRef.current);
        }

        swipeTimeoutRef.current = setTimeout(() => {
          const delta = e.deltaY || e.detail || e.wheelDelta;
          if (delta > 0) {
            nextSlide();
          } else {
            prevSlide();
          }
        }, 50);

        e.preventDefault();
      }
    },
    [isContainerFocused, nextSlide, prevSlide]
  );

  useEffect(() => {
    if (isContainerFocused) {
      window.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      window.removeEventListener("wheel", handleScroll);
    }

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [isContainerFocused, handleScroll]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (displayTestOutfit) {
      const dateObj = calculateDateObject(testOutfitDays);
      setTestOutfitDateObject(dateObj);
    } else {
      setTestOutfitDateObject(null);
    }
  }, [testOutfitDays, displayTestOutfit]);

  // Calculate and store the delivery date object
  useEffect(() => {
    let totalDays;
    if (selectedClient === "new") {
      totalDays = displayTestOutfit
        ? testOutfitDays + productionDays
        : productionDays;
    } else {
      totalDays = productionDays;
    }
    setClientDeliveryDate(calculateDeliveryDate(totalDays));
  }, [selectedClient, productionDays, testOutfitDays, displayTestOutfit]);

  useEffect(() => {
    if (imageContainerRef.current) {
      const options = {
        root: imageContainerRef.current, // Set the root to the image container
        rootMargin: "0px",
        threshold: 0.5, // Trigger when 50% of the image is visible
      };
      //time to check for the remaining part of the show
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute("data-index"), 10);
            setCurrentIndex(index);
          }
        });
      }, options);

      containerRefs.current.forEach((ref) => {
        if (ref) observer.observe(ref);
      });

      return () => {
        containerRefs.current.forEach((ref) => {
          if (ref) observer.unobserve(ref);
        });
      };
    }
  }, [imageContainerRef, containers.length]);

  // FullProduct.js

  const calculateDateObject = (daysToAdd) => {
    let currentDate = new Date();
    let daysAdded = 0;

    while (daysAdded < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getUTCDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++;
      }
    }
    return currentDate;
  };

  const handleInputChange = (index, value, event) => {
    const newCode = [...clientCode];

    if (event.key === "Backspace") {
      if (newCode[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
      } else {
        newCode[index] = "";
      }
    } else if (/^[0-9]$/.test(value) || value === "") {
      // Restrict to digits only
      newCode[index] = value;
      if (value !== "" && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }

    setClientCode(newCode);
    sessionStorage.setItem("clientCode", JSON.stringify(newCode)); // Persist to sessionStorage
    setIsClientCodeValid(null); // Reset Validity on Input Change
    setCustomerName(""); // Reset customer name on Input Change
    setIsClientCodeLoading(false); // Reset loading state on Input Change
  };

  const handleProceedClick = async () => {
    if (
      (selectedClient === "already" && isClientCodeComplete) ||
      (selectedClient === "new" && isSizeDataComplete)
    ) {
      if (product) {
        try {
          const priceNumber = parseFloat(product.price.replace(/[£,]/g, ""));

          // Fetch the custom productId from Firestore using productId (doc.id)
          const productRef = doc(db, "products", category, "items", productId);
          const productDoc = await getDoc(productRef);

          if (!productDoc.exists()) {
            console.error("Product not found during proceed click!");
            return;
          }

          const productData = productDoc.data();
          const customProductId = productData.productId || ""; // Retrieve custom productId

          let customerPhone = null; // Initialize customerPhone
          if (selectedClient === "already" && isClientCodeValid) {
            const enteredClientCode = clientCode.join(""); // Convert the array to a string

            // Query Firestore to get the user with the entered clientCode
            const usersCollectionRef = collection(db, "users");
            const q = query(
              usersCollectionRef,
              where("clientCode", "==", enteredClientCode)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
              const userDoc = querySnapshot.docs[0];
              const userData = userDoc.data();
              customerPhone = userData.customerPhone || null; // Retrieve customerPhone
            } else {
              console.error("No user found with the provided client code.");
              // Optionally, handle this case by setting isClientCodeValid to false
              // setIsClientCodeValid(false);
              // setCustomerName("");
              // return;
            }
          }

          const selectedItem = {
            image: containers[currentIndex],
            name: product.name,
            price: totalPrice, // Use totalPrice instead of basePrice
            id: productId, // Firestore doc.id
            category: categories[0],
            color: selectedColor || "No Color Selected", // Include selected color
            sizes: sizeData, // Include selected sizes
            customizations: selectedCustomizations, // Include selected customizations
            deliveryDate: clientDeliveryDate
              ? clientDeliveryDate.toISOString()
              : null, // Use clientDeliveryDate
            testOutfitDate:
              selectedClient === "new" && displayTestOutfit
                ? clientDeliveryDate.toISOString() // Use clientDeliveryDate if appropriate
                : null,
            selectedClient: selectedClient, // Indicate client type
            selectedForYou: selectedForYouProducts, // Include selectedForYouProducts
            selectedProductId: customProductId, // Assign custom productId
            isCustomized: isProductCustomized, // Include the customization flag

            clientCode:
              selectedClient === "already" && isClientCodeValid
                ? clientCode.join("") // Convert the array to a string
                : null,
            customerPhone:
              selectedClient === "already" && isClientCodeValid
                ? customerPhone
                : null,
          };

          // console.log("Client Delivery Date:", clientDeliveryDate);

          // addToCart(selectedItem);
        } catch (error) {
          console.error("Error during proceed click:", error);
        }
      }
    }
  };

  const handleInputClick = (index) => {
    if (clientCode.every((char) => char === "")) {
      inputRefs.current[0]?.focus();
    } else {
      inputRefs.current[index]?.focus();
    }
  };

  const handleCodeBoxClick = () => {
    if (clientCode.every((char) => char === "")) {
      inputRefs.current[0]?.focus();
    }
  };

  const handleStarClick = (index, e) => {
    e.stopPropagation();
    const newFilled = [...filled];
    newFilled[index] = !newFilled[index];
    setFilled(newFilled);
  };
  const handleMainStarClick = (e) => {
    e.stopPropagation();
    toggleFavorite({
      id: productId,
      name: product.name,
      image: product.displayImages[0],
      category: category,
      price: totalPrice,
      productId: customProductId, // Use the state variable here
      selectedForYou: selectedForYouProducts,
    });
  };

  const isProductFavorited = useMemo(() => {
    return favorites.some((fav) => fav.id === productId);
  }, [favorites, productId]);
  const handleClearSelections = () => {
    if (defaultColor) {
      setSelectedColor(defaultColor.name);
      const parsedPrice = parseFloat(defaultColor.price);
      setSelectedColorPrice(!isNaN(parsedPrice) ? parsedPrice : 0);
    } else {
      setSelectedColor(null);
      setSelectedColorPrice(0);
    }

    if (sections && sections.length > 0) {
      setSelectedCustomizations(defaultCustomizations);
    } else {
      setSelectedCustomizations({});
    }
  };

  const handleClientSelection = (clientType) => {
    setSelectedClient(clientType);
  };

  const handleSizeInputChange = (e) => {
    const { name, value } = e.target;
    setSizeData((prevState) => ({ ...prevState, [name]: value }));
  };

  const isClientCodeComplete = useMemo(
    () => clientCode.every((char) => char !== ""),
    [clientCode]
  );

  const getLocalizedColorName = (color) => {
    if (i18n.language === "fr" && color.frenchName) {
      return color.frenchName;
    }
    return color.name;
  };

  useEffect(() => {
    const validateClientCode = async () => {
      if (isClientCodeComplete) {
        const enteredCode = clientCode.join("");
        setIsClientCodeLoading(true); // Start loading
        try {
          const q = query(
            collection(db, "users"),
            where("clientCode", "==", enteredCode)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const data = userDoc.data();
            const fullName = data.customerName || "Customer";
            const firstName = fullName.split(" ")[0]; // Extract first name
            setCustomerName(firstName);
            setIsClientCodeValid(true);
          } else {
            setIsClientCodeValid(false);
            setCustomerName(""); // Reset if code is invalid
          }
        } catch (error) {
          console.error("Error validating client code:", error);
          setIsClientCodeValid(false);
          setCustomerName(""); // Reset on error
        }
        setIsClientCodeLoading(false); // End loading
      } else {
        setIsClientCodeValid(null);
        setCustomerName(""); // Reset if code is incomplete
      }
    };

    validateClientCode();
  }, [isClientCodeComplete, clientCode]);

  // Adjusted isSizeDataComplete logic
  const isSizeDataComplete = useMemo(() => {
    const availableKeys = Object.keys(availableMeasurements || {}).filter(
      (key) => availableMeasurements[key]?.length > 0
    );

    if (availableKeys.length === 0) {
      // No measurements available, consider size data as complete
      return true;
    }

    // Check that all available measurements have been selected
    return availableKeys.every((key) => {
      const value = sizeData[key];
      return typeof value === "string" && value.trim() !== "";
    });
  }, [sizeData, availableMeasurements]);

  if (!isLoaded || !product) {
    return <div>{t("")}</div>; // Loading state
  }

  const getLocalizedSectionName = (section) => {
    if (i18n.language === "fr" && section.sectionNameFrench) {
      return section.sectionNameFrench;
    }
    return section.sectionName;
  };

  const getLocalizedSubsectionName = (subsection) => {
    if (i18n.language === "fr" && subsection.nameFrench) {
      return subsection.nameFrench;
    }
    return subsection.name;
  };

  // Helper function to format price based on selected currency
  const formatPrice = (priceInGBP) => {
    let convertedPrice = priceInGBP;
    let symbol = "£"; // Default symbol

    switch (currency) {
      case "EUR":
        convertedPrice = priceInGBP * (exchangeRates["EUR"] || 1);
        symbol = "€";
        break;
      case "USD":
        convertedPrice = priceInGBP * (exchangeRates["USD"] || 1);
        symbol = "$";
        break;
      case "GBP":
      default:
        convertedPrice = priceInGBP;
        symbol = "£";
        break;
    }

    return `${symbol}${convertedPrice.toFixed(2)}`;
  };

  return (
    <div className="item-container">
      <div className="top-section">
        <div className="left-column">
          <div className="left-column-15">
            {containers.map((image, index) => (
              <div
                key={index}
                className={`slider-indicator ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => handlePaginationClick(index)}
              >
                <div className="thumbnail-wrapper">
                  <img
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    className="thumbnail-image"
                  />
                </div>
              </div>
            ))}
            <div className="vertical-ruler">
              {containers.map((_, index) => (
                <div
                  key={index}
                  className={`vertical-ruler-section ${
                    index === currentIndex ? "highlighted" : ""
                  }`}
                  style={{
                    top: `${(index / containers.length) * 100}%`,
                    height: `${100 / containers.length}%`,
                  }}
                ></div>
              ))}
            </div>
          </div>

          <div className="left-column-35">
            <div className="item-slider" tabIndex={0} ref={imageContainerRef}>
              <div className="item-product-wrapper">
                {containers.map((image, index) => (
                  <div
                    key={index}
                    className="item-product-container"
                    ref={(el) => (containerRefs.current[index] = el)}
                    data-index={index}
                  >
                    <img
                      className="container-image"
                      src={image}
                      alt={product.name}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Pagination
              currentIndex={currentIndex}
              total={containers.length}
              onClick={handlePaginationClick}
              className={styles.hideOnDesktop}
            />
          </div>
        </div>
        <div className="right-column">
          <div className="text-details-wrapper">
            <div className="text-details">
              <div className="text-item-name">{product.name}</div>
              <div className="text-new-in-small">{t("")}</div>
              <div className="text-new-in-large">
                {formatPrice(totalPrice)}
              </div>{" "}
            </div>
          </div>
          <div className="order-info">
            <img src={clockIcon} alt="Clock Icon" className="clock-icon" />
            <span className="order-info-text">
              {t("Delivery date : ")}{" "}
              <span className="order-time">
                {formatClientDeliveryDate(clientDeliveryDate)}
              </span>{" "}
              {t(" at your residence")}
            </span>
          </div>

          <hr className="styled-hr-top" />
          {/* <Collapsible title={t("Customise your outfit")}>
            <div className="customize-options">
              <div className="customize-category">
                <h4>{t("Color")}</h4>
                {colors.length > 0 ? (
                  colors.map((color, index) => (
                    <div className="customize-option" key={index}>
                      <span>{getLocalizedColorName(color)}</span>
                      <div
                        className="customize-box"
                        style={{ backgroundColor: color.hex }}
                      ></div>

                      <input
                        type="radio"
                        name="color"
                        value={color.name}
                        checked={selectedColor === color.name}
                        onChange={() => {
                          setSelectedColor(color.name);
                          const parsedPrice = parseFloat(color.price);
                          setSelectedColorPrice(
                            !isNaN(parsedPrice) ? parsedPrice : 0
                          );
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <p>{t("No colors available")}</p>
                )}
              </div>

              {sections && sections.length > 0 ? (
                sections.map((section, sectionIndex) => (
                  <div className="customize-category" key={sectionIndex}>
                    <h4>{getLocalizedSectionName(section)}</h4>
                    {section.subsections && section.subsections.length > 0 ? (
                      section.subsections.map((subsection, subIndex) => (
                        <div className="customize-option" key={subIndex}>
                          <span>{getLocalizedSubsectionName(subsection)}</span>

                          <input
                            type="radio"
                            name={section.sectionName}
                            value={subsection.name}
                            checked={
                              selectedCustomizations[section.sectionName] ===
                              subsection.name
                            }
                            onChange={() =>
                              handleCustomizationChange(
                                section.sectionName,
                                subsection.name
                              )
                            }
                          />
                        </div>
                      ))
                    ) : (
                      <p>{t("No options available for this customization.")}</p>
                    )}
                  </div>
                ))
              ) : (
                <p>{t("No customization options available.")}</p>
              )}

              {(colors.length > 0 || sections.length > 0) && (
                <div className="clear-selections-container">
                  <button
                    className="clear-selections-button"
                    onClick={handleClearSelections}
                  >
                    {t("Reset Selections")}
                  </button>
                </div>
              )}
            </div>
          </Collapsible> */}

          <Collapsible title={t("Why we use Whatsapp")}>
            <div className="customize-options">
              <div className="customize-category">
                <ul className="customize-option-list">
                  <li>
                    <div className="customize-option">
                      {t(
                        "It is more efficient should you want to customise the design."
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="customize-option">
                      {t(
                        "You'll be able to follow up on the production progress of your order"
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="customize-option">
                      {t(
                        "If ever you have an issue, you'll know exactly who to contact."
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              {/* Concluding Paragraph */}
              <p className="customize-no-options">
                {t(
                  "Simply put, it's more comfortable to interact directly with another person."
                )}
              </p>
            </div>
          </Collapsible>

          <hr className="styled-hr-bottom" />
          {/* <div className="client-selection">
            <div
              className={`client-box ${
                selectedClient === "new" ? "selected" : ""
              }`}
              onClick={() => handleClientSelection("new")}
            >
              {t("NEW CLIENT")}
            </div>
            <div
              className={`client-box ${
                selectedClient === "already" ? "selected" : ""
              }`}
              onClick={() => handleClientSelection("already")}
            >
              {t("ALREADY CLIENT")}
            </div>
          </div> */}

          {/* <div className="client-info">
            {selectedClient === "already" ? (
              <>
                <p className="client-code-text">
                  {t("Enter Your Client Code To Order")}
                </p>
                <div className="client-code-box" onClick={handleCodeBoxClick}>
                  {clientCode.map((code, index) => (
                    <input
                      key={index}
                      className="client-code-input"
                      type="text"
                      maxLength="1"
                      value={code}
                      placeholder="*"
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) =>
                        handleInputChange(index, e.target.value, e)
                      }
                      onKeyDown={(e) =>
                        handleInputChange(index, e.target.value, e)
                      }
                      onClick={() => handleInputClick(index)}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        if (index === 5 && /^[0-9]$/.test(e.target.value)) {
                          e.target.blur();
                        }
                      }}
                    />
                  ))}
                </div>
                {isClientCodeComplete &&
                  (isClientCodeLoading ? (
                    <div className="spinner-container2">
                      <div className="spinner2" aria-label="Loading"></div>
                    </div>
                  ) : isClientCodeValid ? (
                    <div className="client-welcome-text">
                      {t("Hello Mr.")} {customerName},
                      <p>
                        {t(
                          "Welcome back Sir. Please proceed with your order, we already have your measurements."
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="client-error-text">
                      {t("Unidentified Code.")}
                      <p>
                        {t(
                          "If you're already a client please verify your code otherwise, click on NEW CLIENT to order."
                        )}
                      </p>
                    </div>
                  ))}
                
              </>
            ) : (
              <div className="new-client-form">
                <div className="new-client-form">
                  {availableMeasurements.top?.length > 0 && (
                    <div className="form-group">
                      <label>{t("Top")}</label>
                      <select
                        className="form-input"
                        name="top"
                        value={sizeData.top || ""}
                        onChange={handleSizeInputChange}
                      >
                        <option value="">{t("Select Size")}</option>
                        {availableMeasurements.top.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {availableMeasurements.trouserWaist?.length > 0 && (
                    <div className="form-group">
                      <label>{t("Trouser waist")}</label>
                      <select
                        className="form-input"
                        name="trouserWaist"
                        value={sizeData.trouserWaist || ""}
                        onChange={handleSizeInputChange}
                      >
                        <option value="">{t("Select Waist Size")}</option>
                        {availableMeasurements.trouserWaist.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {availableMeasurements.height?.length > 0 && (
                    <div className="form-group">
                      <label>{t("Height")}</label>
                      <select
                        className="form-input"
                        name="height"
                        value={sizeData.height || ""}
                        onChange={handleSizeInputChange}
                      >
                        <option value="">{t("Select Height")}</option>
                        {availableMeasurements.height.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {availableMeasurements.shoeSize?.length > 0 && (
                    <div className="form-group">
                      <label>{t("Shoe Size")}</label>
                      <select
                        className="form-input"
                        name="shoeSize"
                        value={sizeData.shoeSize || ""}
                        onChange={handleSizeInputChange}
                      >
                        <option value="">{t("Select Shoe Size")}</option>
                        {availableMeasurements.shoeSize.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                      <>sel</>
                    </div>
                  )}

                  {availableMeasurements.capSize?.length > 0 && (
                    <div className="form-group">
                      <label>{t("Cap Size")}</label>
                      <select
                        className="form-input"
                        name="capSize"
                        value={sizeData.capSize || ""}
                        onChange={handleSizeInputChange}
                      >
                        <option value="">{t("Select Cap Size")}</option>
                        {availableMeasurements.capSize.map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {availableMeasurements.uniqueSize?.length > 0 && (
                    <div className="form-group">
                      <label>{t("Size")}</label>
                      <select
                        className="form-input"
                        name="uniqueSize"
                        value={sizeData.uniqueSize || ""}
                        onChange={handleSizeInputChange}
                      >
                        <option value="">{t("Select Size")}</option>
                        {availableMeasurements.uniqueSize.map((size) => (
                          <option key={size} value={size}>
                            {t(size)}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className="info-text">
                  {selectedClient === "new" && displayTestOutfit ? (
                    <>
                      <p>{t("test_outfit_message", { day: testOutfitDay })}</p>
                      <p>{t("adjust_measurements_message")}</p>
                      <p>
                        {t("delivery_date_message", {
                          date: formatClientDeliveryDate(clientDeliveryDate),
                        })}
                      </p>
                    </>
                  ) : selectedClient === "already" ? (
                    <p>
                      {t("delivery_date_message", {
                        date: formatClientDeliveryDate(clientDeliveryDate),
                      })}
                    </p>
                  ) : null}
                </div>
              </div>
            )}
          </div>
          {selectedClient === "already" && (
            <>
              <hr className="styled-hr-top" />
              <Collapsible title={t("Message to Your Tailor")}>
                <p className="message-title">{t("Since my last order,")}</p>
                <div className="message-options">
                  <div className="message-option">
                    <span>{t("I have lost some weight")}</span>
                    <input type="radio" name="weightChange" />
                  </div>
                  <div className="message-option">
                    <span>{t("I have gained some weight")}</span>
                    <input type="radio" name="weightChange" />
                  </div>
                  <div className="message-option">
                    <span>{t("I haven’t changed much")}</span>
                    <input type="radio" name="weightChange" />
                  </div>
                </div>
              </Collapsible>
            </>
          )} */}
          {/* <hr className="styled-hr-bottom" /> */}
          <div className="bottom-row">
            <div className="client-code-button-container">
              {/* Order on WhatsApp Button */}
              <div
                className="client-code-button"
                onClick={() => {
                  window.open(
                    "https://wa.me/33620181435?text=Hello%2C%20I%20would%20like%20to%20order%20this%20product.",
                    "_blank"
                  );
                }}
              >
                {t("Order on WhatsApp")}
              </div>

              {/* Main Star Icon */}
              <div
                className="client-code-box-small"
                onClick={handleMainStarClick}
              >
                {isProductFavorited ? (
                  <BlueStarIcon className="dark-star-icon" />
                ) : (
                  <DarkStarIcon className="dark-star-icon" />
                )}
              </div>
            </div>
          </div>

          <div className="extras-section">
            <Collapsible title={t("Designer’s Notes")}>
              {designersNotes ? (
                formatText(designersNotes)
              ) : (
                <p>{t("No designer’s notes available")}</p>
              )}
            </Collapsible>
            <hr className="styled-hr" />

            <Collapsible title={t("Remote Bespoke")}>
              {remoteBespoke ? (
                formatText(remoteBespoke)
              ) : (
                <p>{t("No remote bespoke information available")}</p>
              )}
            </Collapsible>
            <hr className="styled-hr" />

            <Collapsible title={t("Delivery Options")}>
              {deliveryOptions ? (
                formatText(deliveryOptions)
              ) : (
                <p>{t("No delivery options available")}</p>
              )}
            </Collapsible>
            <hr className="styled-hr" />
          </div>
        </div>
      </div>
      <div className="extra-shop">
        {relatedProducts.length > 0 && (
          <>
            <h2 className="shop-the-look">{t("Shop the Look")}</h2>
            <hr className="styled-hr-small-selected" />
          </>
        )}

        <div className="products-grid">
          {relatedProducts.length > 0 ? (
            relatedProducts.map((relatedProduct) => {
              const isFavorite = favorites.some(
                (fav) => fav.id === relatedProduct.id
              );

              return (
                <div
                  key={relatedProduct.id}
                  className="product-wrapper-item"
                  onClick={() => handleProductClick(relatedProduct)}
                >
                  <div className="product-container">
                    <img
                      src={relatedProduct.image}
                      alt={relatedProduct.name}
                      className="container-image"
                    />
                  </div>
                  <div className="product-details">
                    <div className="new-in">
                      {relatedProduct.newIn ? (
                        t("Full Set")
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </div>
                    <div className="item-name">{relatedProduct.name}</div>
                    <div className="price">
                      {formatPrice(
                        parseFloat(
                          relatedProduct.price.replace(/[^0-9.-]+/g, "")
                        ) || 0
                      )}
                    </div>
                    <img
                      src={isFavorite ? filledStar : unfilledStar}
                      alt={isFavorite ? t("Filled Star") : t("Unfilled Star")}
                      className="unfilled-star"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        // Directly toggle favorite based on isFavorite
                        toggleFavorite({
                          id: relatedProduct.id,
                          name: relatedProduct.name,
                          image: relatedProduct.image,
                          category: relatedProduct.category,
                          price: parseFloat(
                            (relatedProduct.price || "0").replace(/[£,]/g, "")
                          ),
                          productId:
                            relatedProduct.productId || relatedProduct.id,
                          selectedForYou: [],
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p>{t("")}</p>
          )}
        </div>
      </div>

      <div className="selected-for-you">
        {selectedForYouProducts.length > 0 && (
          <>
            <h2 className="selected-for-you-title">{t("Selected for you")}</h2>
            <hr className="styled-hr-small-selected" />
          </>
        )}

        <div className="selected-products-grid">
          {selectedForYouProducts.length > 0 ? (
            selectedForYouProducts.map((selectedProduct, index) => (
              <div
                key={selectedProduct.id}
                className="selected-product-wrapper"
                onClick={() => handleProductClick(selectedProduct)} // Add onClick handler
              >
                <div className="selected-product-container">
                  <img
                    src={selectedProduct.image}
                    alt={selectedProduct.name}
                    className="container-image"
                  />
                </div>
                <div className="selected-product-details">
                  <div className="item-name">{selectedProduct.name}</div>
                  <div className="price">
                    {formatPrice(
                      parseFloat(
                        selectedProduct.price.replace(/[^0-9.-]+/g, "")
                      ) || 0
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>{t("")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullProduct;
