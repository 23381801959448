import React from "react";
import "../style/FooterDetails.css"; // Ensure the CSS file is correctly referenced
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const FooterDetails = () => {
  const { t } = useTranslation();

  const handleContactClick = () => {
    window.location.href = "/contact-us";
  };
  const handleSizeClick = () => {
    window.location.href = "/contact-us";
  };
  const handleMcknightClick = () => {
    window.location.href = "/about-us";
  };

  return (
    <div>
      <div className="promo-collapsible-container">
        <div className="promo-section">
          <p className="promo-text">
            {t("BE THE FIRST TO ACCESS NEW LAUNCHES")}
          </p>
          <div className="email-signup">
            <input
              type="text"
              placeholder={t("Email")}
              className="email-input"
            />
            <div className="signup-button">{t("SIGN UP")}</div>
          </div>
        </div>
        <div className="text-sections">
          <div className="text-section">
            <h3 onClick={handleContactClick}>
              <span className="footer-details-clicks">{t("Contact Us")}</span>
            </h3>
          </div>
          <div className="text-section">
            <h3 onClick={handleSizeClick}>
              <span className="footer-details-clicks">{t("Size Guide")}</span>
            </h3>
          </div>
          <div className="text-section">
            <h3 onClick={handleMcknightClick}>
              <span className="footer-details-clicks">{t("Mcknight")}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterDetails;
