// Import individual components from Firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDAUCXahqqJrJXhGRzwm5oq1PQtk3pL9vI",
  authDomain: "mcknight-homme.firebaseapp.com",
  projectId: "mcknight-homme",
  storageBucket: "mcknight-homme.appspot.com",
  messagingSenderId: "606514712016",
  appId: "1:606514712016:web:d58ad20bbdd12a7def7dd5",
  measurementId: "G-XDTE8FPVRK",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get instances for Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); // Add Firebase Storage instance
