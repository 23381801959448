// src/components/CountrySelector.js

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { ReactComponent as CloseIcon } from "../icons/cancel.svg";
import { ReactComponent as DropdownIcon } from "../icons/dropdown.svg";
import { ReactComponent as SpinnerIcon } from "../icons/spinner.svg";
import { useTranslation } from "react-i18next";
import { getNames } from "country-list"; // Import the country names
import { CurrencyContext } from "../context/CurrencyContext"; // Import CurrencyContext

const countryOptions = [
  {
    country: "France",
    defaultLanguage: "French",
    languages: ["French", "English"],
    defaultCurrency: "EUR (€)",
    currencies: ["EUR (€)", "USD ($)"],
  },
  {
    country: "Belgium",
    defaultLanguage: "French",
    languages: ["French", "English"],
    defaultCurrency: "EUR (€)",
    currencies: ["EUR (€)", "USD ($)"],
  },
  {
    country: "United Kingdom",
    defaultLanguage: "English",
    languages: ["English"],
    defaultCurrency: "Pounds (£)",
    currencies: ["GBP (£)", "EUR (€)", "USD ($)"], // Corrected "Pounds (£)" to "GBP (£)"
  },
  {
    country: "United States of America",
    defaultLanguage: "English",
    languages: ["English", "French"],
    defaultCurrency: "USD ($)",
    currencies: ["USD ($)", "EUR (€)"],
  },
  {
    country: "UAE",
    defaultLanguage: "English",
    languages: ["English", "French"],
    defaultCurrency: "USD ($)",
    currencies: ["USD ($)", "EUR (€)"],
  },
  {
    country: "Portugal",
    defaultLanguage: "English",
    languages: ["English", "French"],
    defaultCurrency: "EUR (€)",
    currencies: ["EUR (€)", "USD ($)"],
  },
  {
    country: "Germany",
    defaultLanguage: "English",
    languages: ["English", "French"],
    defaultCurrency: "EUR (€)",
    currencies: ["EUR (€)", "USD ($)"],
  },
  {
    country: "Italy",
    defaultLanguage: "English",
    languages: ["English", "French"],
    defaultCurrency: "EUR (€)",
    currencies: ["EUR (€)", "USD ($)"],
  },
  {
    country: "Switzerland",
    defaultLanguage: "English",
    languages: ["English", "French"],
    defaultCurrency: "EUR (€)",
    currencies: ["EUR (€)", "USD ($)"],
  },
  {
    country: "Nigeria",
    defaultLanguage: "English",
    languages: ["English", "French"],
    defaultCurrency: "USD ($)",
    currencies: ["USD ($)", "EUR (€)"],
  },
  {
    country: "Guinea Conakry",
    defaultLanguage: "French",
    languages: ["French", "English"],
    defaultCurrency: "EUR (€)",
    currencies: ["EUR (€)", "USD ($)"],
  },
];

const defaultOptions = {
  defaultLanguage: "English",
  languages: ["English", "French"],
  defaultCurrency: "USD ($)",
  currencies: ["USD ($)", "EUR (€)"],
};

const CountrySelector = ({ onClose, onLanguageChange }) => {
  const { currency, changeCurrency } = useContext(CurrencyContext); // Access CurrencyContext
  const [tempCountry, setTempCountry] = useState(
    localStorage.getItem("country") || "Select country"
  );
  const [tempCurrency, setTempCurrency] = useState(
    localStorage.getItem("currency") || defaultOptions.defaultCurrency
  );
  const [tempLanguage, setTempLanguage] = useState(
    localStorage.getItem("language") === "fr"
      ? "French"
      : localStorage.getItem("language") === "en"
      ? "English"
      : defaultOptions.defaultLanguage
  );
  const [isLoading, setIsLoading] = useState(false);
  const [languageOptions, setLanguageOptions] = useState(
    defaultOptions.languages
  );
  const [currencyOptions, setCurrencyOptions] = useState(
    defaultOptions.currencies
  );
  const modalRef = useRef(null);
  const { t } = useTranslation();

  // Import the full list of country names
  const allCountries = getNames();

  const handleLanguageSelect = (lang) => {
    setTempLanguage(lang);
  };

  const handleUpdatePreferences = () => {
    setIsLoading(true);
    // Set country in localStorage
    localStorage.setItem("country", tempCountry);

    // Extract the currency code from tempCurrency (e.g., "EUR (€)" -> "EUR")
    const currencyCode = tempCurrency.split(" ")[0];
    changeCurrency(currencyCode); // Update currency via CurrencyContext

    // Set language in localStorage and update language context
    localStorage.setItem("language", tempLanguage === "English" ? "en" : "fr");
    onLanguageChange(tempLanguage === "English" ? "en" : "fr");

    setTimeout(() => {
      setIsLoading(false);
      onClose();
    }, 2000);
  };

  useEffect(() => {
    const savedCountry = localStorage.getItem("country");
    const savedCurrency = localStorage.getItem("currency");
    const savedLanguage = localStorage.getItem("language");

    if (savedCountry) setTempCountry(savedCountry);
    if (savedCurrency) setTempCurrency(savedCurrency);
    if (savedLanguage)
      setTempLanguage(savedLanguage === "fr" ? "French" : "English");
  }, []);

  useEffect(() => {
    const selectedCountry = countryOptions.find(
      (option) => option.country === tempCountry
    );

    if (selectedCountry) {
      setLanguageOptions(selectedCountry.languages);
      setCurrencyOptions(selectedCountry.currencies);

      // If tempLanguage is not in the available options, set to default
      if (!selectedCountry.languages.includes(tempLanguage)) {
        setTempLanguage(selectedCountry.defaultLanguage);
      }

      // If tempCurrency is not in the available options, set to default
      if (!selectedCountry.currencies.includes(tempCurrency)) {
        setTempCurrency(selectedCountry.defaultCurrency);
      }
    } else if (tempCountry === "Select country") {
      // Reset to default options
      setLanguageOptions(defaultOptions.languages);
      setCurrencyOptions(defaultOptions.currencies);
      setTempLanguage(defaultOptions.defaultLanguage);
      setTempCurrency(defaultOptions.defaultCurrency);
    } else {
      // For other countries not in countryOptions
      setLanguageOptions(defaultOptions.languages);
      setCurrencyOptions(defaultOptions.currencies);

      // If tempLanguage is not in the available options, set to default
      if (!defaultOptions.languages.includes(tempLanguage)) {
        setTempLanguage(defaultOptions.defaultLanguage);
      }

      // If tempCurrency is not in the available options, set to default
      if (!defaultOptions.currencies.includes(tempCurrency)) {
        setTempCurrency(defaultOptions.defaultCurrency);
      }
    }
  }, [tempCountry]);

  useEffect(() => {
    console.log("Temp Country: ", tempCountry);
    console.log("Temp Currency: ", tempCurrency);
    console.log("Temp Language: ", tempLanguage);
  }, [tempCountry, tempCurrency, tempLanguage]);

  const handleClickOutsideModal = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [handleClickOutsideModal]);

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    container: {
      backgroundColor: "white",
      padding: "50px 20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      position: "relative",
      textAlign: "center",
      width: "320px",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      cursor: "pointer",
    },
    title: {
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: "normal",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px",
    },
    dropdownContainer: {
      flex: 1,
      margin: "0 5px",
    },
    updatePreferences: {
      marginTop: "40px",
      textDecoration: "underline",
      fontSize: "14px",
      cursor: "pointer",
    },
    spinner: {
      width: "20px",
      height: "20px",
    },
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.container} ref={modalRef}>
        <CloseIcon style={styles.closeButton} onClick={onClose} />
        <h2 style={styles.title}>{t("Country / Pays")}:</h2>

        <div style={{ margin: "0 5px" }}>
          <CustomDropdown
            value={tempCountry}
            onChange={setTempCountry}
            withSeparators={true}
            countryOptions={countryOptions} // Pass countryOptions as a prop
            allCountries={allCountries} // Pass allCountries as a prop
          />
        </div>

        <div style={styles.row}>
          <div style={styles.dropdownContainer}>
            <h2 style={styles.title}>{t("Currency / Devise")}:</h2>

            <CustomDropdown
              options={currencyOptions}
              value={tempCurrency}
              onChange={setTempCurrency}
            />
          </div>
          <div style={styles.dropdownContainer}>
            <h2 style={styles.title}>{t("Language / Langue")}:</h2>
            <CustomDropdown
              options={languageOptions}
              value={tempLanguage}
              onChange={handleLanguageSelect}
            />
          </div>
        </div>

        <div style={styles.updatePreferences} onClick={handleUpdatePreferences}>
          {isLoading ? (
            <SpinnerIcon style={styles.spinner} />
          ) : (
            t("Validate / Valider")
          )}
        </div>
      </div>
    </div>
  );
};

const CustomDropdown = ({
  options,
  value,
  onChange,
  withSeparators,
  countryOptions,
  allCountries,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  const styles = {
    container: {
      position: "relative",
      width: "100%",
      cursor: "pointer",
    },
    selected: {
      border: "1px solid #ccc",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#fff",
      fontSize: "12px",
    },
    options: {
      position: "absolute",
      top: "100%",
      left: "-1px",
      width: "100%",
      border: "1px solid #ccc",
      borderTop: "none",
      backgroundColor: "#fff",
      zIndex: 10,
      maxHeight: "230px",
      overflowY: "auto",
    },
    option: {
      padding: "10px",
      borderBottom: "1px solid #ccc",
      backgroundColor: "#fff",
      fontSize: "12px",
    },
    optionHover: {
      backgroundColor: "#f1f1f1",
    },
    separator: {
      padding: "10px",
      backgroundColor: "#f1f1f1",
      fontSize: "12px",
      fontWeight: "bold",
    },
  };

  // Compute suggested countries and other countries
  const suggestedCountries = countryOptions
    ? countryOptions.map((option) => option.country)
    : [];

  const otherCountries = allCountries
    ? allCountries.filter((country) => !suggestedCountries.includes(country))
    : [];

  // Optionally sort otherCountries alphabetically
  otherCountries.sort((a, b) => a.localeCompare(b));

  return (
    <div style={styles.container} ref={dropdownRef}>
      <div style={styles.selected} onClick={() => setIsOpen(!isOpen)}>
        {value}
        <DropdownIcon />
      </div>
      {isOpen && (
        <div style={styles.options}>
          {withSeparators && (
            <>
              <div style={styles.separator}>Suggested Countries</div>
              {suggestedCountries.map((country) => (
                <div
                  key={country}
                  style={styles.option}
                  onClick={() => handleOptionClick(country)}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      styles.optionHover.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      styles.option.backgroundColor)
                  }
                >
                  {country}
                </div>
              ))}
              <div style={styles.separator}>All Countries</div>
              {otherCountries.map((country) => (
                <div
                  key={country}
                  style={styles.option}
                  onClick={() => handleOptionClick(country)}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      styles.optionHover.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      styles.option.backgroundColor)
                  }
                >
                  {country}
                </div>
              ))}
            </>
          )}
          {!withSeparators &&
            options.map((option) => (
              <div
                key={option}
                style={styles.option}
                onClick={() => handleOptionClick(option)}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    styles.optionHover.backgroundColor)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    styles.option.backgroundColor)
                }
              >
                {option}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CountrySelector;
