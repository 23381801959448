import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../icons/menu.svg"; // Adjust the import path
import "../style/AdminPanel.css"; // Import the new CSS file
import Dashboard from "./Dashboard";
import ProductManagement from "./ProductManagement";
import CategoryManagement from "./CategoryManagement";
import ViewOrders from "./ViewOrders"; // Import ViewOrders component
import Settings from "./Settings";
import UserManagement from "./UserManagement"; // Import UserManagement component

const AdminPanel = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const defaultComponent = location.state?.activeComponent || "dashboard";
  const [activeComponent, setActiveComponent] = useState(defaultComponent);
  const drawerRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawerOpen(false);
      }
    };

    if (drawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [drawerOpen]);

  const handleLogout = () => {
    navigate("/mc-admin");
  };

  const handleLinkClick = (component) => {
    setActiveComponent(component);
    setDrawerOpen(false);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "dashboard":
        return <Dashboard />;
      case "productManagement":
        return <ProductManagement />;
      case "categoryManagement":
        return <CategoryManagement />;
      case "viewOrders":
        return <ViewOrders />;
      case "settings":
        return <Settings />;
      case "userManagement":
        return <UserManagement />; // Add UserManagement here
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="admin-panel-container">
      <nav className="admin-nav-container">
        <MenuIcon
          className="admin-icon left-icon"
          onClick={() => setDrawerOpen(true)}
        />
        <span className="brand-text">MCKNIGHT</span>
        <div className="admin-icon right-icon"></div>
      </nav>
      {drawerOpen && (
        <div
          className="admin-overlay"
          onClick={() => setDrawerOpen(false)}
        ></div>
      )}
      <div
        className={`admin-drawer ${drawerOpen ? "open" : ""}`}
        ref={drawerRef}
      >
        <div className="drawer-content-top">
          <span className="brand-text">MCKNIGHT</span>
          <div
            className="admin-link"
            onClick={() => handleLinkClick("dashboard")}
          >
            Dashboard
          </div>
          <div
            className="admin-link"
            onClick={() => handleLinkClick("productManagement")}
          >
            Product Management
          </div>
          <div
            className="admin-link"
            onClick={() => handleLinkClick("categoryManagement")}
          >
            Category Management
          </div>
          <div
            className="admin-link"
            onClick={() => handleLinkClick("viewOrders")}
          >
            View Orders
          </div>
          <div
            className="admin-link"
            onClick={() => handleLinkClick("userManagement")}
          >
            User Management
          </div>

          <div
            className="admin-link"
            onClick={() => handleLinkClick("settings")}
          >
            Settings
          </div>
          <div className="admin-link" onClick={handleLogout}>
            Log out
          </div>
        </div>
      </div>
      <div className={`main-content ${drawerOpen ? "drawer-open" : ""}`}>
        {renderComponent()}
      </div>
      <div className="please-switch-to-desktop">Please switch to desktop</div>
    </div>
  );
};

export default AdminPanel;
