import React, { useState, useRef } from "react";
import "../style/Collapsible.css"; // Ensure the CSS file is correctly referenced

const Collapsible = React.memo(({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`collapsible ${isOpen ? "open" : ""}`}>
      <button className="collapsible-header" onClick={toggleCollapse}>
        {title}
        <span className={`collapsible-icon ${isOpen ? "open" : ""}`}></span>
      </button>
      <div ref={contentRef} className="collapsible-content">
        {children}
      </div>
    </div>
  );
});

export default Collapsible;
