// src/components/Overlay.js

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "../style/Overlay.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as McKnightLogo } from "../icons/mcknight_white_removed.svg";

const Overlay = ({ message, isVisible, onClose, productSummary }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const portalRoot = document.getElementById("portal-root");
  if (!portalRoot) {
    console.error("Portal root element not found!");
    return null;
  }

  console.log("Received productSummary:", productSummary);

  // Function to get the latest delivery date from the products
  const getLatestDeliveryDate = (products) => {
    let latestDate = null;
    products.forEach((product) => {
      if (product.deliveryDate) {
        const date = new Date(product.deliveryDate); // Parses ISO string correctly
        if (!isNaN(date)) {
          if (!latestDate || date > latestDate) {
            latestDate = date;
          }
        }
      }
    });
    return latestDate;
  };

  const formatDeliveryDate = (date) => {
    if (!date) return "";

    const day = date.getDate();
    const month = date.toLocaleString(i18n.language, { month: "long" });
    return `${day} ${month}`;
  };

  const latestDeliveryDate = getLatestDeliveryDate(productSummary);

  const formattedDeliveryDate = latestDeliveryDate
    ? formatDeliveryDate(latestDeliveryDate)
    : "";

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={styles.overlayContent}
        onClick={(e) => e.stopPropagation()}
      >
        <h2>
          {t("Order placed")}
          <br />
          {t("Successfully!")}
        </h2>

        <p>{t("Your order has been received")}</p>

        {/* Centered asterisks */}
        <div style={{ textAlign: "center", fontSize: "24px" }}>*****</div>

        {/* Delivery Heading */}
        <h3>{t("Delivery | Livraison")}</h3>

        {/* Display Delivery Date */}
        {formattedDeliveryDate && <p>{formattedDeliveryDate}</p>}
        {/* Insert the SVG Image */}
        <div className={styles.imageContainer}>
          <McKnightLogo className={styles.overlayImage} />
        </div>

        {/* Horizontal Rule */}
        <hr className={styles.overlayHr} />

        <button className={styles.overlayButton} onClick={onClose}>
          OK
        </button>
      </div>
    </div>,
    portalRoot
  );
};

export default Overlay;
