// src/components/VideoComponent.js
import React from "react";
import styles from "../style/VideoComponent.module.css"; // Import CSS Module
import { useTranslation } from "react-i18next";

const VideoComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.videoContainer}>
      <div className={styles.videoResponsive}>
        <iframe
          src="https://www.youtube.com/embed/qOnzEg0W4TM"
          title="Promotional Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={styles.videoIframe}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoComponent;
