import React, { useState, useEffect } from "react";
import { getImageUrl } from "../firebaseActions";
import { useTranslation } from "react-i18next";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase-config";

function Home() {
  const { t, i18n } = useTranslation();
  const [imageUrl, setImageUrl] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const fetchImage = async () => {
    try {
      const docPath = isMobile ? "mobile" : "desktop";
      const latestDoc = await getDoc(doc(db, "PromoImages", docPath));

      if (latestDoc.exists()) {
        const { fileName } = latestDoc.data();
        const imagePath = isMobile
          ? `dkp-promo-mobile/${fileName}`
          : `dkp-promo-desktop/${fileName}`;

        const cachedImageUrl = localStorage.getItem(imagePath);

        if (cachedImageUrl) {
          setImageUrl(cachedImageUrl);
        } else {
          const url = await getImageUrl(imagePath);
          setImageUrl(url);
          localStorage.setItem(imagePath, url);
        }
      } else {
        console.error(
          `No promotional image found for ${isMobile ? "Mobile" : "Desktop"}`
        );
      }
    } catch (error) {
      console.error("Error fetching promotional image:", error);
    }
  };

  useEffect(() => {
    fetchImage();

    const handleResize = () => {
      const isCurrentlyMobile = window.innerWidth <= 600;
      if (isMobile !== isCurrentlyMobile) {
        setIsMobile(isCurrentlyMobile);
        fetchImage();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    const handleLanguageChanged = () => {
      setImageUrl((prevImageUrl) => prevImageUrl);
    };

    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [i18n]);

  const handleButtonClick = () => {
    window.location.href = "/products";
  };

  return (
    <div
      className="viewport"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="content">
        <button className="homme-button" onClick={handleButtonClick}>
          {t("M A N")}
        </button>
      </div>
    </div>
  );
}

export default Home;
