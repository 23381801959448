import { useState, useEffect } from "react";
import { collectionGroup, getDocs } from "firebase/firestore"; // collectionGroup for querying subcollections
import { db } from "../firebase-config"; // Adjust the path as necessary

const useFetchProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productCount, setProductCount] = useState(0);
  const [categoryCounts, setCategoryCounts] = useState({}); // New state to store the product counts by category

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollectionGroup = collectionGroup(db, "items"); // Query all items subcollections
        const productSnapshot = await getDocs(productsCollectionGroup);
        const productList = productSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(productList);
        setProductCount(productList.length);

        // Calculate product counts by category
        const counts = {};
        productList.forEach((product) => {
          const category = product.categories; // Adjust this according to your data structure
          if (counts[category]) {
            counts[category]++;
          } else {
            counts[category] = 1;
          }
        });

        setCategoryCounts(counts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return { products, loading, productCount, categoryCounts };
};

export default useFetchProducts;
