// Success.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const Success = () => {
  const location = useLocation();
  const [session, setSession] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("session_id");

    if (sessionId) {
      // Optionally, you can fetch session details from your backend
      setSession(sessionId);
      // Here, you can also trigger saving the order to Firebase
    }
  }, [location.search]);

  return (
    <div>
      <h1>Payment Successful!</h1>
      {session ? <p>Session ID: {session}</p> : <p>Loading...</p>}
      {/* Add any additional success information or redirection */}
    </div>
  );
};

export default Success;
