// src/components/ContactUs.js

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "../style/ContactUs.module.css";
import consultationImage from "../images/consultation.webp";
import customisationImage from "../images/customisation.webp";
import outfitrequest from "../images/outfit_request.webp";
import general_info from "../images/general_info.webp";
import career from "../images/career.webp";

const ContactUs = () => {
  const { t } = useTranslation();
  const customDesignsRef = useRef(null);
  const testOutfitRef = useRef(null);
  const careerInfoRef = useRef(null);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.subtitle}>{t("Contact Us")}</h2>
        <nav className={styles.nav}>
          <a
            href="#"
            className={styles.navItem}
            onClick={(e) => {
              e.preventDefault();
              customDesignsRef.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            {t("Custom Designs")}
          </a>
          <a
            href="#"
            className={styles.navItem}
            onClick={(e) => {
              e.preventDefault();
              testOutfitRef.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            {t("Test Outfit")}
          </a>
          <a
            href="#"
            className={styles.navItem}
            onClick={(e) => {
              e.preventDefault();
              careerInfoRef.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            {t("Career Info")}
          </a>
        </nav>
      </header>

      <section
        id="custom-designs"
        className={styles.section}
        ref={customDesignsRef}
      >
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>
              {t("Consultation for a custom marriage attire")}
            </h3>
            <p className={styles.sectionText}>
              {t(
                "Book a call with our Design Team to discuss the customization of your wedding/marriage attire."
              )}
              <br />
              {t("Link:")}{" "}
              <a
                href="https://calendly.com/igbunusamuel/30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://calendly.com/igbunusamuel/30min
              </a>
            </p>
          </div>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${consultationImage})` }}
          />
        </div>
      </section>

      <section
        id="customization-special-event"
        className={styles.section}
        ref={testOutfitRef}
      >
        <div className={styles.contentContainer}>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${customisationImage})` }}
          />
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>
              {t("Customization for a special event")}
            </h3>
            <p className={styles.sectionText}>
              {t(
                "You have an important occasion coming up and want a unique piece. Book a session with our Design Team to discuss your ideas."
              )}
              <br />
              {t("Link:")}{" "}
              <a
                href="https://calendly.com/igbunusamuel/30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Custom Outfit Customisation - iSolve")}
              </a>
            </p>
          </div>
        </div>
      </section>

      <section id="test-outfit" className={styles.section}>
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>
              {t("You want an outfit that's not on our page")}
            </h3>
            <p className={styles.sectionText}>
              {t(
                "Send us a sketch/picture of the outfit(s) you want. We will evaluate the design and send you a link to order it."
              )}
              <br />
              <a href="https://wa.me/+33620181435">{t("WhatsApp")}</a>
            </p>
          </div>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${outfitrequest})` }}
          />
        </div>
      </section>

      <section id="general-info" className={styles.section}>
        <div className={styles.contentContainer}>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${general_info})` }}
          />
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>{t("General Info.")}</h3>
            <p className={styles.sectionText}>
              {t(
                "If you ever have an issue with your order or would simply like to "
              )}
              <a href="#">{t("get in touch with us")}</a>
              {t(", contact us via WhatsApp")}
              <br />
              {t("on +33 620 18 14 35.")}
            </p>
          </div>
        </div>
      </section>

      <section id="career-info" className={styles.section} ref={careerInfoRef}>
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>{t("Career")}</h3>
            <p className={styles.sectionText}>
              {t("For job opportunities, reach out to us at ")}
              <a href="mailto:mcknightcouture@gmail.com">
                mcknightcouture@gmail.com
              </a>
            </p>
          </div>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${career})` }}
          />
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
