// src/components/AboutUs.js

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "../style/AboutUs.module.css";
import startSmallImage from "../images/start_small.webp";
import mcknightTodayImage from "../images/mcknight_today.webp";
import remoteBespokeImage from "../images/remote_bespoke.webp";

const AboutUs = () => {
  const { t } = useTranslation();

  // Create refs for each section
  const startSmallRef = useRef(null);
  const mcknightTodayRef = useRef(null);
  const remoteBespokeRef = useRef(null);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.subtitle}>{t("About McKnight Homme")}</h2>
        <nav className={styles.nav}>
          <span
            className={styles.navItem}
            onClick={() =>
              startSmallRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            {t("Little Beginnings")}
          </span>
          <span
            className={styles.navItem}
            onClick={() =>
              mcknightTodayRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            {t("McKnight Today")}
          </span>
          <span
            className={styles.navItem}
            onClick={() =>
              remoteBespokeRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            {t("Remote Bespoke")}
          </span>
        </nav>
      </header>

      {/* Start Small Section */}
      <section id="start-small" className={styles.section} ref={startSmallRef}>
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>{t("Start Small")}</h3>
            <p className={styles.sectionText}>
              {t(
                "The first McKnight outfit was made out of boredom. During my early days as an engineering undergraduate, I developed a habit of waking up very early (around 2am) to study before dawn. This was so I could make up for the language barrier I had studying electrical engineering in French. This habit paid off; I was able to graduate with honors in 2019 from INSA Lyon. However, after graduating it didn’t die off, I continued waking up at 2am, only this time, without any engineering exams to prepare for."
              )}
            </p>
            <p className={styles.sectionText}>
              {t(
                "I must have read every book I came across in those first few years, just trying to occupy my mind before dawn. Soon, I ran out of books (or became bored with them) and I really had to find something to do with all that time between 2am and 6am every day."
              )}
            </p>
            <p className={styles.sectionText}>
              {t(
                "I’ve always seen myself as an artist, a creator of some sort. I spent my early teenage years building toy cars and houses with my brothers in Sapele, Nigeria. Those years were gone but still in my heart, I loved to make stuff. It’s actually why I opted for engineering. I thought I was going to be building new ideas, lol."
              )}
            </p>
          </div>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${startSmallImage})` }}
          />
        </div>
      </section>

      {/* Additional Paragraphs */}
      <section className={styles.section}>
        <div className={styles.textOnlyContainer}>
          <p className={styles.sectionText}>
            {t(
              "So, naturally, I thought to myself, “What can I invest these free hours into building?” One thing I was certain of even at that time was that I wasn’t looking simply for a way to while away time. I wanted an idea/project that was going to challenge me creatively, not be too demanding in the way of seed fund, have some lucrative potential and be scalable (should the need ever arise)."
            )}
          </p>
          <p className={styles.sectionText}>
            {t(
              "I first thought about making female handbags, but I quickly discarded the idea as it didn’t look very promising financially and was going to be very time demanding (working leather is no joke). So I bought a Brother sewing machine from AMAZON. I was going to teach myself to sew with the help of YouTube and GOOGLE."
            )}
          </p>
          <p className={styles.sectionText}>
            {t(
              "I admit I wasn’t really counting on the gig being lucrative, I was fine just knowing that it could be financially profitable. The idea met my 4 criteria, so I said to Jeff, “Let’s Do It!”"
            )}
          </p>
          <p className={styles.sectionText}>
            {t(
              "I made the first top (maybe I’ll drop a picture here) and I remember being so excited about it. So much so that I wore it to work the next day. I felt on top of the world wearing something I made with my own hands into the world, in front of other humans. It felt like a superpower."
            )}
          </p>
          <p className={styles.sectionText}>
            {t(
              "I kept dancing around with some ideas on YouTube for a couple of weeks and it was just beautiful watching myself learn techniques and apply them to real clothes that I wore. I think I would pay to relive those moments. Just awesome."
            )}
          </p>
          <p className={styles.sectionText}>
            {t(
              "Then one day, that first top I made, I wore it to work, and a colleague liked it. Then he said, “You know you can actually make tops like these and sell them?” I kid you not, a light bulb came on in my head as he spoke the words. I suddenly realized that people could actually pay for something I made with my hands, in my room. Brooooooo! That was it, that was the moment it all changed for me. There was no going back, I decided to build a business around it and nothing was going to stop me from thereon."
            )}
          </p>
        </div>
      </section>

      {/* McKnight Today Section */}
      <section
        id="mcknight-today"
        className={styles.section}
        ref={mcknightTodayRef}
      >
        <div className={styles.contentContainer}>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${mcknightTodayImage})` }}
          />
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>{t("McKnight Today")}</h3>
            <p className={styles.sectionText}>
              {t(
                "One day you’ll be able to order a perfect bespoke outfit online without having to meet any tailor. At McKnight that day is today."
              )}
            </p>
            <p className={styles.sectionText}>
              {t(
                "We could talk about our superb tailoring or our infinite catalogue of designs to choose from, but we won’t do that because, what really sets us apart is that we care 24/7 for our clients and we will never stop innovating to improve their lives."
              )}
            </p>
            <p className={styles.sectionText}>
              {t(
                "This is McKnight. Yes, we make clothes, but it’s really Peace of Mind that we sell. To go to bed knowing that, wherever you are, your outfit will find you, it will fit you and it will arrive on time. Every Single Time."
              )}
            </p>
            <p className={styles.sectionText}>
              {t(
                "My name is Jeff, hopefully we get to meet each other one of these days. Till then, take care of yourself brother."
              )}
            </p>
          </div>
        </div>
      </section>

      {/* Remote Bespoke Section */}
      <section
        id="remote-bespoke"
        className={styles.section}
        ref={remoteBespokeRef}
      >
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <h3 className={styles.sectionTitle}>
              {t("Remote Bespoke (Rem-ote™)")}
            </h3>
            <p className={styles.sectionText}>
              {t(
                "With your height, shirt size and waist size, our AI system precisely calculates the values for your test outfit."
              )}
            </p>
            <p className={styles.sectionText}>
              {t(
                "After trying out the test outfit, we see the necessary changes, adjust your values in our database and then produce your real McKnight outfit bespoke."
              )}
            </p>
            <p className={styles.sectionText}>
              {t(
                "We also save your measurements so that next time you return to the website, you simply input your Client Code and we identify your exact values for your bespoke order."
              )}
            </p>
          </div>
          <div
            className={styles.imageContainer}
            style={{ backgroundImage: `url(${remoteBespokeImage})` }}
          />
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
