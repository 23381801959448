import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as BackIcon } from "../icons/back.svg";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import "../style/SearchResults.css";
import ProductDisplay from "./ProductDisplay"; // Import ProductDisplay component

const SearchResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = location.state?.query || "";
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const [imageItems, setImageItems] = useState([
    <div className="search-image-item-unique" key="1">
      Image 1
    </div>,
    <div className="search-image-item-unique" key="2">
      Image 2
    </div>,
    <div className="search-image-item-unique" key="3">
      Image 3
    </div>,
    <div className="search-image-item-unique" key="4">
      Image 4
    </div>,
  ]);

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from, {
        state: { activeComponent: location.state.activeComponent },
      }); // Navigate back to the previous state with activeComponent
    } else {
      navigate("/mc-admin/panel"); // Navigate back to the AdminPanel
    }
  };

  const products = [
    {
      name: "Product 1",
      price: 100,
      sold: 10,
      shopLook: 3,
      attachedAssets: ["Asset 1", "Asset 2"],
    },
    {
      name: "Product 2",
      price: 200,
      sold: 20,
      shopLook: 5,
      attachedAssets: ["Asset 1"],
    },
    {
      name: "Product 3",
      price: 150,
      sold: 15,
      shopLook: 2,
      attachedAssets: ["Asset 1", "Asset 2", "Asset 3"],
    },
    {
      name: "Product 4",
      price: 250,
      sold: 30,
      shopLook: 4,
      attachedAssets: ["Asset 1"],
    },
    {
      name: "Product 5",
      price: 250,
      sold: 30,
      shopLook: 4,
      attachedAssets: ["Asset 1"],
    },
    {
      name: "Product 6",
      price: 250,
      sold: 30,
      shopLook: 4,
      attachedAssets: ["Asset 1"],
    },
    // Add more products as needed
  ];

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const addImage = () => {
    setImageItems([
      ...imageItems,
      <div className="search-image-item-unique" key={imageItems.length + 1}>
        New Image
      </div>,
    ]);
  };

  const removeImage = (index) => {
    const newImageItems = imageItems.filter((_, i) => i !== index);
    setImageItems(newImageItems);
    if (currentSlide >= newImageItems.length) {
      setCurrentSlide(newImageItems.length - 1);
    }
  };

  const addAsset = () => {
    const newAssets = [
      ...selectedProduct.attachedAssets,
      `Asset ${selectedProduct.attachedAssets.length + 1}`,
    ];
    setSelectedProduct({
      ...selectedProduct,
      attachedAssets: newAssets,
    });
  };

  const removeAsset = (index) => {
    const newAssets = selectedProduct.attachedAssets.filter(
      (_, i) => i !== index
    );
    setSelectedProduct({
      ...selectedProduct,
      attachedAssets: newAssets,
    });
  };

  const renderAttachedAssets = (assets) => {
    if (assets.length <= 3 && isEditing) {
      return (
        <>
          {assets.map((asset, index) => (
            <div className="search-asset-container-unique" key={index}>
              {asset}
              {isEditing && (
                <div
                  className="search-cancel-icon-unique"
                  onClick={() => removeAsset(index)}
                >
                  ×
                </div>
              )}
            </div>
          ))}
          <div className="search-add-asset-unique" onClick={addAsset}>
            Add asset
          </div>
        </>
      );
    }

    return assets.map((asset, index) => (
      <div className="search-asset-container-unique" key={index}>
        {asset}
        {isEditing && (
          <div
            className="search-cancel-icon-unique"
            onClick={() => removeAsset(index)}
          >
            ×
          </div>
        )}
      </div>
    ));
  };

  const handleTouchStart = (e) => {
    setStartX(e.targetTouches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      e.preventDefault();
      const currentX = e.targetTouches[0].clientX;
      const moveX = startX - currentX;
      if (moveX > 30) {
        nextSlide();
        setIsDragging(false);
      } else if (moveX < -30) {
        prevSlide();
        setIsDragging(false);
      }
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      e.preventDefault();
      const currentX = e.clientX;
      const moveX = startX - currentX;
      if (moveX > 30) {
        nextSlide();
        setIsDragging(false);
      } else if (moveX < -30) {
        prevSlide();
        setIsDragging(false);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const nextSlide = () => {
    setCurrentSlide((prevIndex) => (prevIndex + 1) % imageItems.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevIndex) => (prevIndex - 1 + imageItems.length) % imageItems.length
    );
  };

  const handlePaginationClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="search-results-container-unique">
      <div className="search-results-header-unique">
        <div className="back-button-unique" onClick={handleBackClick}>
          <BackIcon className="back-icon-unique" />
          <span>Back</span>
          <span className="search-results-text-unique">
            Search Results for "{searchQuery}"
          </span>
        </div>
        <div className="search-container-unique">
          <SearchIcon className="icon-unique" />
          <input
            type="text"
            placeholder="Search"
            className="search-input-unique"
          />
        </div>
      </div>
      <div className="search-results-content-unique">
        <div className="left-section-unique">
          <div className="product-list-unique">
            {products.map((product, index) => (
              <ProductDisplay
                key={index}
                product={product}
                onSelect={handleProductSelect}
              />
            ))}
          </div>
        </div>
        <div className="right-section-unique">
          <div className="right-content-container-unique">
            {selectedProduct ? (
              <>
                <div className="search-edit-product-header-unique">
                  <div className="search-edit-product-title-unique">
                    {isEditing ? "" : "Edit Product"}
                  </div>
                  {isEditing && (
                    <div className="search-add-image-unique" onClick={addImage}>
                      Add image
                    </div>
                  )}
                </div>
                {imageItems.length === 0 ? (
                  <div className="search-new-containers-unique no-image-unique">
                    No image product
                  </div>
                ) : (
                  <div
                    className="search-new-container-unique"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                  >
                    <div
                      className="search-image-item-wrapper-unique"
                      style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                      }}
                    >
                      {imageItems.map((item, index) => (
                        <div className="search-image-item-unique" key={index}>
                          {item}
                          {isEditing && (
                            <div
                              className="search-cancel-icon-unique"
                              onClick={() => removeImage(index)}
                            >
                              ×
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="search-pagination-container-unique">
                  {imageItems.map((_, index) => (
                    <div
                      key={index}
                      className={`search-pagination-circle-unique ${
                        index === currentSlide ? "active" : ""
                      }`}
                      onClick={() => handlePaginationClick(index)}
                    ></div>
                  ))}
                </div>
                <div className="search-product-details-unique">
                  <div className="search-detail-row-unique">
                    <div className="search-detail-label-unique">
                      Product Name:
                    </div>
                    <div className="search-detail-info-unique">
                      {isEditing ? (
                        <input
                          type="text"
                          value={selectedProduct.name}
                          onChange={(e) =>
                            setSelectedProduct({
                              ...selectedProduct,
                              name: e.target.value,
                            })
                          }
                        />
                      ) : (
                        selectedProduct.name
                      )}
                    </div>
                  </div>
                  <div className="search-detail-row-unique">
                    <div className="search-detail-label-unique">Price:</div>
                    <div className="search-detail-info-unique">
                      {isEditing ? (
                        <input
                          type="text"
                          value={selectedProduct.price}
                          onChange={(e) =>
                            setSelectedProduct({
                              ...selectedProduct,
                              price: e.target.value,
                            })
                          }
                        />
                      ) : (
                        `$${selectedProduct.price}`
                      )}
                    </div>
                  </div>
                  <div className="search-detail-row-unique">
                    <div className="search-detail-label-unique">
                      Availability:
                    </div>
                    <div className="search-detail-info-unique">
                      {isEditing ? (
                        <select
                          value={selectedProduct.availability}
                          onChange={(e) =>
                            setSelectedProduct({
                              ...selectedProduct,
                              availability: e.target.value,
                            })
                          }
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      ) : selectedProduct.availability ? (
                        "Yes"
                      ) : (
                        "No"
                      )}
                    </div>
                  </div>
                  <div className="search-detail-row-unique">
                    <div className="search-detail-label-unique">
                      Category Visibility:
                    </div>
                    <div className="search-detail-info-unique">
                      {isEditing ? (
                        <select
                          value={selectedProduct.categoryVisibility}
                          onChange={(e) =>
                            setSelectedProduct({
                              ...selectedProduct,
                              categoryVisibility: e.target.value,
                            })
                          }
                        >
                          <option value="None">None</option>
                          <option value="Romanian">Romanian</option>
                          <option value="Checked">Checked</option>
                          <option value="Stavv">Stavv</option>
                        </select>
                      ) : (
                        selectedProduct.categoryVisibility
                      )}
                    </div>
                  </div>
                  <div className="search-detail-row-unique">
                    <div className="search-detail-label-unique">
                      Attached Assets:
                    </div>
                  </div>
                  <div className="search-detail-row-unique">
                    <div className="search-asset-containers-row-unique">
                      {renderAttachedAssets(selectedProduct.attachedAssets)}
                    </div>
                  </div>
                </div>
                <div className="search-button-container-unique">
                  <div className="search-delete-button-unique">Delete</div>
                  <div
                    className="search-edit-update-button-unique"
                    onClick={toggleEditMode}
                  >
                    {isEditing ? "Done" : "Edit/Update"}
                  </div>
                </div>
              </>
            ) : (
              <div className="search-no-products-selected-unique">
                No products selected
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
