// src/components/NavBar.js

import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as MenuIcon } from "../icons/menu.svg";
import { ReactComponent as StarIcon } from "../icons/star.svg"; // Replace BagIcon with StarIcon
import { ReactComponent as FilledStarIcon } from "../icons/filledstar.svg"; // Optional: Filled star icon
import { ReactComponent as FranceIcon } from "../icons/france.svg";
import { ReactComponent as EnglandIcon } from "../icons/unitedkingdom.svg";
import { ReactComponent as BackIcon } from "../icons/cancel.svg";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountrySelector from "./CountrySelector";
import { db } from "../firebase-config";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { FavoritesContext } from "../context/FavoritesContext"; // Import FavoritesContext
import Favorites from "./Favorites";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [padding, setPadding] = useState("10px 20px");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [favoritesDrawerOpen, setFavoritesDrawerOpen] = useState(false); // Rename cartDrawerOpen to favoritesDrawerOpen
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const drawerRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [iconSize, setIconSize] = useState({
    height: window.innerWidth < 768 ? "14px" : "16px",
    width: window.innerWidth < 768 ? "14px" : "16px",
  });

  const { getFavoritesCount } = useContext(FavoritesContext); // Use FavoritesContext

  // Adjust padding and icon size based on window width
  useEffect(() => {
    const handleResize = () => {
      setPadding(window.innerWidth >= 768 ? "10px 40px" : "10px 20px");
      setIconSize({
        height: window.innerWidth < 768 ? "13px" : "16px",
        width: window.innerWidth < 768 ? "13px" : "16px",
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Close drawer when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawerOpen(false);
      }
    };

    if (drawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [drawerOpen]);

  // Set language based on localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage === "en" ? "en" : "fr");
    }
  }, [i18n]);

  const handleBrandClick = () => {
    if (location.pathname === "/products") {
      // Force a refresh of the products page
      window.location.reload();
    } else {
      // Navigate to the products page
      navigate("/products");
    }
  };

  // Fetch categories from Firestore sorted by createdAt descending
  useEffect(() => {
    setIsLoading(true);
    const q = query(collection(db, "categories"), orderBy("createdAt", "desc"));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const fetchedCategories = querySnapshot.docs.map((doc) => doc.id);
        setCategories(fetchedCategories);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching categories:", error);
        setStatusMessage("Failed to fetch categories.");
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleCategoryClick = (category) => {
    setDrawerOpen(false); // Close the drawer
    const categoryPath = `/${category.toLowerCase().replace(/\s+/g, "-")}`;
    navigate(categoryPath); // Use navigate for SPA navigation
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language === "en" ? "en" : "fr");
  };

  const styles = {
    navContainer: {
      background: "white",
      color: "black",
      padding: padding,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: window.innerWidth < 768 ? "30px" : "50px",
      position: "sticky",
      top: 0,
      zIndex: 100,
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      ...iconSize,
      cursor: "pointer",
    },
    brandTextContainer: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    brandText: {
      fontWeight: "bold",
      fontSize: "18px",
      fontFamily: "Didot Bold",
      color: "#000",
      opacity: "0.7",
      cursor: "pointer",
    },
    drawer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "250px",
      height: "100%",
      backgroundColor: "white",
      borderRight: "1px solid rgba(0, 0, 0, 0.1)",
      transform: drawerOpen ? "translateX(0)" : "translateX(-100%)",
      transition: "transform 0.3s ease",
      zIndex: 1001,
      padding: "20px",
    },
    drawerWide: {
      width: "350px",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: drawerOpen ? "rgba(0, 0, 0, 0.5)" : "transparent",
      transition: "background-color 0.3s ease",
      zIndex: 1000,
    },
    drawerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
    },
    drawerContent: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    link: {
      fontFamily: "Georgia",
      color: "#707070",
      opacity: 0.9,
      fontSize: "13px",
      marginTop: "10px",
      cursor: "pointer",
      textDecoration: "none", // Remove underline if desired
    },
    category: {
      fontFamily: "Georgia",
      opacity: 1,
      marginTop: "20px",
      fontWeight: "500",
      fontSize: "20px",
    },
    searchContainer: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #707070",
      paddingBottom: "4px",
      width: "calc(80% + 10px)",
    },
    categoryLink: {
      fontFamily: "Georgia",
      color: "#707070",
      opacity: 0.9,
      fontSize: "13px",
      marginTop: "10px",
      cursor: "pointer",
      paddingLeft: "20px", // Indentation for categories
    },
    searchInput: {
      border: "none",
      outline: "none",
      marginLeft: "8px",
      fontFamily: "Didot Bold",
      fontWeight: 100,
      fontSize: "14px",
      color: "#707070",
      background: "transparent",
      width: "100%",
    },
    favoritesCount: {
      backgroundColor: "#000",
      color: "white",
      borderRadius: "50%",
      width: "16px",
      height: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "-8px",
      right: "-8px",
      fontSize: "10px",
    },
  };

  return (
    <>
      <nav style={styles.navContainer}>
        <MenuIcon style={styles.icon} onClick={() => setDrawerOpen(true)} />
        <div style={styles.brandTextContainer}>
          <span style={styles.brandText} onClick={handleBrandClick}>
            MCKNIGHT
          </span>
        </div>
        <div style={styles.iconContainer}>
          <div style={{ position: "relative" }}>
            <StarIcon
              style={styles.icon}
              onClick={() => setFavoritesDrawerOpen(true)}
            />
            {getFavoritesCount() > 0 && (
              <div style={styles.favoritesCount}>{getFavoritesCount()}</div>
            )}
          </div>
          {i18n.language === "fr" ? (
            <FranceIcon
              style={{ ...styles.icon, marginLeft: "15px" }}
              onClick={() => setCountrySelectorOpen(true)}
            />
          ) : (
            <EnglandIcon
              style={{
                ...styles.icon,
                marginLeft: "15px",
              }}
              onClick={() => setCountrySelectorOpen(true)}
            />
          )}
        </div>
      </nav>
      {drawerOpen && (
        <div style={styles.overlay} onClick={() => setDrawerOpen(false)}></div>
      )}
      <div
        style={{
          ...styles.drawer,
          ...(window.innerWidth >= 768 ? styles.drawerWide : {}),
        }}
        ref={drawerRef}
      >
        <div style={styles.drawerHeader}>
          <div style={styles.searchContainer}>
            <SearchIcon style={styles.icon} />
            <input
              type="text"
              placeholder={t("Search")}
              style={styles.searchInput}
            />
          </div>
          <BackIcon style={styles.icon} onClick={() => setDrawerOpen(false)} />
        </div>
        <div style={styles.drawerContent}>
          <a href="/about-us" style={styles.link}>
            {t("About Us")}
          </a>
          <a href="/contact-us" style={styles.link}>
            {t("Contact Us")}
          </a>
          <div style={styles.category}>-{t("Categories")}-</div>
          {isLoading ? (
            <div className="spinner"></div> // Ensure you have spinner styles
          ) : (
            categories.map((category) => (
              <div
                style={styles.categoryLink}
                key={category}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </div>
            ))
          )}
        </div>
      </div>
      {/* Replace Cart with Favorites */}
      <Favorites
        drawerOpen={favoritesDrawerOpen}
        setDrawerOpen={setFavoritesDrawerOpen}
      />
      {countrySelectorOpen && (
        <CountrySelector
          onClose={() => setCountrySelectorOpen(false)}
          onLanguageChange={handleLanguageChange}
        />
      )}
      {statusMessage && <div className="status-message">{statusMessage}</div>}
    </>
  );
};

export default NavBar;
