// src/context/FavoritesContext.js

import React, { createContext, useState, useEffect } from "react";

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState(() => {
    const savedFavorites = localStorage.getItem("favorites");
    return savedFavorites ? JSON.parse(savedFavorites) : [];
  });

  useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);

  const toggleFavorite = (product) => {
    const isAlreadyFavorite = favorites.some((fav) => fav.id === product.id);

    if (isAlreadyFavorite) {
      // Remove from favorites
      setFavorites((prevFavorites) =>
        prevFavorites.filter((fav) => fav.id !== product.id)
      );
    } else {
      // Add to favorites
      // product may already include selectedForYou if provided from FullProduct.js
      setFavorites((prevFavorites) => [...prevFavorites, product]);
    }
  };

  const getFavoritesCount = () => {
    return favorites.length;
  };

  const value = {
    favorites,
    toggleFavorite,
    getFavoritesCount,
  };

  return (
    <FavoritesContext.Provider value={value}>
      {children}
    </FavoritesContext.Provider>
  );
};
