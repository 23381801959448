import React from "react";
import styles from "../style/Pagination.module.css";

const Pagination = ({ currentIndex, total, onClick, className }) => {
  return (
    <div className={`${styles.paginationContainer} ${className}`}>
      {Array.from({ length: total }).map((_, index) => (
        <div
          key={index}
          className={`${styles.paginationCircle} ${
            index === currentIndex ? styles.active : ""
          }`}
          onClick={() => onClick(index)}
        ></div>
      ))}
    </div>
  );
};

export default Pagination;
