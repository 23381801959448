import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18n";
import NavBar from "./components/NavBar";
import "./App.css";
import SocialLinks from "./components/SocialLinks";
import FooterDetails from "./components/FooterDetails";
import Home from "./components/Home";
import Products from "./components/Products";
import Item from "./components/Item";
import FullProduct from "./components/FullProduct"; // Import FullProduct
import AdminLogin from "./components/AdminLogin";
import AdminPanel from "./components/AdminPanel";
import ProductManagement from "./components/ProductManagement";
import SearchResults from "./components/SearchResults";
import AddProduct from "./components/AddProduct";
import SpecificProduct from "./components/SpecificProduct"; // Import SpecificProduct component
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { CartProvider } from "./context/CartContext";
import { LanguageProvider } from "./context/LanguageContext"; // Import LanguageProvider
import { FavoritesProvider } from "./context/FavoritesContext"; // Import FavoritesProvider

import Checkout from "./components/Checkout"; // Import the Checkout component
import FinishedOrders from "./components/FinishedOrders"; // Import FinishedOrders component
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import AboutUs from "./components/AboutUs"; // Import AboutUs component
import ContactUs from "./components/ContactUs"; // Import AboutUs component
import VideoComponent from "./components/VideoComponent"; // Import the VideoComponent
import { CurrencyProvider } from "./context/CurrencyContext"; // Import CurrencyProvider

function App() {
  return (
    <AuthProvider>
      <FavoritesProvider>
        <LanguageProvider>
          <CurrencyProvider>
            {" "}
            {/* Add CurrencyProvider here */}
            <I18nextProvider i18n={i18n}>
              <Router>
                <div>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <>
                          <NavBar />
                          <Home />
                          <SocialLinks />
                          <VideoComponent />

                          <FooterDetails />
                        </>
                      }
                    />
                    <Route
                      path="/full-product"
                      element={
                        <>
                          <NavBar />
                          <FullProduct /> {/* New FullProduct component */}
                          <SocialLinks />
                          <FooterDetails />
                        </>
                      }
                    />

                    <Route
                      path="/products"
                      element={
                        <>
                          <NavBar />
                          <Products />
                          <SocialLinks />
                          <FooterDetails />
                        </>
                      }
                    />
                    <Route
                      path="/item"
                      element={
                        <>
                          <NavBar />
                          <Item />
                          <SocialLinks />
                          <FooterDetails />
                        </>
                      }
                    />
                    <Route
                      path="/about-us"
                      element={
                        <>
                          {" "}
                          <NavBar />
                          <AboutUs /> <SocialLinks />
                          <FooterDetails />
                        </>
                      }
                    />
                    <Route
                      path="/contact-us"
                      element={
                        <>
                          {" "}
                          <NavBar />
                          <ContactUs /> <SocialLinks />
                          <FooterDetails />
                        </>
                      }
                    />

                    <Route
                      path="/checkout"
                      element={
                        <>
                          <Checkout />
                        </>
                      }
                    />
                    <Route path="/success" element={<Success />} />
                    <Route path="/cancel" element={<Cancel />} />

                    <Route path="/mc-admin" element={<AdminLogin />} />
                    <Route
                      path="/mc-admin/panel"
                      element={
                        <ProtectedRoute>
                          <AdminPanel />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/product-management"
                      element={<ProductManagement />}
                    />
                    <Route path="/search-results" element={<SearchResults />} />
                    <Route path="/add-product" element={<AddProduct />} />
                    <Route
                      path="/:category"
                      element={
                        <>
                          <NavBar />
                          <SpecificProduct />
                          <SocialLinks />
                          <FooterDetails />
                        </>
                      }
                    />
                    <Route
                      path="/finished-orders"
                      element={
                        <>
                          <FinishedOrders />
                        </>
                      }
                    />
                  </Routes>
                </div>
              </Router>
            </I18nextProvider>
          </CurrencyProvider>{" "}
          {/* Close CurrencyProvider */}
        </LanguageProvider>
      </FavoritesProvider>
    </AuthProvider>
  );
}

export default App;
