import React from "react";
import useLazyLoad from "../hooks/useLazyLoad";
import "../style/ProductDisplay.css";

const ProductDisplay = ({ product, isSelected, onSelect }) => {
  const [imageSrc, imgRef] = useLazyLoad(product.displayImages[0]);

  const handleClick = () => {
    if (onSelect) {
      onSelect(product);
    }
  };

  return (
    <div
      className={`product-display-container ${isSelected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <div className="image-container">
        {imageSrc ? (
          <img
            ref={imgRef}
            src={imageSrc}
            alt={product.name}
            className="product-image"
          />
        ) : (
          <div ref={imgRef} className="image-placeholder"></div>
        )}
      </div>
      <div className="product-info">
        <div className="product-name">{product.name}</div>
        <div className="product-family-name">
          Family Name: <span>{product.familyName}</span>
        </div>
        <div className="product-price">
          Price: <span>${product.price}</span>
        </div>
        <div className="product-sold">
          Sold: <span>{product.sold}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductDisplay;
