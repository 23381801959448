// src/context/CartContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

// Create the CartContext
const CartContext = createContext();

// Custom hook to use the CartContext
export const useCart = () => useContext(CartContext);

// CartProvider component
export const CartProvider = ({ children }) => {
  // Initialize cart state from localStorage or start with an empty array
  const [cart, setCart] = useState(() => {
    try {
      const savedCart = localStorage.getItem("cart");
      return savedCart ? JSON.parse(savedCart) : [];
    } catch (error) {
      console.error("An error occurred while loading the cart:", error);
      return [];
    }
  });

  // Initialize selectedForYou state from localStorage or start with an empty array
  const [selectedForYou, setSelectedForYou] = useState(() => {
    try {
      const saved = localStorage.getItem("selectedForYou");
      return saved ? JSON.parse(saved) : [];
    } catch (error) {
      console.error("Failed to parse selectedForYou from localStorage:", error);
      return [];
    }
  });

  // Effect to update localStorage whenever the cart changes
  useEffect(() => {
    try {
      localStorage.setItem("cart", JSON.stringify(cart));
    } catch (error) {
      console.error("Failed to save cart to localStorage:", error);
    }
  }, [cart]);

  // Effect to update localStorage whenever the selectedForYou changes
  useEffect(() => {
    try {
      localStorage.setItem("selectedForYou", JSON.stringify(selectedForYou));
    } catch (error) {
      console.error("Failed to save selectedForYou to localStorage:", error);
    }
  }, [selectedForYou]);

  // Function to add an item to the cart
  const addToCart = (item) => {
    setCart((prevCart) => [...prevCart, item]);
  };

  // Function to remove an item from the cart by index
  const removeFromCart = (index) => {
    setCart((prevCart) => prevCart.filter((_, i) => i !== index));
  };

  // **Function to clear the entire cart**
  const clearCart = () => {
    setCart([]); // Reset cart state to empty array
  };

  // Function to get the number of items in the cart
  const getCartCount = () => {
    return cart.length;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart, // Expose clearCart to context consumers
        getCartCount,
        selectedForYou,
        setSelectedForYou,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
