import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext
import { db } from "../firebase-config"; // Firebase config
import { doc, getDoc } from "firebase/firestore"; // Firestore functions
import "../style/Admin.css";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Use the login function from AuthContext

  // Fetch the stored email and password from Firestore
  const fetchStoredCredentials = async () => {
    try {
      const credentialsDoc = await getDoc(
        doc(db, "AdminSettings", "credentials")
      );
      if (credentialsDoc.exists()) {
        return credentialsDoc.data(); // This will return the email and currentPassword fields
      } else {
        console.error("No credentials found in Firestore.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching credentials from Firestore:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch stored email and password from Firestore
    const storedCredentials = await fetchStoredCredentials();

    if (!storedCredentials) {
      setError("Credentials not set up in Firestore.");
      return;
    }

    // Check if the entered email and password match the Firestore values
    if (
      email === storedCredentials.email &&
      password === storedCredentials.currentPassword
    ) {
      login(); // Set the user as authenticated
      navigate("/mc-admin/panel");
    } else {
      setError("Invalid email or password.");
    }
  };

  return (
    <div className="admin-container">
      <nav
        style={{
          background: "white",
          color: "black",
          padding: "10px 40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50px",
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 1000,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            fontFamily: "Didot Bold",
            color: "#000",
            opacity: "0.7",
          }}
        >
          MCKNIGHT
        </span>
      </nav>
      <div className="login-form-container">
        <h2 className="login-header">Admin Login</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
