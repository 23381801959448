// src/context/CurrencyContext.js

import React, { createContext, useState, useEffect } from "react";
import { db } from "../firebase-config";
import { doc, onSnapshot } from "firebase/firestore";

export const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("EUR"); // Default currency
  const [exchangeRates, setExchangeRates] = useState({
    EUR: 1,
    USD: 0.92,
    GBP: 0.94,
  });

  useEffect(() => {
    // Listen to real-time updates in the FX/rates document
    const ratesDocRef = doc(db, "FX", "rates");
    const unsubscribe = onSnapshot(
      ratesDocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setExchangeRates({
            EUR: 1,
            USD: data.euroToDollar || 0.92,
            GBP: data.euroToPound || 0.94,
          });
        } else {
          console.warn("No FX rates found, using default rates.");
        }
      },
      (error) => {
        console.error("Error fetching FX rates:", error);
      }
    );

    return () => unsubscribe();
  }, []);

  // Persist selected currency in localStorage
  useEffect(() => {
    const savedCurrency = localStorage.getItem("currency");
    if (savedCurrency && ["EUR", "USD", "GBP"].includes(savedCurrency)) {
      setCurrency(savedCurrency);
    }
  }, []);

  const changeCurrency = (newCurrency) => {
    if (["EUR", "USD", "GBP"].includes(newCurrency)) {
      setCurrency(newCurrency);
      localStorage.setItem("currency", newCurrency);
    }
  };

  return (
    <CurrencyContext.Provider
      value={{ currency, exchangeRates, changeCurrency }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
