// UserManagement.js
import React, { useState, useEffect, useMemo } from "react";
import "../style/UserManagement.css"; // Import CSS for styling
import { collection, getDocs, query, orderBy } from "firebase/firestore"; // Firestore functions
import { db } from "../firebase-config"; // Import Firebase configuration

const UserManagement = () => {
  const [sortOrder, setSortOrder] = useState("desc"); // Default sort order is descending
  const [sortOption, setSortOption] = useState("Creation Date"); // Default sort option
  const [entries, setEntries] = useState([]); // State to store fetched user entries
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage errors

  // Function to format date strings (DD/MM/YYYY)
  const formatDateHere = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return dateString; // Return the original string if invalid date
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch users from 'users' collection
        const usersCollectionRef = collection(db, "users"); // Reference to 'users' collection
        const usersSnapshot = await getDocs(usersCollectionRef); // Fetch documents

        // Map each document to the desired structure
        const usersData = usersSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            uid: doc.id,
            customerName: data.customerName || "N/A",
            clientCode: data.clientCode || "N/A",
            customerPhone: data.customerPhone || "N/A",
            customerAddress: data.customerAddress || "N/A",
            creationTime: data.creationTime || data.createdAt || "", // Fetch creation time
            totalMoneySpent: 0, // Will calculate later
          };
        });

        // Fetch orders from 'Orders' collection
        const ordersCollectionRef = collection(db, "Orders");
        const ordersSnapshot = await getDocs(ordersCollectionRef);

        // Map orders by clientCode
        const ordersData = ordersSnapshot.docs.map((doc) => doc.data());
        const ordersByClientCode = {};
        ordersData.forEach((order) => {
          const clientCode = order.clientCode;
          if (clientCode) {
            if (!ordersByClientCode[clientCode]) {
              ordersByClientCode[clientCode] = [];
            }
            ordersByClientCode[clientCode].push(order);
          }
        });

        // Calculate total money spent for each user
        const updatedUsersData = usersData.map((user) => {
          const clientCode = user.clientCode;
          const userOrders = ordersByClientCode[clientCode] || [];
          let totalSpent = 0;
          userOrders.forEach((order) => {
            // Sum up the prices of products in the order
            const products = order.products || [];
            products.forEach((product) => {
              totalSpent += parseFloat(product.price) || 0;
            });
          });
          return {
            ...user,
            totalMoneySpent: totalSpent,
          };
        });

        setEntries(updatedUsersData); // Update state with fetched data
        setLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        console.error("Error fetching users or orders:", err);
        setError("Failed to fetch users or orders.");
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchData();
  }, []);

  // Sorting function
  const sortedEntries = useMemo(() => {
    return [...entries].sort((a, b) => {
      if (sortOption === "Amount Spent") {
        return sortOrder === "asc"
          ? a.totalMoneySpent - b.totalMoneySpent
          : b.totalMoneySpent - a.totalMoneySpent;
      } else if (sortOption === "Creation Date") {
        const dateA = new Date(a.creationTime);
        const dateB = new Date(b.creationTime);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        return 0;
      }
    });
  }, [entries, sortOption, sortOrder]);

  // Handler to toggle sort order
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSortOptionChange = (event) => {
    setSortOption(event.target.value);
  };

  if (loading) {
    return <div className="loading">Loading users...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="user-management-container">
      {/* Header Section */}
      <div className="header">
        <div className="date">{new Date().toLocaleDateString()}</div>
        <div className="sort-container">
          <span>Sort by: </span>
          <select
            value={sortOption}
            onChange={handleSortOptionChange}
            className="sort-dropdown"
          >
            <option value="Creation Date">Creation Date</option>
            <option value="Amount Spent">Amount Spent</option>
          </select>
          <span
            className="sort-option"
            onClick={toggleSortOrder}
            style={{ cursor: "pointer", color: "#007bff", marginLeft: "10px" }}
          >
            ({sortOrder === "asc" ? "Ascending" : "Descending"})
          </span>
        </div>
      </div>

      {/* Title Section */}
      <div className="title-section">
        <div className="user-management-title">User Management</div>
      </div>

      {/* Users Table */}
      <div className="users-table">
        {/* Table Headers */}
        <div className="users-header">
          <div className="users-header-cell">User Info</div>
          <div className="users-header-cell">Client Code</div>
          <div className="users-header-cell">Phone Number</div>
          <div className="users-header-cell">Address</div>
          <div className="users-header-cell">Creation Date</div>
          <div className="users-header-cell">Total Money Spent</div>
        </div>

        {/* Table Rows */}
        {sortedEntries.map((entry, index) => (
          <div className="users-row" key={index}>
            <div className="user-cell">{entry.customerName}</div>
            <div className="user-cell">{entry.clientCode}</div>
            <div className="user-cell">{entry.customerPhone}</div>
            <div className="user-cell">{entry.customerAddress}</div>
            <div className="user-cell">
              {entry.creationTime ? formatDateHere(entry.creationTime) : "N/A"}
            </div>
            <div className="user-cell">
              €{entry.totalMoneySpent.toFixed(2).toLocaleString()}
            </div>
          </div>
        ))}

        {/* Handle Case When No Users Are Available */}
        {sortedEntries.length === 0 && (
          <div className="no-users">No users found.</div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
