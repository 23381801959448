// FinishedOrders.js
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as BackIcon } from "../icons/back.svg";
import { collection, getDocs } from "firebase/firestore"; // Firestore functions
import { db } from "../firebase-config"; // Import Firebase configuration
import styles from "../style/FinishedOrders.module.css"; // Import the CSS module

const FinishedOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [finishedOrders, setFinishedOrders] = useState([]);

  // Fetch finished orders from Firestore
  useEffect(() => {
    const fetchFinishedOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "SentOutOrders"));
        const fetchedOrders = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          orderId: doc.id, // Use document ID as orderId
        }));

        setFinishedOrders(fetchedOrders); // Store the fetched orders in state
      } catch (error) {
        console.error("Error fetching finished orders:", error);
      }
    };

    fetchFinishedOrders();
  }, []);

  // Handle back button click
  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from, {
        state: { activeComponent: location.state.activeComponent },
      });
    } else {
      navigate("/mc-admin/panel", {
        state: { activeComponent: "viewOrders" },
      });
    }
  };

  return (
    <div className={styles.finishedOrdersContainer}>
      {/* Back Button */}
      <div className={styles.backAndTitleContainer}>
        <div className={styles.backButton} onClick={handleBackClick}>
          <BackIcon className={styles.backIcon} />
          <span>Back</span>
        </div>
      </div>

      <h2>Finished Orders</h2>

      <div className={styles.ordersTable}>
        {finishedOrders.length > 0 ? (
          finishedOrders.map((order) => (
            <div key={order.orderId} className={styles.orderRow}>
              <div>
                <strong>Order ID:</strong> {order.orderId}
              </div>
              <div>
                <strong>Product Name:</strong> {order.productName}
              </div>
              <div>
                <strong>Price:</strong> {order.price}
              </div>
              <div>
                <strong>Customer Name:</strong> {order.customerName}
              </div>
              <div>
                <strong>Customer Email:</strong> {order.customerEmail}
              </div>
              <div>
                <strong>Test Outfit Details:</strong> {order.testOutfitDetails}
              </div>
              <div>
                <strong>Expected Final Delivery Date:</strong>{" "}
                {order.expectedFinalDeliveryDate}
              </div>
              <div>
                <strong>Expected Delivery Date to Client:</strong>{" "}
                {order.expectedDeliveryDateToClient}
              </div>
              <div>
                <strong>Customizations:</strong>
                {/* **Dynamic Customizations Per Product** */}
                {order.productCustomizations &&
                order.productCustomizations.length > 0 ? (
                  <ul className={styles.customizationsList}>
                    {order.productCustomizations.map((item, idx) => (
                      <li key={idx} className={styles.customizationItem}>
                        <strong>{item.productName}:</strong>{" "}
                        {item.customizations}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No customizations available.</p>
                )}
              </div>
              <div>
                <strong>Final Delivery Status:</strong> {order.finalDelivery}
              </div>
            </div>
          ))
        ) : (
          <p>No finished orders available.</p>
        )}
      </div>
    </div>
  );
};

export default FinishedOrders;
