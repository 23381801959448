import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as BackIcon } from "../icons/back.svg";
import { SketchPicker } from "react-color";
import "../style/AddProduct.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { db, storage } from "../firebase-config";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  query,
  where,
  getDocs,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";

import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const animatedComponents = makeAnimated();

const newClientOptions = [
  { value: "Top", label: "Top" },
  { value: "TrouserWaist", label: "Trouser waist" },
  { value: "CapSize", label: "Cap size" },
  { value: "Height", label: "Height" },
  { value: "ShoeSize", label: "Shoe size" },
  { value: "UniqueSize", label: "Unique Size" },
];

const optionsTop = [
  { value: "ALL", label: "All" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
  { value: "XXXL", label: "XXXL" },
];

const optionsTrouserWaist = [
  { value: "ALL", label: "All" },
  { value: "EUR 30", label: "EUR 30" },
  { value: "EUR 32", label: "EUR 32" },
  { value: "EUR 34", label: "EUR 34" },
  { value: "EUR 36", label: "EUR 36" },
  { value: "EUR 38", label: "EUR 38" },
  { value: "EUR 40", label: "EUR 40" },
  { value: "EUR 42", label: "EUR 42" },
  { value: "EUR 44", label: "EUR 44" },
  { value: "EUR 46", label: "EUR 46" },
  { value: "EUR 48", label: "EUR 48" },
  { value: "EUR 50", label: "EUR 50" },
  { value: "EUR 52", label: "EUR 52" },
  { value: "EUR 54", label: "EUR 54" },
  { value: "EUR 56", label: "EUR 56" },
  { value: "EUR 58", label: "EUR 58" },
  { value: "EUR 60", label: "EUR 60" },
];

const optionsHeight = [
  { value: "ALL", label: "All" },
  { value: "1m60 | 5ft3", label: "1m60 | 5ft3" },
  { value: "1m61", label: "1m61" },
  { value: "1m62", label: "1m62" },
  { value: "1m63 | 5ft4", label: "1m63 | 5ft4" },
  { value: "1m64", label: "1m64" },
  { value: "1m65", label: "1m65" },
  { value: "1m66 | 5ft5", label: "1m66 | 5ft5" },
  { value: "1m67", label: "1m67" },
  { value: "1m68 | 5ft6", label: "1m68 | 5ft6" },
  { value: "1m69 | 5ft6", label: "1m69 | 5ft6" },
  { value: "1m70 | 5ft6", label: "1m70 | 5ft6" },
  { value: "1m71 | 5ft7", label: "1m71 | 5ft7" },
  { value: "1m72 | 5ft7", label: "1m72 | 5ft7" },
  { value: "1m73 | 5ft8", label: "1m73 | 5ft8" },
  { value: "1m74 | 5ft8", label: "1m74 | 5ft8" },
  { value: "1m75 | 5ft8", label: "1m75 | 5ft8" },
  { value: "1m76 | 5ft9", label: "1m76 | 5ft9" },
  { value: "1m77 | 5ft9", label: "1m77 | 5ft9" },
  { value: "1m78 | 5ft10", label: "1m78 | 5ft10" },
  { value: "1m79 | 5ft10", label: "1m79 | 5ft10" },
  { value: "1m80 | 5ft10", label: "1m80 | 5ft10" },
  { value: "1m81 | 5ft11", label: "1m81 | 5ft11" },
  { value: "1m82 | 5ft11", label: "1m82 | 5ft11" },
  { value: "1m83 | 6ft", label: "1m83 | 6ft" },
  { value: "1m84 | 6ft", label: "1m84 | 6ft" },
  { value: "1m85 | 6ft", label: "1m85 | 6ft" },
  { value: "1m86 | 6ft1", label: "1m86 | 6ft1" },
  { value: "1m87 | 6ft1", label: "1m87 | 6ft1" },
  { value: "1m88 | 6ft2", label: "1m88 | 6ft2" },
  { value: "1m89 | 6ft2", label: "1m89 | 6ft2" },
  { value: "1m90 | 6ft2", label: "1m90 | 6ft2" },
  { value: "1m91 | 6ft3", label: "1m91 | 6ft3" },
  { value: "1m92 | 6ft3", label: "1m92 | 6ft3" },
  { value: "1m93 | 6ft3", label: "1m93 | 6ft3" },
  { value: "1m94 | 6ft4", label: "1m94 | 6ft4" },
  { value: "1m95", label: "1m95" },
  { value: "1m96", label: "1m96" },
  { value: "1m97", label: "1m97" },
  { value: "1m98", label: "1m98" },
  { value: "1m99", label: "1m99" },
  { value: "2m00", label: "2m00" },
  { value: "2m01", label: "2m01" },
  { value: "2m02", label: "2m02" },
  { value: "2m03", label: "2m03" },
  { value: "2m04", label: "2m04" },
  { value: "2m05", label: "2m05" },
  { value: "2m06", label: "2m06" },
  { value: "2m07", label: "2m07" },
  { value: "2m08", label: "2m08" },
  { value: "2m09", label: "2m09" },
  { value: "2m10", label: "2m10" },
];

const optionsCapSize = [
  { value: "ALL", label: "All" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
];

const optionsShoeSize = [
  { value: "ALL", label: "All" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    maxWidth: "100%",
  }),
  control: (provided) => ({
    ...provided,
    maxWidth: "100%",
    minHeight: "38px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "wrap",
    overflowY: "auto",
  }),
  multiValue: (provided) => ({
    ...provided,
    maxWidth: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "10px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "10px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#aaa",
      color: "white",
    },
  }),
  menu: (provided) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
};

const AddProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [displayImages, setDisplayImages] = useState([]);
  const [isUpdateValid, setIsUpdateValid] = useState(false);
  const [colorFrenchNames, setColorFrenchNames] = useState({});

  const [selectedColors, setSelectedColors] = useState([]);
  const [colorDialogVisible, setColorDialogVisible] = useState(false);
  const [currentColor, setCurrentColor] = useState("#fff");
  const [colorName, setColorName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [priceInputsVisible, setPriceInputsVisible] = useState(false);
  const [colorPrices, setColorPrices] = useState({});
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSleeveLengths, setSelectedSleeveLengths] = useState([]);
  const [sleeveLengthPrices, setSleeveLengthPrices] = useState({});
  const [sleeveLengthInputsVisible, setSleeveLengthInputsVisible] =
    useState(false);
  const [selectedTrouserLengths, setSelectedTrouserLengths] = useState([]);
  const [trouserLengthPrices, setTrouserLengthPrices] = useState({});
  const [selectedCapSizes, setSelectedCapSizes] = useState([]);
  const [capSizePrices, setCapSizePrices] = useState({});
  const [capSizeInputsVisible, setCapSizeInputsVisible] = useState(false);
  const [selectedHeights, setSelectedHeights] = useState([]);
  const [heightPrices, setHeightPrices] = useState({});
  const [heightInputsVisible, setHeightInputsVisible] = useState(false);
  const [productionDays, setProductionDays] = useState(0);
  const [testOutfitDays, setTestOutfitDays] = useState(0);
  const [newClientDeliveryDate, setNewClientDeliveryDate] = useState(0);
  const [oldClientDeliveryDate, setOldClientDeliveryDate] = useState(0);
  const [designersNotes, setDesignersNotes] = useState("");
  const [remoteBespoke, setRemoteBespoke] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState("");
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categoryError, setCategoryError] = useState(null);
  const [newClientSections, setNewClientSections] = useState([]);
  const [isNewClientDropdownOpen, setIsNewClientDropdownOpen] = useState(false);
  const newClientDropdownRef = useRef(null);
  const productData = location.state?.product || {};
  const [sections, setSections] = useState([]);
  const [isColorButtonVisible, setIsColorButtonVisible] = useState(true);
  const [selectedTopSizes, setSelectedTopSizes] = useState([]);
  const [availableOptions, setAvailableOptions] = useState(newClientOptions);
  const [selectedTrouserWaistSizes, setSelectedTrouserWaistSizes] = useState(
    []
  );
  const [selectedShoeSizes, setSelectedShoeSizes] = useState([]);
  const [familyName, setFamilyName] = useState("");
  const [newInToggle, setNewInToggle] = useState(false);
  const [displayTestOutfitToggle, setDisplayTestOutfitToggle] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialProductData, setInitialProductData] = useState(null);

  // New visibility state
  const [visibility, setVisibility] = useState("All");

  const handleShoeSizeChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "ALL")) {
      setSelectedShoeSizes(
        optionsShoeSize.filter((option) => option.value !== "ALL")
      );
    } else {
      setSelectedShoeSizes(selectedOptions);
    }
  };

  const handleHeightChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "ALL")) {
      setSelectedHeights(
        optionsHeight.filter((option) => option.value !== "ALL")
      );
    } else {
      setSelectedHeights(selectedOptions);
    }
  };

  const handleTopSizesChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "ALL")) {
      setSelectedTopSizes(
        optionsTop.filter((option) => option.value !== "ALL")
      );
    } else {
      setSelectedTopSizes(selectedOptions);
    }
  };

  const handleCapSizesChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "ALL")) {
      setSelectedCapSizes(
        optionsCapSize.filter((option) => option.value !== "ALL")
      );
    } else {
      setSelectedCapSizes(selectedOptions);
    }
  };

  const handleTrouserWaistSizesChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "ALL")) {
      setSelectedTrouserWaistSizes(
        optionsTrouserWaist.filter((option) => option.value !== "ALL")
      );
    } else {
      setSelectedTrouserWaistSizes(selectedOptions);
    }
  };

  const handleNewClientSectionAdd = (section) => {
    if (!newClientSections.includes(section)) {
      setNewClientSections([...newClientSections, section]);

      setAvailableOptions(
        availableOptions.filter((option) => option.value !== section)
      );
    }
    setIsNewClientDropdownOpen(false);
  };

  const handleAddNewSection = () => {
    const newSection = {
      subsections: [{ subSection: "", price: "" }],
    };
    setSections([...sections, newSection]);
  };

  const handleSubsectionChange = (sectionIndex, subIndex, field, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].subsections[subIndex][field] = value;
    setSections(updatedSections);
  };

  const handleAddSubsection = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].subsections.push({
      subSection: "",
      price: "",
    });
    setSections(updatedSections);
  };

  const handleSectionNameChange = (index, newName, newFrenchName) => {
    const updatedSections = [...sections];
    updatedSections[index].sectionName = newName;
    updatedSections[index].sectionNameFrench =
      newFrenchName || updatedSections[index].sectionNameFrench;
    setSections(updatedSections);
  };

  const optionsSleeveLength = [
    { value: "NA", label: "N/A" },
    { value: "ALL", label: "ALL" },
    { value: "As seen in the picture", label: "As seen in the picture" },
    { value: "Sleeveless", label: "Sleeveless" },
    { value: "Short Sleeve", label: "Short Sleeve" },
    { value: "Elbow Sleeve", label: "Elbow Sleeve" },
    { value: "3 - Quarter Sleeve", label: "3 - Quarter Sleeve" },
    { value: "Long Sleeve", label: "Long Sleeve" },
  ];

  const handleSectionAdd = (section) => {
    if (section === "Color" && !selectedSections.includes("Color")) {
      setSelectedSections([...selectedSections, "Color"]);
      setIsColorButtonVisible(false);
    }
  };

  const toggleNewClientDropdown = () => {
    setIsNewClientDropdownOpen(!isNewClientDropdownOpen);
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from, {
        state: { activeComponent: location.state.activeComponent },
      });
    } else {
      navigate("/mc-admin/panel", {
        state: { activeComponent: "productManagement" },
      });
    }
  };
  const handleSleeveLengthDropdownClick = () => {
    const orderedPrices = {};
    selectedSleeveLengths.forEach((length) => {
      orderedPrices[length.value] = sleeveLengthPrices[length.value] || "";
    });
    setSleeveLengthPrices(orderedPrices);
    setSleeveLengthInputsVisible(true);
  };

  const handleSleeveLengthPriceChange = (lengthValue, price) => {
    setSleeveLengthPrices({
      ...sleeveLengthPrices,
      [lengthValue]: price,
    });
  };

  const handleSleeveLengthRemove = (length) => {
    const updatedLengths = selectedSleeveLengths.filter(
      (l) => l.value !== length.value
    );
    setSelectedSleeveLengths(updatedLengths);

    const updatedPrices = { ...sleeveLengthPrices };
    delete updatedPrices[length.value];
    setSleeveLengthPrices(updatedPrices);
  };
  const sleeveLengthPriceLabel = `Price + (${
    Object.values(sleeveLengthPrices).join(", ") || "0"
  })`;
  const handleProductionDaysChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/, "");
    value = value.replace(/^0+(?=\d)/, "");
    const intValue = parseInt(value, 10) || 0;
    setProductionDays(intValue);
    setOldClientDeliveryDate(intValue);
    setNewClientDeliveryDate(intValue + (parseInt(testOutfitDays, 10) || 0));
  };

  const handleTestOutfitDaysChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/, "");
    value = value.replace(/^0+(?=\d)/, "");
    const intValue = parseInt(value, 10) || 0;
    setTestOutfitDays(intValue);
    setNewClientDeliveryDate((parseInt(productionDays, 10) || 0) + intValue);
  };
  const handleCapSizeDropdownClick = () => {
    const orderedPrices = {};
    selectedCapSizes.forEach((size) => {
      orderedPrices[size.value] = capSizePrices[size.value] || "";
    });
    setCapSizePrices(orderedPrices);
    setCapSizeInputsVisible(true);
  };

  const handleCapSizePriceChange = (sizeValue, price) => {
    setCapSizePrices({
      ...capSizePrices,
      [sizeValue]: price,
    });
  };

  const handleCapSizeRemove = (size) => {
    const updatedSizes = selectedCapSizes.filter((s) => s.value !== size.value);
    setSelectedCapSizes(updatedSizes);

    const updatedPrices = { ...capSizePrices };
    delete updatedPrices[size.value];
    setCapSizePrices(updatedPrices);
  };

  const capSizePriceLabel = `Price + (${
    Object.values(capSizePrices).join(", ") || "0"
  })`;

  const handleHeightDropdownClick = () => {
    const orderedPrices = {};
    selectedHeights.forEach((height) => {
      orderedPrices[height.value] = heightPrices[height.value] || "";
    });
    setHeightPrices(orderedPrices);
    setHeightInputsVisible(true);
  };

  const handleHeightPriceChange = (heightValue, price) => {
    setHeightPrices({
      ...heightPrices,
      [heightValue]: price,
    });
  };

  const handleHeightRemove = (height) => {
    const updatedHeights = selectedHeights.filter(
      (h) => h.value !== height.value
    );
    setSelectedHeights(updatedHeights);

    const updatedPrices = { ...heightPrices };
    delete updatedPrices[height.value];
    setHeightPrices(updatedPrices);
  };

  const heightPriceLabel = `Price + (${
    Object.values(heightPrices).join(", ") || "0"
  })`;

  const handlePriceDropdownClick = () => {
    const orderedPrices = {};
    selectedColors.forEach((color) => {
      orderedPrices[color.hex] = colorPrices[color.hex] || "";
    });

    setColorPrices(orderedPrices);
    setPriceInputsVisible(true);
  };

  const handlePriceChange = (colorHex, price) => {
    setColorPrices({
      ...colorPrices,
      [colorHex]: price,
    });
  };

  const handlePriceOkayClick = () => {
    setPriceInputsVisible(false);
  };

  const priceLabel = `Price (${Object.values(colorPrices).join(", ") || "0"})`;

  const handleRemoveDisplayImage = (index) => {
    const newImages = displayImages.filter((_, idx) => idx !== index);
    setDisplayImages(newImages);
  };

  const handleAddDisplayFiles = (event) => {
    const files = event.target.files;
    const newImages = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        newImages.push(e.target.result);
        if (newImages.length === files.length) {
          setDisplayImages((prevImages) => [...prevImages, ...newImages]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleColorDialog = () => {
    setColorDialogVisible(!colorDialogVisible);
  };

  const handleColorChange = (color) => {
    setCurrentColor(color.hex);
  };

  const handleColorSelect = () => {
    if (
      selectedColors.length < 3 &&
      !selectedColors.some((c) => c.hex === currentColor)
    ) {
      setSelectedColors([
        ...selectedColors,
        { hex: currentColor, name: colorName || currentColor },
      ]);
    }
    setColorDialogVisible(false);
    setColorName("");
  };

  const handleColorRemove = (color) => {
    const updatedColors = selectedColors.filter((c) => c.hex !== color.hex);
    setSelectedColors(updatedColors);

    const updatedPrices = { ...colorPrices };
    delete updatedPrices[color.hex];
    setColorPrices(updatedPrices);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        newClientDropdownRef.current &&
        !newClientDropdownRef.current.contains(event.target)
      ) {
        setIsNewClientDropdownOpen(false);
      }
    }

    if (isNewClientDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNewClientDropdownOpen]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoryList = querySnapshot.docs.map((doc) => ({
          value: doc.id,
          label: doc.id,
        }));

        setCategories(categoryList);

        if (productData?.categories?.length > 0) {
          const visibilities = productData.categories.map((c) => c.visibility);
          const uniqueVisibilities = [...new Set(visibilities)];
          if (uniqueVisibilities.length === 1) {
            setVisibility(uniqueVisibilities[0]);
          } else {
            setVisibility("All"); // Default to 'All' if multiple visibilities
          }
        }

        if (categoryList.length > 0) {
          setSelectedCategories(
            productData?.categories?.map((cat) => ({
              value: cat.category,
              label: cat.category,
            })) || []
          );
        }
      } catch (error) {
        setCategoryError("Failed to load categories. Please try again.");
        console.error("Error fetching categories:", error);
      } finally {
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (location.state?.product) {
      const productData = location.state.product;
      console.log("Retrieved product data:", productData);

      setIsEditing(true);
      setInitialProductData(productData);

      const allTopSizes = optionsTop.map((option) => option.label);
      if (
        allTopSizes.every((size) =>
          productData.availableMeasurements.top.includes(size)
        )
      ) {
        setSelectedTopSizes([{ value: "ALL", label: "ALL" }]);
      } else {
        setSelectedTopSizes(
          productData.availableMeasurements.top.map((size) => ({
            value: size,
            label: size,
          }))
        );
      }

      const allTrouserWaistSizes = optionsTrouserWaist.map(
        (option) => option.label
      );
      if (
        allTrouserWaistSizes.every((size) =>
          productData.availableMeasurements.trouserWaist.includes(size)
        )
      ) {
        setSelectedTrouserWaistSizes([{ value: "ALL", label: "ALL" }]);
      } else {
        setSelectedTrouserWaistSizes(
          productData.availableMeasurements.trouserWaist.map((size) => ({
            value: size,
            label: size,
          }))
        );
      }

      const allCapSizes = optionsCapSize.map((option) => option.label);
      if (
        allCapSizes.every((size) =>
          productData.availableMeasurements.capSize.includes(size)
        )
      ) {
        setSelectedCapSizes([{ value: "ALL", label: "ALL" }]);
      } else {
        setSelectedCapSizes(
          productData.availableMeasurements.capSize.map((size) => ({
            value: size,
            label: size,
          }))
        );
      }

      const allHeights = optionsHeight.map((option) => option.label);
      if (
        allHeights.every((height) =>
          productData.availableMeasurements.height.includes(height)
        )
      ) {
        setSelectedHeights([{ value: "ALL", label: "ALL" }]);
      } else {
        setSelectedHeights(
          productData.availableMeasurements.height.map((height) => ({
            value: height,
            label: height,
          }))
        );
      }

      const allShoeSizes = optionsShoeSize.map((option) => option.label);
      if (
        allShoeSizes.every((size) =>
          productData.availableMeasurements.shoeSize.includes(size)
        )
      ) {
        setSelectedShoeSizes([{ value: "ALL", label: "ALL" }]);
      } else {
        setSelectedShoeSizes(
          productData.availableMeasurements.shoeSize.map((size) => ({
            value: size,
            label: size,
          }))
        );
      }

      setNewInToggle(productData.newIn || false);
      setDisplayTestOutfitToggle(productData.displayTestOutfit || false);

      setProductName(productData.name || "");
      setProductPrice(productData.price || "");
      setFamilyName(productData.familyName || "");
      setDesignersNotes(productData.designersNotes || "");
      setRemoteBespoke(productData.remoteBespoke || "");
      setDeliveryOptions(productData.deliveryOptions || "");
      setDisplayImages(productData.displayImages || []);
      setProductionDays(productData.productionDays || 0);
      setTestOutfitDays(productData.testOutfitDays || 0);

      const retrievedSections = productData.sections || [];

      const updatedSections = retrievedSections.map((section) => ({
        sectionName: section.sectionName,
        sectionNameFrench: section.sectionNameFrench || "",
        subsections: section.subsections.map((subsection) => ({
          subSection: subsection.name,
          subSectionFrench: subsection.nameFrench || "",
          price: subsection.price,
        })),
      }));

      setSections(updatedSections);

      const colors = productData.colors || [];

      const colorPriceMap = {};
      const frenchNameMap = {};

      colors.forEach((color) => {
        colorPriceMap[color.hex] = color.price || "";
        frenchNameMap[color.hex] = color.frenchName || "";
      });

      setSelectedColors(colors);
      setColorPrices(colorPriceMap);
      setColorFrenchNames(frenchNameMap);

      if (colors.length > 0) {
        setSelectedSections((prevSections) =>
          prevSections.includes("Color")
            ? prevSections
            : [...prevSections, "Color"]
        );
      }

      colors.forEach((color) => {
        colorPriceMap[color.hex] = color.price || "";
      });

      setSelectedColors(colors);
      setColorPrices(colorPriceMap);

      const availableMeasurements = productData.availableMeasurements || {};

      setSelectedSleeveLengths(availableMeasurements.sleeveLength || []);
      setSleeveLengthPrices(productData.sleeveLengthPrices || {});

      setSelectedTrouserLengths(availableMeasurements.trouserLengths || []);
      setTrouserLengthPrices(productData.trouserLengthPrices || {});

      setSelectedCapSizes(availableMeasurements.capSize || []);
      setCapSizePrices(productData.capSizePrices || {});

      setSelectedHeights(availableMeasurements.height || []);
      setHeightPrices(productData.heightPrices || {});

      setSelectedTopSizes(availableMeasurements.top || []);

      const newClientSections = [];
      if (availableMeasurements.top?.length > 0) {
        newClientSections.push("Top");
      }
      if (availableMeasurements.trouserWaist?.length > 0) {
        setSelectedTrouserWaistSizes(availableMeasurements.trouserWaist);
        newClientSections.push("TrouserWaist");
      }
      if (availableMeasurements.capSize?.length > 0) {
        newClientSections.push("CapSize");
      }
      if (availableMeasurements.shoeSize?.length > 0) {
        setSelectedShoeSizes(availableMeasurements.shoeSize);
        newClientSections.push("ShoeSize");
      }
      if (availableMeasurements.height?.length > 0) {
        newClientSections.push("Height");
      }
      if (availableMeasurements.uniqueSize?.length > 0) {
        newClientSections.push("UniqueSize");
      }

      setNewClientSections(newClientSections);

      setAvailableOptions(
        newClientOptions.filter(
          (option) => !newClientSections.includes(option.value)
        )
      );

      setTimeout(() => {
        console.log("Final product state set:", {
          productName,
          productPrice,
          familyName,
          selectedCategories,
          designersNotes,
          remoteBespoke,
          deliveryOptions,
          selectedColors,
          colorPrices,
          sections,
          displayImages,
          productionDays,
          testOutfitDays,
          selectedTopSizes,
          selectedTrouserWaistSizes,
          selectedCapSizes,
          selectedHeights,
          selectedShoeSizes,
          selectedSleeveLengths,
          selectedTrouserLengths,
          sleeveLengthPrices,
          trouserLengthPrices,
          capSizePrices,
          heightPrices,
          newClientSections,
        });
      }, 0);
    }
  }, [location.state]);

  useEffect(() => {
    const hasChanges = (current, initial) => {
      return JSON.stringify(current) !== JSON.stringify(initial);
    };

    const isUpdateValid =
      hasChanges(productName, initialProductData?.name) ||
      hasChanges(productPrice, initialProductData?.price) ||
      hasChanges(designersNotes, initialProductData?.designersNotes) ||
      hasChanges(remoteBespoke, initialProductData?.remoteBespoke) ||
      hasChanges(deliveryOptions, initialProductData?.deliveryOptions) ||
      hasChanges(selectedColors, initialProductData?.colors) ||
      hasChanges(colorPrices, initialProductData?.colorPrices) ||
      hasChanges(sections, initialProductData?.sections) ||
      hasChanges(displayImages, initialProductData?.displayImages) ||
      hasChanges(productionDays, initialProductData?.productionDays) ||
      hasChanges(testOutfitDays, initialProductData?.testOutfitDays) ||
      hasChanges(newClientSections, initialProductData?.newClientSections) ||
      hasChanges(
        selectedTopSizes,
        initialProductData?.availableMeasurements?.top
      ) ||
      hasChanges(
        selectedTrouserWaistSizes,
        initialProductData?.availableMeasurements?.trouserWaist
      ) ||
      hasChanges(
        selectedCapSizes,
        initialProductData?.availableMeasurements?.capSize
      ) ||
      hasChanges(
        selectedHeights,
        initialProductData?.availableMeasurements?.height
      ) ||
      hasChanges(
        selectedShoeSizes,
        initialProductData?.availableMeasurements?.shoeSize
      ) ||
      hasChanges(selectedCategories, initialProductData?.categories) ||
      hasChanges(visibility, initialProductData?.categories?.[0]?.visibility);

    setIsUpdateValid(isUpdateValid);
  }, [
    productName,
    productPrice,
    designersNotes,
    remoteBespoke,
    deliveryOptions,
    selectedColors,
    colorPrices,
    sections,
    displayImages,
    productionDays,
    testOutfitDays,
    selectedTopSizes,
    selectedTrouserWaistSizes,
    selectedCapSizes,
    selectedHeights,
    selectedShoeSizes,
    newClientSections,
    selectedCategories,
    visibility,
    initialProductData,
  ]);

  useEffect(() => {
    const isSectionValid = (section) =>
      section.sectionName &&
      section.sectionName.trim() !== "" &&
      section.subsections &&
      section.subsections.length > 0 &&
      section.subsections.every(
        (subsection) =>
          subsection.subSection &&
          subsection.subSection.trim() !== "" &&
          subsection.price &&
          subsection.price.trim() !== ""
      );

    const isColorValid =
      selectedColors.length === 0 ||
      (selectedColors.length > 0 &&
        Object.keys(colorPrices).length === selectedColors.length);

    const isFormValid =
      productName.trim() !== "" &&
      productPrice.trim() !== "" &&
      selectedCategories.length > 0 &&
      designersNotes.trim() !== "" &&
      remoteBespoke.trim() !== "" &&
      deliveryOptions.trim() !== "" &&
      isColorValid &&
      sections.every(isSectionValid) &&
      displayImages.length > 0 &&
      productionDays > 0 &&
      testOutfitDays > 0 &&
      newClientSections.every((section) => {
        switch (section) {
          case "Top":
            return selectedTopSizes.length > 0;
          case "TrouserWaist":
            return selectedTrouserWaistSizes.length > 0;
          case "CapSize":
            return selectedCapSizes.length > 0;
          case "Height":
            return selectedHeights.length > 0;
          case "ShoeSize":
            return selectedShoeSizes.length > 0;
          case "UniqueSize":
            return true;

          default:
            return false;
        }
      });

    setIsFormValid(isFormValid);
  }, [
    productName,
    productPrice,
    selectedCategories,
    designersNotes,
    remoteBespoke,
    deliveryOptions,
    selectedColors,
    colorPrices,
    sections,
    displayImages,
    productionDays,
    testOutfitDays,
    selectedTopSizes,
    selectedTrouserWaistSizes,
    selectedCapSizes,
    selectedHeights,
    selectedShoeSizes,
    newClientSections,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isLoading) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification("");
    }, 3000);
  };

  const checkForUndefinedFields = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value === undefined) {
        console.error(`Field "${key}" is undefined. Setting default value.`);
        data[key] = "";
      } else if (typeof value === "object" && !Array.isArray(value)) {
        checkForUndefinedFields(value);
      }
    });
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const productUUID = initialProductData?.uuid;
      const productID = initialProductData?.productId;

      if (!productUUID && !productID) {
        showNotification("Error: Product UUID or Product ID is missing.");
        setIsLoading(false);
        return;
      }

      const updatedProductData = {
        name: productName || "",
        price: productPrice || "",
        familyName: familyName || "",
        newIn: newInToggle || false,
        displayTestOutfit: displayTestOutfitToggle || false,
        categories: selectedCategories.map((category) => ({
          category: category.value || "",
          visibility: visibility || "All",
        })),
        designersNotes: designersNotes || "",
        remoteBespoke: remoteBespoke || "",
        deliveryOptions: deliveryOptions || "",
        colors: selectedColors.map((color) => ({
          name: color.name || "",
          hex: color.hex || "",
          price: colorPrices[color.hex] || 0,
          frenchName: colorFrenchNames[color.hex] || "",
        })),
        sections: sections.map((section) => ({
          sectionName: section.sectionName || "",
          sectionNameFrench: section.sectionNameFrench || "",
          subsections: section.subsections.map((subsection) => ({
            name: subsection.subSection || "",
            nameFrench: subsection.subSectionFrench || "",
            price: subsection.price || 0,
          })),
        })),
        availableMeasurements: {
          top: selectedTopSizes.some((size) => size.value === "ALL")
            ? optionsTop
                .filter((option) => option.value !== "ALL")
                .map((option) => option.label)
            : selectedTopSizes.map((option) => option.label || option),
          trouserWaist: selectedTrouserWaistSizes.some(
            (size) => size.value === "ALL"
          )
            ? optionsTrouserWaist
                .filter((option) => option.value !== "ALL")
                .map((option) => option.label)
            : selectedTrouserWaistSizes.map((option) => option.label || option),
          capSize: selectedCapSizes.some((size) => size.value === "ALL")
            ? optionsCapSize
                .filter((option) => option.value !== "ALL")
                .map((option) => option.label)
            : selectedCapSizes.map((option) => option.label || option),
          height: selectedHeights.some((height) => height.value === "ALL")
            ? optionsHeight
                .filter((option) => option.value !== "ALL")
                .map((option) => option.label)
            : selectedHeights.map((option) => option.label || option),
          shoeSize: selectedShoeSizes.some((size) => size.value === "ALL")
            ? optionsShoeSize
                .filter((option) => option.value !== "ALL")
                .map((option) => option.label)
            : selectedShoeSizes.map((option) => option.label || option),
          uniqueSize: newClientSections.includes("UniqueSize")
            ? ["UniqueSize"]
            : [],
        },
        displayImages: displayImages || [],
        productionDays: productionDays || 0,
        testOutfitDays: testOutfitDays || 0,
        newClientDeliveryDate: newClientDeliveryDate || 0,
        oldClientDeliveryDate: oldClientDeliveryDate || 0,
      };

      checkForUndefinedFields(updatedProductData);

      for (const selectedCategory of selectedCategories) {
        console.log(`Updating category: ${selectedCategory.value}`);

        const productsSubcollection = collection(
          db,
          "products",
          selectedCategory.value,
          "items"
        );

        if (productUUID) {
          const querySnapshot = await getDocs(
            query(productsSubcollection, where("uuid", "==", productUUID))
          );

          if (!querySnapshot.empty) {
            for (const docSnapshot of querySnapshot.docs) {
              const docId = docSnapshot.id;
              console.log(
                `Updating document ID: ${docId} in category: ${selectedCategory.value}`
              );

              const productRef = doc(
                db,
                "products",
                selectedCategory.value,
                "items",
                docId
              );

              await setDoc(productRef, updatedProductData, { merge: true });
            }
          } else {
            console.error(
              `No matching document found for UUID ${productUUID} in category ${selectedCategory.value}.`
            );
          }
        } else if (productID) {
          const querySnapshot = await getDocs(
            query(productsSubcollection, where("productId", "==", productID))
          );

          if (!querySnapshot.empty) {
            for (const docSnapshot of querySnapshot.docs) {
              const docId = docSnapshot.id;
              console.log(
                `Updating document ID: ${docId} in category: ${selectedCategory.value} using Product ID: ${productID}`
              );

              const productRef = doc(
                db,
                "products",
                selectedCategory.value,
                "items",
                docId
              );

              await setDoc(productRef, updatedProductData, { merge: true });
            }
          } else {
            console.error(
              `No matching document found for Product ID ${productID} in category ${selectedCategory.value}.`
            );
          }
        }
      }

      showNotification("Product updated successfully!");
    } catch (error) {
      console.error("Error updating product:", error);
      showNotification("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    setIsLoading(true);
    try {
      const productUUID = selectedCategories.length > 1 ? uuidv4() : null;

      const counterRef = doc(db, "counters", "productCounter");
      const counterSnapshot = await getDoc(counterRef);

      let currentCount = 0;

      if (counterSnapshot.exists()) {
        currentCount = counterSnapshot.data().currentCount;
      } else {
        await setDoc(counterRef, { currentCount: 0 });
      }

      const newCount = currentCount + 1;
      const productID = `MC-${String(newCount).padStart(4, "0")}`;

      const allTopSizes = optionsTop
        .map((option) => option.label)
        .filter((size) => size !== "All");
      const allTrouserWaistSizes = optionsTrouserWaist.map(
        (option) => option.label
      );
      const allCapSizes = optionsCapSize.map((option) => option.label);
      const allHeights = optionsHeight.map((option) => option.label);
      const allShoeSizes = optionsShoeSize.map((option) => option.label);

      const productData = {
        uuid: productUUID,
        productId: productID,
        name: productName,
        price: productPrice,
        familyName: familyName,
        newIn: newInToggle,
        displayTestOutfit: displayTestOutfitToggle,
        categories: selectedCategories.map((category) => ({
          category: category.value,
          visibility: visibility || "All",
        })),
        designersNotes,
        remoteBespoke,
        deliveryOptions,
        colors: selectedColors.map((color) => ({
          name: color.name,
          hex: color.hex,
          price: colorPrices[color.hex],
          frenchName: colorFrenchNames[color.hex] || "",
        })),
        sections: sections.map((section) => ({
          sectionName: section.sectionName,
          sectionNameFrench: section.sectionNameFrench || "",
          subsections: section.subsections.map((subsection) => ({
            name: subsection.subSection,
            nameFrench: subsection.subSectionFrench || "",
            price: subsection.price,
          })),
        })),
        availableMeasurements: {
          top: selectedTopSizes.some((size) => size.value === "ALL")
            ? allTopSizes
            : selectedTopSizes.map((option) => option.label),
          trouserWaist: selectedTrouserWaistSizes.some(
            (size) => size.value === "ALL"
          )
            ? allTrouserWaistSizes
            : selectedTrouserWaistSizes.map((option) => option.label),
          capSize: selectedCapSizes.some((size) => size.value === "ALL")
            ? allCapSizes
            : selectedCapSizes.map((option) => option.label),
          height: selectedHeights.some((height) => height.value === "ALL")
            ? allHeights
            : selectedHeights.map((option) => option.label),
          shoeSize: selectedShoeSizes.some((size) => size.value === "ALL")
            ? allShoeSizes
            : selectedShoeSizes.map((option) => option.label),
          uniqueSize: newClientSections.includes("UniqueSize")
            ? ["UniqueSize"]
            : [],
        },
        displayImages: [],
        productionDays,
        testOutfitDays,
        newClientDeliveryDate,
        oldClientDeliveryDate,
        createdAt: serverTimestamp(), // Automatically sets the server timestamp
      };

      const uploadImage = async (image, path) => {
        const storageRef = ref(storage, path);
        const uploadTask = await uploadString(storageRef, image, "data_url");
        return getDownloadURL(uploadTask.ref);
      };

      const displayImageUrls = await Promise.all(
        displayImages.map((image, index) =>
          uploadImage(
            image,
            `products/${productName.replace(/\s+/g, "-")}/display/${index}`
          )
        )
      );

      productData.displayImages = displayImageUrls;

      for (const selectedCategory of selectedCategories) {
        const productPath = `products/${
          selectedCategory.value
        }/${productName.replace(/\s+/g, "-")}`;

        const productsCollection = collection(db, "products");
        const categoryDoc = doc(productsCollection, selectedCategory.value);

        await setDoc(categoryDoc, {}, { merge: true });
        const productsSubcollection = collection(categoryDoc, "items");

        await addDoc(productsSubcollection, productData);
      }

      await setDoc(counterRef, { currentCount: newCount });

      showNotification("Product uploaded successfully!");

      // Reset form fields
      setProductName("");
      setProductPrice("");
      setSelectedCategories([]);
      setVisibility("All");
      setRemoteBespoke("");
      setColorPrices({});
      setDesignersNotes("");
      setDeliveryOptions("");
      setSelectedColors([]);
      setSelectedSleeveLengths([]);
      setSelectedTopSizes([]);
      setSelectedTrouserWaistSizes([]);
      setSelectedHeights([]);
      setSelectedCapSizes([]);
      setSelectedShoeSizes([]);
      setSections([]);
      setDisplayImages([]);
      setProductionDays(0);
      setTestOutfitDays(0);
      setNewInToggle(false);
      setDisplayTestOutfitToggle(false);
      setFamilyName("");
    } catch (error) {
      console.error("Error uploading product:", error);
      showNotification("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isLoading) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  return (
    <div className="add-product-container-unique">
      <div className="add-product-header-unique">
        <div className="back-and-title-container">
          <div className="back-button-unique" onClick={handleBackClick}>
            <BackIcon className="back-icon-unique" />
            <span>Back</span>
          </div>
          <span className="add-product-title-unique">
            {isEditing ? "Edit Product" : "Add Product"}
          </span>
        </div>
        <div className="upload-button-container-unique">
          {/* Button for Upload Product */}
          {!isEditing && (
            <div
              className={`upload-button-unique ${
                !isFormValid || isLoading ? "disabled" : ""
              }`}
              onClick={isFormValid && !isLoading ? handleUpload : null}
            >
              {isLoading ? <span className="loader"></span> : "Upload Product"}
            </div>
          )}

          {/* Button for Update Product */}
          {isEditing && (
            <div
              className={`upload-button-unique ${
                !isUpdateValid || isLoading ? "disabled" : ""
              }`}
              onClick={isUpdateValid && !isLoading ? handleUpdate : null}
            >
              {isLoading ? <span className="loader"></span> : "Update Product"}
            </div>
          )}
        </div>
      </div>
      <div className="content-container-unique">
        <div className="split-section-unique small-section-unique">
          <div className="new-product-section">
            <div className="new-section-title">Product</div>
            <div className="new-product-container">
              <label className="new-form-label" htmlFor="productName">
                Name
              </label>
              <input
                className="new-form-input"
                type="text"
                id="productName"
                name="productName"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
              <div className="new-form-row">
                <div className="new-form-group">
                  <label className="new-form-label" htmlFor="productPrice">
                    Price ($)
                  </label>
                  <input
                    className="new-form-input-small"
                    type="number"
                    id="productPrice"
                    name="productPrice"
                    value={productPrice}
                    onChange={(e) => setProductPrice(e.target.value)}
                  />
                </div>
                <div className="new-form-group">
                  <label className="new-form-label" htmlFor="familyName">
                    Family Name
                  </label>
                  <input
                    className="new-form-input"
                    type="text"
                    id="familyName"
                    name="familyName"
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                  />
                </div>
              </div>
              <label className="new-form-label" htmlFor="category">
                Category
              </label>
              {loadingCategories ? (
                <div>Loading categories...</div>
              ) : categoryError ? (
                <div>{categoryError}</div>
              ) : (
                <Select
                  isMulti
                  options={categories}
                  value={selectedCategories}
                  onChange={(selectedOptions) =>
                    setSelectedCategories(selectedOptions)
                  }
                  components={animatedComponents}
                  styles={customStyles}
                />
              )}

              {/* New Visibility Control */}
              <div className="visibility-control-unique">
                <label className="visibility-label-unique">Visibility</label>
                <select
                  className="visibility-select-unique"
                  value={visibility}
                  onChange={(e) => setVisibility(e.target.value)}
                >
                  {selectedCategories.length === 1 ? (
                    <>
                      <option value="All">All</option>
                      <option value="No">No</option>
                      <option value="Limited">Limited</option>
                    </>
                  ) : (
                    <>
                      <option value="All">All</option>
                      <option value="No">No</option>
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>

          <div className="details-section">
            <div className="details-section-title">Details</div>
            <div className="details-container">
              <div className="details-group">
                <label className="details-label" htmlFor="designersNotes">
                  Designer’s Notes
                </label>
                <textarea
                  className="details-textarea"
                  id="designersNotes"
                  name="designersNotes"
                  value={designersNotes}
                  onChange={(e) => setDesignersNotes(e.target.value)}
                />
              </div>
              <div className="details-group">
                <label className="details-label" htmlFor="remoteBespoke">
                  Remote Bespoke
                </label>
                <textarea
                  className="details-textarea"
                  id="remoteBespoke"
                  name="remoteBespoke"
                  value={remoteBespoke}
                  onChange={(e) => setRemoteBespoke(e.target.value)}
                />
              </div>
              <div className="details-group">
                <label className="details-label" htmlFor="deliveryOptions">
                  Delivery Options
                </label>
                <textarea
                  className="details-textarea"
                  id="deliveryOptions"
                  name="deliveryOptions"
                  value={deliveryOptions}
                  onChange={(e) => setDeliveryOptions(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="split-section-unique medium-section-unique">
          <div className="customize-section">
            <div className="customize-title">Customize outfit</div>
            <div className="customize-container">
              {selectedSections.length === 0 && sections.length === 0 && (
                <p>Click the button below to add a customizable section.</p>
              )}
              {selectedSections.includes("Color") && (
                <div className="color-section">
                  <div className="color-title">Color</div>
                  <div className="color-options">
                    <form
                      className="color-dropdown"
                      onClick={toggleColorDialog}
                    >
                      <span className="color-hint-text">Add a color</span>
                    </form>
                    <div
                      className={`price-dropdown ${
                        selectedColors.length === 0 ? "disabled" : ""
                      }`}
                      onClick={
                        selectedColors.length > 0
                          ? handlePriceDropdownClick
                          : null
                      }
                    >
                      {priceLabel}
                    </div>
                  </div>

                  <div className="selected-colors">
                    {selectedColors.map((color, index) => (
                      <div key={index} className="selected-color">
                        <span
                          className="color-swatch"
                          style={{ backgroundColor: color.hex }}
                        />
                        <span className="color-name">{color.name}</span>
                        <input
                          type="text"
                          placeholder="French name"
                          value={colorFrenchNames[color.hex] || ""}
                          onChange={(e) =>
                            setColorFrenchNames({
                              ...colorFrenchNames,
                              [color.hex]: e.target.value,
                            })
                          }
                          className="color-french-name-input"
                        />
                        <span
                          className="remove-color"
                          onClick={() => handleColorRemove(color)}
                        >
                          ×
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {priceInputsVisible && (
                <div className="price-input-container">
                  {selectedColors.map((color, index) => (
                    <div key={index} className="price-input">
                      <label>{color.name}:</label>
                      <input
                        type="number"
                        value={colorPrices[color.hex] || ""}
                        onChange={(e) =>
                          handlePriceChange(color.hex, e.target.value)
                        }
                        placeholder={`Price for ${color.name}`}
                      />
                    </div>
                  ))}
                  <button
                    className="okay-button"
                    onClick={handlePriceOkayClick}
                  >
                    Okay
                  </button>
                </div>
              )}
              {sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="new-section-container">
                  <input
                    type="text"
                    value={section.sectionName}
                    onChange={(e) =>
                      handleSectionNameChange(
                        sectionIndex,
                        e.target.value,
                        section.sectionNameFrench
                      )
                    }
                    placeholder="Section Name"
                    className="section-name-input"
                  />
                  <div>
                    <input
                      type="text"
                      value={section.sectionNameFrench || ""}
                      onChange={(e) =>
                        handleSectionNameChange(
                          sectionIndex,
                          section.sectionName,
                          e.target.value
                        )
                      }
                      placeholder="Section Name (French)"
                      className="section-name-input-french"
                    />
                  </div>

                  {section.subsections.map((subsection, subIndex) => (
                    <div key={subIndex} className="custom-subsection-row">
                      <div className="fields-section">
                        <input
                          type="text"
                          value={subsection.subSection}
                          placeholder="Subsection"
                          onChange={(e) =>
                            handleSubsectionChange(
                              sectionIndex,
                              subIndex,
                              "subSection",
                              e.target.value
                            )
                          }
                        />
                        <input
                          type="text"
                          value={subsection.subSectionFrench || ""}
                          placeholder="Subsection (French)"
                          onChange={(e) =>
                            handleSubsectionChange(
                              sectionIndex,
                              subIndex,
                              "subSectionFrench",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <input
                        type="number"
                        value={subsection.price}
                        placeholder="Price"
                        onChange={(e) =>
                          handleSubsectionChange(
                            sectionIndex,
                            subIndex,
                            "price",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ))}
                  <button
                    className="add-subsection-button"
                    onClick={() => handleAddSubsection(sectionIndex)}
                  >
                    Add Subsection
                  </button>
                </div>
              ))}
              <div className="customize-button-container">
                {isColorButtonVisible && (
                  <button
                    className="customize-button"
                    onClick={() => handleSectionAdd("Color")}
                  >
                    Add Color
                  </button>
                )}
              </div>

              <div className="customize-button-container">
                <button
                  className="customize-button"
                  onClick={handleAddNewSection}
                >
                  Add a New Section
                </button>
              </div>

              {selectedSections.includes("Sleeve Length") && (
                <div className="sleeve-length-section">
                  <div className="sleeve-length-title">Sleeve Length</div>
                  <div className="sleeve-length-options">
                    <select
                      className="sleeve-length-dropdown"
                      onChange={(e) => {
                        const selectedOption = optionsSleeveLength.find(
                          (option) => option.value === e.target.value
                        );
                        if (
                          selectedOption &&
                          !selectedSleeveLengths.some(
                            (length) => length.value === selectedOption.value
                          )
                        ) {
                          setSelectedSleeveLengths([
                            ...selectedSleeveLengths,
                            selectedOption,
                          ]);
                        }
                      }}
                    >
                      <option value="">Add sizes</option>
                      {optionsSleeveLength.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      className={`price-dropdown ${
                        selectedSleeveLengths.length === 0 ? "disabled" : ""
                      }`}
                      onClick={
                        selectedSleeveLengths.length > 0
                          ? handleSleeveLengthDropdownClick
                          : null
                      }
                    >
                      {sleeveLengthPriceLabel}
                    </div>
                  </div>
                  <div className="selected-sleeve-lengths">
                    {selectedSleeveLengths.map((length, index) => (
                      <div key={index} className="selected-length">
                        <span className="length-name">{length.label}</span>
                        <span
                          className="remove-length"
                          onClick={() => handleSleeveLengthRemove(length)}
                        >
                          ×
                        </span>
                      </div>
                    ))}
                  </div>
                  {sleeveLengthInputsVisible && (
                    <div className="price-input-container">
                      {selectedSleeveLengths.map((length, index) => (
                        <div key={index} className="price-input">
                          <label>{length.label}:</label>
                          <input
                            type="number"
                            value={sleeveLengthPrices[length.value] || ""}
                            onChange={(e) =>
                              handleSleeveLengthPriceChange(
                                length.value,
                                e.target.value
                              )
                            }
                            placeholder={`Price for ${length.label}`}
                          />
                        </div>
                      ))}
                      <button
                        className="okay-button"
                        onClick={() => setSleeveLengthInputsVisible(false)}
                      >
                        Okay
                      </button>
                    </div>
                  )}
                </div>
              )}
              {/* Future sections */}

              {selectedSections.includes("Cap Size") && (
                <div className="cap-size-section">
                  <div className="cap-size-title">Cap Size</div>
                  <div className="cap-size-options">
                    <select
                      className="cap-size-dropdown"
                      onChange={(e) => {
                        const selectedOption = optionsCapSize.find(
                          (option) => option.value === e.target.value
                        );
                        if (
                          selectedOption &&
                          !selectedCapSizes.some(
                            (size) => size.value === selectedOption.value
                          )
                        ) {
                          setSelectedCapSizes([
                            ...selectedCapSizes,
                            selectedOption,
                          ]);
                        }
                      }}
                    >
                      <option value="">Add sizes</option>
                      {optionsCapSize.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      className={`price-dropdown ${
                        selectedCapSizes.length === 0 ? "disabled" : ""
                      }`}
                      onClick={
                        selectedCapSizes.length > 0
                          ? handleCapSizeDropdownClick
                          : null
                      }
                    >
                      {capSizePriceLabel}
                    </div>
                  </div>
                  <div className="selected-cap-sizes">
                    {selectedCapSizes.map((size, index) => (
                      <div key={index} className="selected-size">
                        <span className="size-name">{size.label}</span>
                        <span
                          className="remove-size"
                          onClick={() => handleCapSizeRemove(size)}
                        >
                          ×
                        </span>
                      </div>
                    ))}
                  </div>
                  {capSizeInputsVisible && (
                    <div className="price-input-container">
                      {selectedCapSizes.map((size, index) => (
                        <div key={index} className="price-input">
                          <label>{size.label}:</label>
                          <input
                            type="number"
                            value={capSizePrices[size.value] || ""}
                            onChange={(e) =>
                              handleCapSizePriceChange(
                                size.value,
                                e.target.value
                              )
                            }
                            placeholder={`Price for ${size.label}`}
                          />
                        </div>
                      ))}
                      <button
                        className="okay-button"
                        onClick={() => setCapSizeInputsVisible(false)}
                      >
                        Okay
                      </button>
                    </div>
                  )}
                </div>
              )}
              {selectedSections.includes("Height") && (
                <div className="height-section">
                  <div className="height-title">Height</div>
                  <div className="height-options">
                    <select
                      className="height-dropdown"
                      onChange={(e) => {
                        const selectedOption = optionsHeight.find(
                          (option) => option.value === e.target.value
                        );
                        if (
                          selectedOption &&
                          !selectedHeights.some(
                            (height) => height.value === selectedOption.value
                          )
                        ) {
                          setSelectedHeights([
                            ...selectedHeights,
                            selectedOption,
                          ]);
                        }
                      }}
                    >
                      <option value="">Add sizes</option>
                      {optionsHeight.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div
                      className={`price-dropdown ${
                        selectedHeights.length === 0 ? "disabled" : ""
                      }`}
                      onClick={
                        selectedHeights.length > 0
                          ? handleHeightDropdownClick
                          : null
                      }
                    >
                      {heightPriceLabel}
                    </div>
                  </div>
                  <div className="selected-heights">
                    {selectedHeights.map((height, index) => (
                      <div key={index} className="selected-height">
                        <span className="height-name">{height.label}</span>
                        <span
                          className="remove-height"
                          onClick={() => handleHeightRemove(height)}
                        >
                          ×
                        </span>
                      </div>
                    ))}
                  </div>
                  {heightInputsVisible && (
                    <div className="price-input-container">
                      {selectedHeights.map((height, index) => (
                        <div key={index} className="price-input">
                          <label>{height.label}:</label>
                          <input
                            type="number"
                            value={heightPrices[height.value] || ""}
                            onChange={(e) =>
                              handleHeightPriceChange(
                                height.value,
                                e.target.value,
                                window.addEventListener.checkForUndefinedFields
                              )
                            }
                            placeholder={`Price for ${height.label}`}
                          />
                        </div>
                      ))}
                      <button
                        className="okay-button"
                        onClick={() => setHeightInputsVisible(false)}
                      >
                        Okay
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="split-section-unique large-section-unique">
          <div className="split-title-unique">Product Images</div>
          <div className="product-images-inner-container-unique">
            <div className="image-section-unique">
              <div className="image-section-title-unique">On Display</div>
              <label className="add-files-link-unique">
                Click Here to add files
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleAddDisplayFiles}
                />
              </label>
              <div className="images-container-unique">
                {displayImages.map((image, index) => (
                  <div key={index} className="image-container-unique">
                    <img
                      src={image}
                      alt={`Display ${index}`}
                      className="image-fit"
                    />
                    <div
                      className="cancel-icon"
                      onClick={() => handleRemoveDisplayImage(index)}
                    >
                      ×
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="delivery-section">
            <div className="delivery-title">Delivery</div>
            <div className="delivery-container">
              <div className="delivery-row">
                <div className="delivery-group">
                  <label className="delivery-label" htmlFor="productionDays">
                    Production Days
                  </label>
                  <input
                    className="delivery-input"
                    type="number"
                    id="productionDays"
                    name="productionDays"
                    value={productionDays}
                    onChange={handleProductionDaysChange}
                  />
                </div>
                <div className="delivery-group">
                  <label className="delivery-label" htmlFor="testOutfitDays">
                    Test-outfit Days
                  </label>
                  <input
                    className="delivery-input"
                    type="number"
                    id="testOutfitDays"
                    name="testOutfitDays"
                    value={testOutfitDays}
                    onChange={handleTestOutfitDaysChange}
                  />
                </div>
              </div>
              <div className="delivery-dates">
                <p>
                  Final outfit delivery (AFTER TO): {newClientDeliveryDate} days
                </p>
                <p>Test outfit delivery: {testOutfitDays} days</p>
              </div>
            </div>
          </div>
          <div className="new-client-title">New Client</div>
          <div className="new-client-container">
            {newClientSections.length === 0 && (
              <p>Click the button below to add New client details.</p>
            )}

            {newClientSections.includes("Top") && (
              <div className="top-section-unique">
                <div className="top-title">Top Sizes</div>
                <div className="top-options">
                  <Select
                    className="custom-select-container"
                    isMulti
                    options={[{ value: "ALL", label: "All" }]}
                    value={
                      selectedTopSizes.length === optionsTop.length - 1
                        ? [{ value: "ALL", label: "All" }]
                        : selectedTopSizes
                    }
                    onChange={handleTopSizesChange}
                    components={animatedComponents}
                    placeholder="Select top sizes"
                    styles={customStyles}
                  />
                </div>
              </div>
            )}

            {newClientSections.includes("TrouserWaist") && (
              <div className="trouser-waist-section-unique">
                <div className="trouser-waist-title">Trouser Waist Sizes</div>
                <div className="trouser-waist-options">
                  <Select
                    className="custom-select-container"
                    isMulti
                    options={[{ value: "ALL", label: "All" }]}
                    value={
                      selectedTrouserWaistSizes.length ===
                      optionsTrouserWaist.length - 1
                        ? [{ value: "ALL", label: "All" }]
                        : selectedTrouserWaistSizes
                    }
                    onChange={handleTrouserWaistSizesChange}
                    components={animatedComponents}
                    placeholder="Select trouser waist sizes"
                  />
                </div>
              </div>
            )}
            {newClientSections.includes("CapSize") && (
              <div className="cap-size-section-unique">
                <div className="cap-size-title">Cap Sizes</div>
                <div className="cap-size-options">
                  <Select
                    className="custom-select-container"
                    isMulti
                    options={[{ value: "ALL", label: "All" }]}
                    value={
                      selectedCapSizes.length === optionsCapSize.length - 1
                        ? [{ value: "ALL", label: "All" }]
                        : selectedCapSizes
                    }
                    onChange={handleCapSizesChange}
                    components={animatedComponents}
                    placeholder="Select cap sizes"
                  />
                </div>
              </div>
            )}

            {newClientSections.includes("Height") && (
              <div className="height-section-unique">
                <div className="height-title">Heights</div>
                <div className="height-options">
                  <Select
                    className="custom-select-container"
                    isMulti
                    options={[{ value: "ALL", label: "All" }]}
                    value={
                      selectedHeights.length === optionsHeight.length - 1
                        ? [{ value: "ALL", label: "All" }]
                        : selectedHeights
                    }
                    onChange={handleHeightChange}
                    components={animatedComponents}
                    placeholder="Select heights"
                  />
                </div>
              </div>
            )}
            {newClientSections.includes("ShoeSize") && (
              <div className="shoe-size-section-unique">
                <div className="shoe-size-title">Shoe Sizes</div>
                <div className="shoe-size-options">
                  <Select
                    className="custom-select-container"
                    isMulti
                    options={[{ value: "ALL", label: "All" }]}
                    value={
                      selectedShoeSizes.length === optionsShoeSize.length - 1
                        ? [{ value: "ALL", label: "All" }]
                        : selectedShoeSizes
                    }
                    onChange={handleShoeSizeChange}
                    components={animatedComponents}
                    placeholder="Select shoe sizes"
                  />
                </div>
              </div>
            )}
            {newClientSections.includes("UniqueSize") && (
              <div className="unique-size-section-unique">
                <div className="unique-size-title">Unique Size</div>
                <div className="unique-size-options">
                  <Select
                    className="custom-select-container"
                    isMulti={false}
                    options={[{ value: "Unique Size", label: "Unique Size" }]}
                    value={{ value: "Unique Size", label: "Unique Size" }}
                    isDisabled={true}
                    components={animatedComponents}
                    placeholder="Unique Size"
                  />
                </div>
              </div>
            )}

            <div className="new-client-button-container">
              <button
                className="new-client-button"
                onClick={toggleNewClientDropdown}
              >
                Add a section
              </button>
              {isNewClientDropdownOpen && (
                <div className="dropdown-menu" ref={newClientDropdownRef}>
                  {availableOptions.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => handleNewClientSectionAdd(option.value)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Add a toggle switch here */}
          <div className="toggle-container">
            <input
              type="checkbox"
              id="newInToggle"
              className="toggle-switch"
              checked={newInToggle}
              onChange={(e) => setNewInToggle(e.target.checked)}
            />
            <label htmlFor="newInToggle" className="toggle-label">
              New in
            </label>
          </div>
          <div className="toggle-item">
            <input
              type="checkbox"
              id="displayTestOutfitToggle"
              className="toggle-switch"
              checked={displayTestOutfitToggle}
              onChange={(e) => setDisplayTestOutfitToggle(e.target.checked)}
            />
            <label htmlFor="displayTestOutfitToggle" className="toggle-label">
              Display Test outfit
            </label>
          </div>
        </div>
      </div>
      {colorDialogVisible && (
        <div className="color-dialog-overlay">
          <div className="color-dialog">
            <SketchPicker
              color={currentColor}
              onChangeComplete={handleColorChange}
            />
            <input
              type="text"
              placeholder="Enter color name"
              value={colorName}
              onChange={(e) => setColorName(e.target.value)}
              className="color-name-input"
            />
            <div className="color-dialog-buttons">
              <button onClick={handleColorSelect}>Select</button>
              <button onClick={toggleColorDialog}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {notification && (
        <div className={`notification ${notification ? "show" : ""}`}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default AddProduct;
