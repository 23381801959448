import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../style/ProductManagement.css";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { ReactComponent as DownIcon } from "../icons/down.svg";
import useFetchProducts from "../hooks/useFetchProducts";
import ProductDisplay from "./ProductDisplay";

const ProductManagement = () => {
  const today = new Date().toLocaleDateString();
  const [activeTab, setActiveTab] = useState("allProducts");
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [sortOption, setSortOption] = useState("Upload date");
  const [searchQuery, setSearchQuery] = useState("");
  const [deduplicatedProducts, setDeduplicatedProducts] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const { products, loading, hasMore, fetchMoreProducts } = useFetchProducts();

  const observer = useRef();

  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            fetchMoreProducts();
          }
        },
        { threshold: 1.0 }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, fetchMoreProducts]
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAddProductClick = () => {
    navigate("/add-product", {
      state: {
        from: location.pathname,
        activeComponent: "productManagement",
      },
    });
  };

  const toggleSortDropdown = () => {
    setSortDropdownVisible(!sortDropdownVisible);
  };

  const handleSortOptionClick = (option) => {
    setSortOption(option);
    setSortDropdownVisible(false);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      navigate("/search-results", {
        state: {
          from: location.pathname,
          query: searchQuery,
          activeComponent: "productManagement",
        },
      });
    }
  };

  const handleProductClick = (product) => {
    navigate("/add-product", {
      state: {
        product: product,
        from: location.pathname,
        activeComponent: "productManagement",
      },
    });
  };

  // Deduplication logic with order preservation
  useEffect(() => {
    const deduplicateProducts = () => {
      const productMap = new Map();

      products.forEach((product) => {
        let uniqueKey = product.uuid || product.productId;

        if (!uniqueKey) {
          // If neither uuid nor productId is present, generate a key based on product name and price
          uniqueKey = `${product.name}_${product.price}`;
        }

        const existingProduct = productMap.get(uniqueKey);

        if (existingProduct) {
          // Compare 'createdAt' timestamps
          const existingTimestamp =
            existingProduct.createdAt?.toMillis?.() || 0;
          const currentTimestamp = product.createdAt?.toMillis?.() || 0;

          if (currentTimestamp > existingTimestamp) {
            // Replace with the newer product
            productMap.set(uniqueKey, product);
          }
        } else {
          productMap.set(uniqueKey, product);
        }
      });

      // Convert Map values to an array and sort by 'createdAt' descending
      const dedupedProducts = Array.from(productMap.values()).sort((a, b) => {
        const timestampA = a.createdAt?.toMillis?.() || 0;
        const timestampB = b.createdAt?.toMillis?.() || 0;
        return timestampB - timestampA;
      });

      setDeduplicatedProducts(dedupedProducts);
    };

    deduplicateProducts();
  }, [products]);

  return (
    <div className="product-management-container">
      <div className="header">
        <div className="date">{today}</div>
        <div className="search-container">
          <SearchIcon className="icon" />
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleSearchKeyPress}
          />
        </div>
      </div>
      <div className="title-section">
        <div className="product-management-title">Product Management</div>
        <div className="add-product-container">
          <div className="add-product-box" onClick={handleAddProductClick}>
            ADD PRODUCT
          </div>
        </div>
      </div>
      <div className="content-sections">
        <div className="left-section">
          <div className="tabs-container">
            <div className="tabs">
              <div
                className={`tab ${activeTab === "allProducts" ? "active" : ""}`}
                onClick={() => handleTabClick("allProducts")}
              >
                All products
              </div>
              <div
                className={`tab ${
                  activeTab === "mostPurchased" ? "active" : ""
                }`}
                onClick={() => handleTabClick("mostPurchased")}
              >
                Most purchased
              </div>
              <div
                className={`tab ${
                  activeTab === "leastPurchased" ? "active" : ""
                }`}
                onClick={() => handleTabClick("leastPurchased")}
              >
                Least purchased
              </div>
            </div>
            <div className="sort-container">
              <span>Sort by:</span>
              <div className="dropdown" onClick={toggleSortDropdown}>
                {sortOption}
                <DownIcon className="down-icon" />
                {sortDropdownVisible && (
                  <div className="dropdown-content">
                    <button
                      onClick={() => handleSortOptionClick("Upload date")}
                    >
                      Upload date
                    </button>
                    <button
                      onClick={() => handleSortOptionClick("New in relevance")}
                    >
                      New in relevance
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="product-list">
            {deduplicatedProducts.map((product, index) => {
              if (deduplicatedProducts.length === index + 1) {
                return (
                  <div
                    ref={lastProductElementRef}
                    key={product.id}
                    onClick={() => handleProductClick(product)}
                    style={{ cursor: "pointer" }}
                  >
                    <ProductDisplay product={product} />
                  </div>
                );
              } else {
                return (
                  <div
                    key={product.id}
                    onClick={() => handleProductClick(product)}
                    style={{ cursor: "pointer" }}
                  >
                    <ProductDisplay product={product} />
                  </div>
                );
              }
            })}
            {loading && <div>Loading...</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductManagement;
